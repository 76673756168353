import { graphql } from "src/gql"

export const ECONSENT_MFA = "ECONSENT_MFA"

// IE11 doesn't support timezones without a polyfill, which is huge
export const DATE_FORMAT_IE11 = "yyyy-MM-dd hh:mm a"
export const DATE_FORMAT_IE11_SHORT = "MMMM d, yyyy"

export const IN_PERSON_PARAM = "inPersonEConsent"

/**
 * https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
 */
export const WORD_DOCUMENT_TYPE =
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document"

// If editing role names, update the eConsent preview invalidSignatoryRoleMessage
export const ROLE_NAMES = Object.freeze({
  participant: "PARTICIPANT",
  site: "SITE",
  witness: "WITNESS",
  translator: "TRANSLATOR",
  larGuardian: "LAR_GUARDIAN",
  caregiver: "CAREGIVER",
  proxy: "PROXY",
})

type ValueOf<T> = T[keyof T]
export const getSignatureCountByRole = (role: ValueOf<typeof ROLE_NAMES>) => {
  if (role === ROLE_NAMES.witness) {
    return 2
  }

  return 1
}
export const QUESTION_ROLE_NAMES = Object.freeze({
  defaultRole: "DEFAULT",
  participant: "PARTICIPANT",
  witness: "WITNESS",
  translator: "TRANSLATOR",
  larGuardian: "LAR_GUARDIAN",
  caregiver: "CAREGIVER",
  proxy: "PROXY",
})

export const CONFIRM_FIELD_TYPES = {
  email: "EMAIL",
  phone: "PHONE",
}

export const CODE_INPUT_ANIMATION_TIME = 1800

export const NOTIFICATION_APPLICATIONS = {
  ePro: "ePRO",
  eConsent: "eConsent",
  otherVisit: "other_visit",
  sharedDocs: "shared_documents",
  virtualVisit: "virtual_visit",
}

export const subTaskQuery = graphql(/* GraphQL*/ `
  fragment SubTaskQuery on UserSubTask {
    __typename
    id
    action
    actionDate
    displayName
    dueDateTime
    isViewable
    sequence
    startDateTime
    ... on SignEConsentSubTask {
      isUnlockCodeRequired
    }
  }
`)

export const userTaskQuery = graphql(/* GraphQL */ `
  fragment UserTaskQuery on UserTask {
    userRole {
      role
    }
    isComplete
    studyParticipant {
      lastName
      firstName
      globalParticipantId
      study {
        studyId
        studyName
      }
    }
  }
`)
