export interface LanguagesJson {
  readonly languages: readonly {
    charDirection: string
    displayName: string
    translatedLabel: string
    fallBackTag: string
    tag: string
    isBaseLanguage: boolean
  }[]
  /**
   * Mapping from myvp shorthand (key) to bcp-47 locale string (value).
   *
   * @example
   * {
   *   enUS: "en-US",
   *   esES: "es-ES",
   *   ...
   * }
   */
  readonly shorthands: Readonly<Record<string, string>>
}

const createResolveSupportedLocale = (data: LanguagesJson) => {
  const supportedSet: ReadonlySet<string> = new Set(
    Object.values(data.shorthands)
  )
  const fallbackMap: ReadonlyMap<string, string> = createFallbackLocaleMap(data)

  return (localeOrLocales: string | readonly string[]): string | null => {
    const locales = Array.isArray(localeOrLocales)
      ? localeOrLocales
      : [localeOrLocales]

    for (const locale of locales) {
      if (supportedSet.has(locale)) {
        return locale
      }
      const fallback = fallbackMap.get(locale)
      if (fallback && supportedSet.has(fallback)) {
        return fallback
      }
    }

    return null
  }
}

export default createResolveSupportedLocale

export type SupportedLocaleResolver = ReturnType<
  typeof createResolveSupportedLocale
>

const createFallbackLocaleMap = (data: LanguagesJson) => {
  const map = new Map<string, string>()

  // these mappings take precedence over the `shorthands` mapping
  // Example:
  // in these mappings, pt-PT => falls back to pt-BR
  // in shorthands, pt-PT => falls back to pt-PT
  for (const fallbackDef of data.languages) {
    map.set(fallbackDef.tag, fallbackDef.fallBackTag)
  }

  for (const [shorthand, bcp47tag] of Object.entries(data.shorthands)) {
    // we only need `shorthand`s with length of 2, but bcp47 allows a language tag to
    // be 2-8 letters (over 2 characters is rare).
    // only set if the key is not present (previous keys have higher precedence)
    if (!map.has(shorthand)) {
      map.set(shorthand, bcp47tag)
    }
  }

  return map
}
