export default {
  "eproViewer.answerImageFallback": "Answer {number}",
  "eproViewer.back": "Back",
  "eproViewer.blockHeadingAriaLabel": "Block Heading",
  "eproViewer.calendarInputTitle": "Open Calendar",
  "eproViewer.cancel": "Cancel",
  "eproViewer.close": "Close",
  "eproViewer.cantDisplayModalHeader": "Can't display survey",
  "eproViewer.cantDisplayModalMessage":
    "We can't currently display the survey on your device. Please try rotating your device, zooming out, or using another device.",
  "eproViewer.date": "Date",
  "eproViewer.dateError": "Please enter a date between {min} and {max}.",
  "eproViewer.dateQuestionLabel": "Item {itemNumber}: Date question",
  "eproViewer.dateTimeError":
    "Please enter a date and time between {min} and {max}.",
  "eproViewer.dateTimeQuestionLabel": "Item {itemNumber}: Datetime question",
  "eproViewer.headingImageFallback": "Question{questionNumber}",
  "eproViewer.headingImageTextBlockFallback": "Instructions",
  "eproViewer.licenseImageFallback": "Survey License",
  "eproViewer.loadingSurvey": "Loading Survey...",
  "eproViewer.loggedOut": "Logged out",
  "eproViewer.loggedOut2": "Logged Out",
  "eproViewer.loggedOutWhileInactive":
    "We logged you out while you were inactive. Please select OK to log in again.",
  "eproViewer.multipleChoiceQuestionLabel":
    "Item {itemNumber}: Multiple-choice question",
  "eproViewer.next": "Next",
  "eproViewer.numberEntryIncrementError":
    "Please enter a value that's a multiple of {increment}.",
  "eproViewer.numberEntryMultiFieldsIncrementError":
    "<b>{label}:</b> Please enter a value that's a multiple of {increment}.",
  "eproViewer.numberEntryQuestionLabel":
    "Item {itemNumber}: Number Entry question",
  "eproViewer.numberEntryRangeError":
    "Please enter a value between {minNumber} and {maxNumber}.",
  "eproViewer.numberEntryMultiFieldsRangeError":
    "<b>{label}:</b> Please enter a value between {minNumber} and {maxNumber}.",
  "eproViewer.numberOfNumber": "{lowerNumber} of {greaterNumber}",
  "eproViewer.numericScaleImageFallback": "Number Scale",
  "eproViewer.numericScaleQuestionLabel":
    "Item {itemNumber}: Numeric Scale question",
  "eproViewer.tryRotatingYourScreenTitle": "Try Rotating your Device",
  "eproViewer.tryRotatingYourScreenDescription":
    "For a better experience, try rotating your device or viewing on a wider screen.",
  "eproViewer.ok": "OK",
  "eproViewer.percentComplete":
    "{progressPercentage}<br></br><size>complete</size>",
  "eproViewer.pleaseProvideAResponse": "Please provide a response.",
  "eproViewer.pleaseReconnectToContinue": "Please reconnect to continue.",
  "eproViewer.previous": "Previous",
  "eproViewer.questionNotApplicable":
    "Question {questionNumber} not applicable",
  "eproViewer.questionsNotApplicable":
    "Questions {firstQuestionNumber}-{lastQuestionNumber} not applicable",
  "eproViewer.restrictedDataSurveyWarning":
    "This survey contains restricted data",
  "eproViewer.scoringResultsError": "Error calculating results",
  "eproViewer.scoringResultsErrorRow": "Error calculating result",
  "eproViewer.scoringResultsHeader": "Results",
  "eproViewer.selectAllThatApply": "Select all that apply",
  "eproViewer.selectOne": "Select one",
  "eproViewer.singleChoiceQuestionLabel":
    "Item {itemNumber}: Single-choice question",
  "eproViewer.start": "Start",
  "eproViewer.submit": "Submit",
  "eproViewer.submitting": "Submitting",
  "eproViewer.reviewBeforeSubmit":
    "You can review and make changes by scrolling up. When you are ready, submit your answers.",
  "eproViewer.surveyStartErrorMessage":
    "Another survey must be completed before starting this survey. To start the other survey, select <b>Start.</b>",
  "eproViewer.surveyStartErrorTitle": "Survey Couldn't Be Started",
  "eproViewer.textBlockLabel": "Item {itemNumber}: Text content",
  "eproViewer.textEntryEmptyScreenReader":
    "You can enter up to {maxLength} characters in this field",
  "eproViewer.textEntryNotEmptyScreenReader":
    "{currentLength} characters entered out of {maxLength} characters allowed",
  "eproViewer.textEntryQuestionLabel": "Item {itemNumber}: Text Entry question",
  "eproViewer.time": "Time",
  "eproViewer.timeError": "Please enter a time between {min} and {max}.",
  "eproViewer.timeQuestionLabel": "Item {itemNumber}: Time question",
  "eproViewer.visualScaleQuestionLabel":
    "Item {itemNumber}: Visual Scale question",
  "eproViewer.visualScaleThumbLabelMin": "Minimum: {minimum}. ",
  "eproViewer.visualScaleThumbLabelMax": "Maximum: {maximum}.",
  "eproViewer.websiteIssues": "Website issues",
  "eproViewer.websiteIssuesMessage":
    "Sorry, we're having an issue with our website or servers. Please refresh the page and try again.",
  "eproViewer.youreOffline": "You're offline",
  "eproViewer.youreOnline": "You're online",
  "eproViewer.scaleSlider": "Scale slider",
}
