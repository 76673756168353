import {
  getBrowserTimezone,
  getBrowserTimezoneGMT,
} from "@myvp/shared/src/functions/get-browser-timezone"

const staticProperties = {
  platform: "Web",
  app_version: process.env.REACT_APP_VERSION,
  user_agent: navigator.userAgent,
}

/**
 * Returns data for:
 *
 * - the "device-properties" http header.
 * - boomerang (BOOMR) "device-properties" variable
 */
export const getDeviceProperties = () => ({
  ...staticProperties,
  timezone: getBrowserTimezone(),
  timezoneOffset: getBrowserTimezoneGMT(),
  deviceWidth: screen.width,
  deviceHeight: screen.height,
  pixelDensity: window.devicePixelRatio,
  currentWidth: window.innerWidth,
  currentHeight: window.innerHeight,
})
