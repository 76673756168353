import React from "react"
import ReactDOM from "react-dom"
import Popup, { type PopupProps } from "@myvp/shared/src/components/popup"
import { css } from "@emotion/react"
import { LocaleRegion } from "@myvp/shared/src/ui-providers"

export interface ModalProps extends PopupProps {
  backgroundOnly?: boolean
  backgroundVariant?: "light"
  freezeBackground?: boolean
  modalRoot?: HTMLElement
  modalRef?: React.Ref<HTMLDivElement>
}

class Modal extends React.Component<ModalProps> {
  static defaultProps = {
    titlePosition: "left",
    title: "",
    closeTitle: "",
    clearOnClickOutside: false,
  } satisfies Partial<ModalProps>

  el = document.createElement("div")

  componentDidMount() {
    const modalRoot =
      this.props.modalRoot ?? document.getElementById("modal-root")
    modalRoot?.appendChild(this.el)
    if (this.props.freezeBackground) {
      // Don't allow scroll of the background while the modal is open
      document.body.style.overflow = "hidden"
    }
  }

  componentWillUnmount() {
    // NOTE: this.el.remove() doesn't work on IE11
    this.el.parentNode?.removeChild(this.el)
    if (this.props.freezeBackground) {
      document.body.style.overflow = ""
    }
  }

  render() {
    const { className, freezeBackground, backgroundOnly, modalRef, ...props } =
      this.props

    return ReactDOM.createPortal(
      <LocaleRegion
        css={css`
          background-color: rgba(0, 0, 0, 0.5);
          position: fixed;
          height: 100%;
          width: 100%;
          top: 0;
          left: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 999;
          #modal-popup {
            height: 100%;
            position: inherit;
            z-index: 1000;
          }

          ${props.backgroundVariant === "light" &&
          `
              background-color: rgba(0, 0, 0, 0.2);
            `}
        `}
        className={className}
      >
        {backgroundOnly ? null : (
          <Popup
            {...props}
            ref={modalRef}
            topOffset={props.topOffset || 0}
            id="modal-popup"
          >
            {props.children}
          </Popup>
        )}
      </LocaleRegion>,
      this.el
    )
  }
}

const ModalExport = (props: ModalProps) => {
  if (!props.visible) {
    return null
  } else {
    return <Modal {...props} />
  }
}

export default ModalExport
