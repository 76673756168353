/**
 * NON_PATIENT_FACING: econsentEditor|econsentGenerate|econsentPreview
 */
module.exports = {
  "avatar.firstInitialLastInitial": "{firstInitial}{lastInitial}",
  "userPopup.name": "{firstName} {lastName}",
  "accountSettings.loggedOut": "Logged out",
  "accountSettings.loggedOutWhileInactive":
    "We logged you out while you were inactive. Please select OK to log in again.",
  "alertModal.ok": "OK",
  "authenticatedRoute.updatesToAppPolicies": "Updates to App Policies",
  "authenticatedRoute.updatedTermsMessage":
    "We've made updates to our Privacy Notice and/or Terms of Service. The current versions of these documents are linked below.",
  "authenticatedRoute.privacyNotice": "Privacy Notice",
  "authenticatedRoute.returnToLogin": "Return to Login",
  "confirmNewNumber.confirmPhoneNumberText": "Confirm Phone Number – Text",
  "confirmNewNumber.confirmPhoneNumberVoice":
    "Confirm Phone Number – Voice Call",
  "confirmNewNumber.enterMfaDescription":
    "We sent a six-digit verification code to: <br></br> <span><b>{digits}</b></span>.",
  "confirmNewNumber.enterMfaDescriptionCall":
    "We're calling <span><b>{digits}</b></span> to give you a six-digit verification code.",
  "confirmNewNumber.linkExpired": "Link Expired",
  "confirmNewNumber.login": "log in",
  "confirmNewNumber.loginToRequestNewPhoneNumber":
    "Please {linkToLoginPage} and request a new link to change your phone number.",
  "confirmIdentity.heading": "Confirm Identity",
  "confirmIdentity.info":
    "Select the contact method that you want to use to verify your identity. If the contact information is incorrect, contact your study team for help.",
  "confirmIdentity.email.heading": "Confirm Identity – Email",
  "confirmIdentity.email.info":
    "We sent an email to <span>{email}</span> with a link that will allow you to log in.",
  "confirmIdentity.email.sendAnotherEmail": "Send Another Email",
  "confirmIdentity.email.failedToSend": "Failed to Send",
  "confirmIdentity.email.modalInfo":
    "Your email has failed to send. Please check your internet, and return to the login page to try again.",
  "confirmIdentity.email.ok": "OK",
  "confirmIdentity.text": "Text",
  "confirmIdentity.email": "Email",
  "confirmIdentity.call": "Call",
  "confirmIdentity.next": "Next",
  "identityConfirmed.mainText": "Identity Confirmed",
  "identityConfirmed.linkExpired.heading": "Link Expired",
  "identityConfirmed.linkExpired.info":
    "This email confirmation link is expired. Please make sure you're using the most recent confirmation email, or use another verification method.",
  "determineUser.notEnoughInformationTitle": "Not Enough Information on File",
  "determineUser.notEnoughInformationMessage":
    "To register without an activation code, you need an email and phone number on file. Your study team can add this info for you or give you a code.",
  "determineUser.ok": "OK",
  "determineUser.somethingWentWrong": "Something went wrong",
  "determineUser.pleaseReturnToLogin":
    "Please return to the login page and try again.",
  "determineUser.returnToLogin": "Return to Login",
  "documents.version": "Version",
  "econsent.closeDialog": "Close Dialog",
  "econsentEditor.documentLanguageSettings": "Document Language Settings",
  "econsent.documentNotDownloaded": "Document not downloaded",
  "econsent.failedToDownloadPdf":
    "Sorry, we weren't able to download the document. Please try again later.",
  "econsentEditor.pageCountLabelTooltipText":
    "You can use the following page number tokens to format the page number layout: <br></br>Current Page: [[curr_page_num]]<br></br>Total Pages: [[total_page_num]]",
  "econsentEditor.pageCount": "Page Count",
  "econsentEditor.pagePlaceholder":
    "Page [[curr_page_num]] of [[total_page_num]]",
  "econsentEditor.of": "of",
  "econsentEditor.x": "X",
  "econsentEditor.y": "Y",
  "econsentEditor.pageCountIsAppliedToThePrintDocument":
    "Page count is applied to the print document.",
  "econsentEditor.youMustEnterPageCountTooltip":
    "You must enter page count labels before you can save your changes to the footer.",
  "econsentEditor.headerTooltip":
    "The header content defined in these fields will be<br></br>applied to the print document.",
  "econsentEditor.footerTooltip":
    "The footer content defined in these fields will be<br></br>applied to the print document.",
  "econsentEditor.printContent": "Print Content",
  "econsentEditor.headerAndFooter": "Header and Footer",
  "econsentEditor.editHeader": "Edit Header",
  "econsentEditor.editFooter": "Edit Footer",
  "econsentEditor.addAnswerOption": "Add an answer option",
  "econsentEditor.addContentBlock": "Add content block",
  "econsentEditor.addImage": "Add Image",
  "econsentEditor.addMedia": "Add Media",
  "econsentEditor.addQuestionBlock": "Add Question Block",
  "econsentEditor.addSection": "Add section",
  "econsentEditor.addSignatureBlock": "Add signature block",
  "econsentEditor.addSignatureBlockDisabled":
    "You've added signature blocks for all of the available roles.",
  "econsentEditor.addTextBlock": "Add Text Block",
  "econsentEditor.alignCenterTitle": "Align Center",
  "econsentEditor.alignLeftTitle": "Align Left",
  "econsentEditor.alignNoneTitle": "Align None",
  "econsentEditor.alignRightTitle": "Align Right",
  "econsentEditor.answerLabel": "Answer Name",
  "econsentEditor.answerLabelContent": "Answer Text",
  "econsentEditor.answerText": "Answer Text",
  "econsentEditor.autoSave": "Autosave",
  "econsentEditor.blockConnected":
    "Data from this block will be sent to {sponsorName}.",
  "econsentEditor.blockTitle": "{sectionIndex}.{blockIndex}",
  "econsentEditor.browse": "Browse",
  "econsentEditor.cancel": "Cancel",
  "econsentEditor.confirm": "Confirm",
  "econsentEditor.leftColumn": "Left Column",
  "econsentEditor.center": "Center",
  "econsentEditor.rightColumn": "Right Column",
  "econsentEditor.save": "Save",
  "econsentEditor.input": "Input",
  "econsentEditor.changesFromPreviousSession":
    "Changes from your previous session weren't saved to Vault due to a connection error. Your changes are being saved now.",
  "econsentEditor.checkIn": "Check In",
  "econsentEditor.checkInModalDescription":
    "Clicking Check In will save your changes to Vault as a new document version, end your editing session, and unlock the document in Vault so others can edit it.",
  "econsentEditor.checkingInYourDocument":
    "Checking in the document. You'll be redirected to Vault when the check-in is complete.",
  "econsentEditor.chooseFileError":
    "Sorry, that file doesn't meet our requirements.<br></br>Please resize or reformat the file and try again.",
  "econsentEditor.clickToAddContentBlock": "Click to add content block",
  "econsentEditor.close": "Close",
  "econsentEditor.closeContentBlockModal": "Close add contact block dialog",
  "econsentEditor.closeDialog": "Close Dialog",
  "econsentEditor.closeModal": "Close dialog",
  "econsentEditor.closePopup": "Close popup",
  "econsentEditor.contentBlockAriaLabel":
    "Edit block {blockIndex} of section {sectionIndex}",
  "econsentEditor.contentBlocks": "Content Blocks",
  "econsentEditor.date": "Date",
  "econsentEditor.defaultRole": "Default",
  "econsentEditor.dimensions": "Dimensions: {dimensions}",
  "econsentEditor.documentConnectedToSponsor":
    "This document will send data to {sponsorName}.",
  "econsentEditor.documentName": "Document Name",
  "econsentEditor.documentUpdated": "Your document has been updated.",
  "econsentEditor.downloadDocx": "Download Word Document",
  "econsentEditor.downloadPDF": "Download PDF",
  "econsentEditor.downloadPDFError": "There was an error saving the PDF",
  "econsentEditor.dropAFileHereOrBrowseForOne":
    "Drop a file here or browse for one to add.",
  "econsentEditor.dropANewImageHereOrBrowseForOne":
    "Drop a new image here or browse for one.",
  "econsentEditor.dropYourFileHereToUploadIt":
    "Drop your file here to upload it.",
  "econsentEditor.eSignaturePlaceholderLabel": "eSignature Placeholder",
  "econsentEditor.eSignaturePlaceholderPlaceholder":
    "Your signature placeholder content",
  "econsentEditor.edit": "Edit",
  "econsentEditor.editImage": "Edit Image",
  "econsentEditor.editorPlaceHolder": "Your content",
  "econsentEditor.error.couldnt-be-saved-title":
    "Your changes couldn't be saved",
  "econsentEditor.error.couldnt-checkin-title":
    "Your changes couldn't be checked in",
  "econsentEditor.error.createOrGetEConsentByAuthor.415-message":
    "Sorry, the file type of the document you're trying to open isn't currently supported. Please ensure it was uploaded to Vault as a .PDF or .DOCX file and try again.",
  "econsentEditor.error.createOrGetEConsentByAuthor.415-title":
    "Your document couldn't be opened",
  "econsentEditor.error.createOrGetEConsentByAuthor.500-message":
    "Please return to Vault and try again, and contact your Vault administrator if you continue to see this error.",
  "econsentEditor.error.createOrGetEConsentByAuthor.500-title":
    "Your document couldn't be opened",
  "econsentEditor.error.previewCouldntBeCreated": "Preview Couldn't Be Created",
  "econsentEditor.error.previewCouldntBeCreatedMessage":
    "Sorry, we weren't able to create the preview. Please try again later and contact your Vault administrator if you continue to see this error.",
  "econsentEditor.error.saveEconsentByAuthor.400-checkin-message":
    "We couldn't check in your document due to restricted HTML formatting. Remove the restricted formatting in the blocks with borders and then try to check in your document again. Contact your site administrator if you need more help.",
  "econsentEditor.error.saveEconsentByAuthor.400-checkin-title":
    "Document not checked in",
  "econsentEditor.error.saveEconsentByAuthor.400-message":
    "We couldn't save your document due to restricted HTML formatting. Remove the restricted formatting in the blocks with borders and then try to save your document again. Contact your site administrator if you need more help.",
  "econsentEditor.error.saveEconsentByAuthor.400-title": "Document not saved",
  "econsentEditor.error.saveEconsentByAuthor.403-checkin-message":
    "We couldn't check in the document because the document is no longer checked out to you. Please return to Vault to check out the document and continue editing.",
  "econsentEditor.error.saveEconsentByAuthor.403-message":
    "We couldn't save your changes to Vault because the document is no longer checked out to you. Please return to Vault to check out the document and continue editing.",
  "econsentEditor.error.saveEconsentByAuthor.500-message":
    "Please save or check in the document again, and contact your Vault administrator if you continue to see this error.",
  "econsentEditor.error.saveEconsentByAuthor.503-checkin-message":
    "We couldn't check in your document to Vault due to a connection error. Please reopen the editor from Vault to automatically retry saving your changes.",
  "econsentEditor.error.saveEconsentByAuthor.503-checkin-title":
    "Your document couldn't be checked in",
  "econsentEditor.error.saveEconsentByAuthor.503-message":
    "We couldn't save your changes to Vault due to a connection error. Please reopen the editor from Vault to automatically retry saving your changes.",
  "econsentEditor.error.session-expired-message":
    "Please log in through Vault to continue editing.",
  "econsentEditor.error.updateEConsentByAuthor.403-message":
    "We couldn't autosave your most recent changes because the document is no longer checked out to you. Please return to Vault to check out the document and continue editing.",
  "econsentEditor.fileSize": "File Size: {size}",
  "econsentEditor.fileUploaded": "File Uploaded",
  "econsentEditor.format": "Format: <b>{format}</b>",
  "econsentEditor.getPDFTitle": "eConsent ({docId}) {dateString}.pdf",
  "econsentEditor.help": "Help",
  "econsentEditor.image": "Image",
  "econsentEditor.imageAlignment": "Alignment:",
  "econsentEditor.imageDescriptionLabel": "Description",
  "econsentEditor.imageDescriptionPlaceholder":
    "This description is used as alternative text for screen readers and accessibility.",
  "econsentEditor.imageFormatsAccepted": "Formats: <b>{imageFormats}</b>",
  "econsentEditor.imageType": "Type: {type}",
  "econsentEditor.images": "Image",
  "econsentEditor.importCodeLabel": "Import code",
  "econsentEditor.importCodeModalError": "Invalid Import Code",
  "econsentEditor.importCodeModalTitle": "Paste Import Code",
  "econsentEditor.importCodeServerError":
    "Sorry, we couldn't verify the code. Please try pasting the code again, and contact your Vault administrator if you continue to see this error.",
  "econsentEditor.importDocumentWillReplaceContent":
    "Importing a document will replace the current content, but you'll be able to undo your changes if you need to.",
  "econsentEditor.importJson": "Import JSON",
  "econsentEditor.importJsonFinalizeTitle": "Content Uploaded Successfully",
  "econsentEditor.importJsonTitle": "Upload JSON",
  "econsentEditor.importModalButton": "Import Document",
  "econsentEditor.importPopupLabel": "Import Methods",
  "econsentEditor.importTitle": "Import",
  "econsentEditor.importWithCode": "Import With Code",
  "econsentEditor.importWordDoc": "Import Microsoft Word (.docx)",
  "econsentEditor.jsonInputAriaLabel": "JSON input",
  "econsentEditor.larGuardian": "Guardian / Representative",
  "econsentEditor.caregiver": "Caregiver",
  "econsentEditor.lastAutoSave": "Last autosave: {time}",
  "econsentEditor.maxFileSize": "Max File Size: <b>{filesize}</b>",
  "econsentEditor.media": "Media",
  "econsentEditor.mediaInputAriaLabel": "Media input",
  "econsentEditor.missingFollowingElements":
    "Your document is missing the following elements:",
  "econsentEditor.missingFooter": "A footer",
  "econsentEditor.missingHeader": "A header",
  "econsentEditor.missingLabelsTooltip":
    "You cannot save or check in this document while signature, printed name, or printed date labels are missing.",
  "econsentEditor.missingSignatureBlockForParticipant":
    "A signature block for a participant or representative",
  "econsentEditor.missingSignatureBlockForSite":
    "A signature block for a site staff member",
  "econsentEditor.missingSignatureBlockForRole":
    "A signature block for one of the roles assigned to a question",
  "econsentEditor.missingSignatureLabelsTooltip":
    "You must enter labels for the Signature, Printed Name, and Date fields on all Signature Blocks before you can check in or save your document to Vault.",
  "econsentEditor.missingSiteSignaturePlaceholderTooltip":
    "You must enter an eSignature Placeholder before you can check in or save your document to Vault.",
  "econsentEditor.moveContentBlockDownTitle": "Move block {index} down one",
  "econsentEditor.moveContentBlockUpTitle": "Move block {index} up one",
  "econsentEditor.moveOptionDownOne": "Move option down one",
  "econsentEditor.moveOptionUpOne": "Move option up one",
  "econsentEditor.moveSectionDownTitle": "Move section {index} down one",
  "econsentEditor.moveSectionUpTitle": "Move section {index} up one",
  "econsentEditor.no": "No",
  "econsentEditor.noIdProvided":
    "Invalid address. Please access the editor from Vault.",
  "econsentEditor.optionLabel": "Option {optionNumber} of block {blockNumber}",
  "econsentEditor.participant": "Participant",
  "econsentEditor.placeholder": "Placeholder",
  "econsentEditor.popupSavedToVaultVersion":
    "Version {majorVersion}.{minorVersion}",
  "econsentEditor.printedName": "Printed Name",
  "econsentEditor.problemWithFileUpload":
    "Sorry, we couldn't upload your file. Please try again.",
  "econsentEditor.proxy": "Proxy",
  "econsentEditor.questionBlock": "Question Block",
  "econsentEditor.questionLabel": "Question Name",
  "econsentEditor.questionLabelContent": "Question Text Content",
  "econsentEditor.questionRoleTooltip":
    "You can optionally assign this block to a role. By default, this block is assigned to the first available recipient in the following order: Proxy, Guardian(s), Participant, Witness, Translator.",
  "econsentEditor.redactor.subheading": "Subheading {number}",
  "econsentEditor.redirectingToVault":
    "Check-in complete. You're being redirected to Vault.",
  "econsentEditor.removeContentBlockTitle": "Remove block {index}",
  "econsentEditor.removeMedia": "Remove media",
  "econsentEditor.removeOption": "Remove option",
  "econsentEditor.removeSectionTitle": "Remove section {index}",
  "econsentEditor.removeThisBlock": "Remove this block?",
  "econsentEditor.removeThisOption": "Remove this option?",
  "econsentEditor.removeThisSection": "Remove this section?",
  "econsentEditor.requiredPatientFriendlyTitle":
    "Patient-Friendly Title (Required)",
  "econsentEditor.requiredResponse": "Required Response",
  "econsentEditor.requiresLiveSignature": "Requires Hand-Drawn Signature",
  "econsentEditor.requiresLiveSignatureSponsorTooltip":
    "Participants will need to draw their signature. This was set by the sponsor and can't be disabled while the form is connected.",
  "econsentEditor.requiresLiveSignatureTooltip":
    "Participants will need to draw their signature on a device.",
  "econsentEditor.requiresUnlockCode": "Requires Unlock Code to Sign",
  "econsentEditor.resumeEditing": "Resume Editing",
  "econsentEditor.returnToVault": "Click here to return to Vault",
  "econsentEditor.rightToLeftLanguage": "Right to Left Language",
  "econsentEditor.role": "Role",
  "econsentEditor.saveIncompleteEconsent": "Save incomplete eConsent?",
  "econsentEditor.saveToVault": "Save to Vault",
  "econsentEditor.saveToVaultModalDescription":
    "Clicking Save to Vault will save your changes to Vault as a new document version without ending your editing session.",
  "econsentEditor.saved": "Saved",
  "econsentEditor.savedToVault": "{version} Saved to Vault",
  "econsentEditor.saving": "Saving",
  "econsentEditor.savingToVault": "Saving to Vault",
  "econsentEditor.savingYourProgress": "Saving your progress",
  "econsentEditor.savingYourProgressDescription":
    "You don't need to manually back up your progress; we've got you covered. Click Save to Vault when you're ready to store a new version in Vault; otherwise, we'll automatically capture your progress in the editor.",
  "econsentEditor.sectionAriaLabel": "Edit section number {sectionIndex}",
  "econsentEditor.sectionNumber": "Section {number}",
  "econsentEditor.sectionTitle": "{sectionIndex}.",
  "econsentEditor.sections": "Sections",
  "econsentEditor.settings": "Settings",
  "econsentEditor.setDocumentToRTL": "Set Document to Right to Left",
  "econsentEditor.setDocumentToLTR": "Set Document to Left to Right",
  "econsentEditor.setDocumentToRTLDescription":
    "Enabling this setting mirrors UI elements to be displayed for right to left languages. Select Confirm to change the UI to be displayed from right to left.",
  "econsentEditor.setDocumentToLTRDescription":
    "Disabling this setting mirrors UI elements to be displayed for left to right languages. Select Confirm to change the UI to be displayed from left to right.",
  "econsentEditor.showTextContent": "Show Text Content",
  "econsentEditor.showTitle": "Show Title",
  "econsentEditor.shownInVault": "(shown in Vault)",
  "econsentEditor.signature": "Signature",
  "econsentEditor.signatureBlock": "Signature Block",
  "econsentEditor.signatureLabel": "Signature Name",
  "econsentEditor.signatureLabelContent": "Signature Text Content",
  "econsentEditor.signaturePreview":
    "A text field will be displayed here on the consent form.",
  "econsentEditor.editorSettings": "Editor settings",
  "econsentEditor.lockUnlockForSites": "Lock/Unlock for Sites",
  "econsentEditor.lockUnlockTooltip":
    "This setting locks or unlocks the whole document. Select Lock All to prevent sites from editing the document or Unlock All to allow it. You can also lock or unlock specific content blocks as needed.",
  "econsentEditor.lockAll": "Lock All",
  "econsentEditor.unlockAll": "Unlock All",
  "econsentEditor.allowEdits": "Allow Edits",
  "econsentEditor.lockAllMessage":
    "Selecting this toggle moves all toggles to the locked state and prevents site staff from making changes to this eConsent form.",
  "econsentEditor.unlockAllMessage":
    "Selecting this toggle moves all toggles to the unlocked state and allows sites to edit related content.",
  "econsentEditor.signatureRequirements": "Signature Requirements",
  "econsentEditor.signatureShouldMatchQuestion":
    "eConsent documents should have a signature block for each role that's assigned a question.",
  "econsentEditor.signingOrder": "Signing Order",
  "econsentEditor.signingOrderTooltip":
    "You can enter a Signing Order for this document if a participant needs to sign it before or after signing other documents. For example, a document with a Signing Order of 1 must be signed before a document with a Signing Order of 2.",
  "econsentEditor.site": "Site",
  "econsentEditor.tableOfContentContentBlockAriaLabel":
    "Content block {blockName} index {blockIndex} of section {sectionIndex}",
  "econsentEditor.tableOfContentSectionAriaLabel":
    "Section {sectionName} index {sectionIndex}",
  "econsentEditor.tableOfContents": "Table of Contents",
  "econsentEditor.textBlock": "Text Block",
  "econsentEditor.theSponsor": "the sponsor",
  "econsentEditor.title": "Title",
  "econsentEditor.tooManyFiles":
    "Sorry, you can only upload one file at a time.",
  "econsentEditor.translator": "Translator",
  "econsentEditor.undoChanges": "Undo Changes",
  "econsentEditor.unlockCodeTooltip":
    "Participants need a code to sign this document. Site staff will create the code in the preview opened from SiteVault and provide it to them.",
  "econsentEditor.unsupportedContent": "Unsupported HTML removed",
  "econsentEditor.unsupportedContentDescription":
    "We removed unsupported HTML from what you pasted into the editor. Please verify that the content you wanted was added.",
  "econsentEditor.untitled": "Untitled",
  "econsentEditor.update": "Update",
  "econsentEditor.versionComments": "Version Comments (Optional)",
  "econsentEditor.video": "Video",
  "econsentEditor.webPreview": "Web Preview",
  "econsentEditor.witness": "Witness",
  "econsentEditor.wordInputAriaLabel": "Word input",
  "econsentEditor.yes": "Yes",
  "econsentEditor.yourChangesAreBeingSavedRightNow":
    "Your changes are being saved right now.",
  "econsentEditor.yourImage": "Your image",
  "econsentEditor.allowSiteEdits": "Allow Site Edits",
  "econsentEditor.allowSiteEditsTooltip":
    "Enabling this toggle will allow sites to provide details or make changes to the content within this block.",
  "econsentEditor.lockedContentTooltip":
    "This content block has been locked by the study sponsor. Please contact the sponsor if you need to edit the content.",
  "econsentEditor.lockedContentPopupHeader":
    "This document includes locked content.",
  "econsentEditor.lockedContentPopupMessage":
    "Please contact the study sponsor if you need to edit the locked content.",
  "econsentEditor.rightToLeftTooltip":
    "Enabling Right to Left Language updates the form to correctly display the UI for languages that are written and read from right to left, such as Arabic and Hebrew. The alignment of text elements is adjusted accordingly.",
  "econsentGenerate.errorMessage":
    "Sorry, we couldn't display the QR code. <br></br>Please refresh this page or visit the link below.",
  "econsentGenerate.helpText":
    "If your device can't scan the QR code, you can go to this web address to begin.",
  "econsentGenerate.instructions":
    "Scan the QR code below with your device's camera to begin the in-person consent process, or go to the address below.",
  "econsentGenerate.qrCodeAltText": "QR Code",
  "econsentGenerate.somethingWentWrong":
    "Sorry, we couldn't create the in-person consent code because the document wasn't sent successfully. <br></br>Please resend the document then view the code.",
  "econsentGenerate.title": "In-Person Consent Code",
  "econsentPreview.actionNotAvailableInPreview":
    "This action isn't available in preview mode",
  "econsentPreview.appUserActions": "APP USER ACTIONS",
  "econsentPreview.askForHelpUnavailableInPreview":
    "An actual study participant could complete a simple request form, notifying the site of their concern.",
  "econsentPreview.declineEconsentUnavailable":
    "A real study participant would be sent to their document list at this time.",
  "econsentPreview.expiredGenerationInformation":
    "{firstName} {lastName} generated this preview on {generatedDate}. This link expired on {expirationDate}. Please request a new link from the original provider.",
  "econsentPreview.failedToOpenPreview":
    "Sorry, we weren't able to open the preview. Please refresh the page to try again, and contact the original link provider if you still can't access it.",
  "econsentPreview.generationInformation":
    "{firstName} {lastName} generated this preview on {generatedDate}. This link will expire on {expirationDate}.",
  "econsentPreview.help": "Help",
  "econsentPreview.invalidEditorPreviewLinkDescription":
    "Sorry, we don't have a preview at that address. Please return to the editor and open the preview from there.",
  "econsentPreview.invalidGlobalPreviewLinkDescription":
    "Sorry, we don't have a preview at that address. Please check the link and contact the original provider if you still can't access it.",
  "econsentPreview.invalidLink": "Invalid Link",
  "econsentPreview.linkExpired": "Link Expired",
  "econsentPreview.loginToVault":
    "You must be logged in to view this preview. Please log in through Vault.",
  "econsentPreview.invalidSignatoryRole": "Invalid Signatory Role",
  "econsentPreview.invalidSignatoryRoleMessage":
    "The role in the URL is not supported. Please replace it with one of these supported roles: proxy, guardian, caregiver, participant, witness, or translator.",
  "econsentPreview.previewActions": "PREVIEW ACTIONS",
  "econsentPreview.previewCouldntBeOpened": "Preview Couldn't Be Opened",
  "econsentPreview.previewCouldntBeOpenedMessage":
    "Sorry, we weren't able to open the preview. Please refresh the page to try again.",
  "econsentPreview.previewDefaultName": "Signature Preview",
  "econsentPreview.previewModalMessage":
    "You can perform actions such as signing, declining, and downloading the document, but your actions won't be saved.",
  "econsentPreview.previewModalTitle": "This document is in preview mode",
  "econsentPreview.previewMode": "PREVIEW MODE",
  "econsentPreview.previewNotAvailable": "Preview No Longer Available",
  "econsentPreview.previewingPendingDocument":
    "You're previewing this document, but you have another document pending.",
  "econsentPreview.previewRemovedMessage":
    "Sorry, this preview was removed by the study site.",
  "econsentPreview.submitUnavailable":
    "A real study participant would be required to verify their identity with a code sent to their mobile phone at this time.",
  "econsentPreview.unlockUnavailable":
    "A real study participant would be required to enter an unlock code provided by their study site before proceeding.",
  "econsentUnlock.errorMessage":
    "Sorry, we couldn't unlock this document. Please refresh the page and try again.",
  "econsentUnlock.errorWrongCode":
    "Sorry, this code is invalid. Please double-check the code and try again.",
  "econsentViewer.liveSignatureResized":
    "The window was resized while signing. Please redraw your signature to make sure it is accurately captured.",
  "econsentViewer.apply": "Apply",
  "econsentViewer.askForStudyHelp": "Study Help",
  "econsentViewer.blockConflictMessage":
    "Another signatory already answered one or more questions on this form. Reload the form to restore your progress from the last checkpoint.",
  "econsentViewer.blockConflictTitle": "Question Answered by Another User",
  "econsentViewer.blockLabel": "Scroll to {name}",
  "econsentViewer.cancel": "Cancel",
  "econsentViewer.clear": "Clear",
  "econsentViewer.clearSignature": "Clear Signature",
  "econsentViewer.close": "Close",
  "econsentViewer.closeDialog": "Close dialog",
  "econsentViewer.closeTableOfContents": "Close Table of Contents",
  "econsentViewer.codeExpiredMessage": "This code has expired.",
  "econsentViewer.codeExpiryMessage": "This code will expire in {expiration}.",
  "econsentViewer.codeExpiryMessageDate":
    "This code will expire on {expiration}.",
  "econsentViewer.codeWillExpire": "Code will expire in:",
  "econsentViewer.collapseSection": "Collapse section",
  "econsentViewer.collapseSectionLabel": "Collapse section titled {name}",
  "econsentViewer.conflictMessage":
    "Another user declined this form and you can no longer complete it. Please select OK to view your remaining tasks.",
  "econsentViewer.conflictTitle": "Document declined by another user",
  "econsentViewer.copiedTooltip": "Copied",
  "econsentViewer.copyButtonTitle": "Copy to Clipboard",
  "econsentViewer.createCodeButton": "Create Code",
  "econsentViewer.createNewCode": "Create New Code",
  "econsentViewer.createUnlockCodeMessage":
    "Release this document for signature by sharing the unlock code with your participants or signatories.",
  "econsentViewer.createUnlockCodeTitle": "Create Unlock Code",
  "econsentViewer.date": "Date",
  "econsentViewer.dateHelperText": "(Added on submission)",
  "econsentViewer.declineButton": "Decline",
  "econsentViewer.declineDocument": "Decline Document",
  "econsentViewer.declineErrored":
    "Sorry, we're not able to submit your decision right now. Please try again later and contact your site if the problem continues.",
  "econsentViewer.declineModalContentOtherRoles":
    "This declines the document for all reviewers and might mean the participant can't take part in the study. Please discuss your decision with your site coordinator if you're unsure.",
  "econsentViewer.declineModalContentParticipant":
    "This might mean you can't participate in this study. Please discuss your decision with your site coordinator if you're unsure.",
  "econsentViewer.declineModalTitle": "Decline this document?",
  "econsentViewer.declineToSignLink": "I don't want to sign this document.",
  "econsentViewer.document": "Document",
  "econsentViewer.documentNotAvailable": "This document is no longer available",
  "econsentViewer.documentPendingDeclineError":
    "You have another document pending. You can't decline this document until you complete all pending documents.",
  "econsentViewer.documentPendingSignError":
    "You have another document pending. You can't sign this document until you complete all pending documents.",
  "econsentViewer.documentRemoved":
    "Sorry, this document was removed by the study site. Please select OK to view your current tasks.",
  "econsentViewer.documentDeclined": "Document Declined",
  "econsentViewer.documentSubmitted": "Document Submitted",
  "econsentViewer.documentSubmittedOn":
    "You submitted this document on {date}.",
  "econsentViewer.documentSubmittedToday": "You submitted this document today.",
  "econsentViewer.documentSubmittedYesterday":
    "You submitted this document yesterday.",
  "econsentViewer.downloadDocx": "Download Word Document",
  "econsentViewer.downloadPDF": "Download PDF",
  "econsentViewer.drawYourOwnSignature": "Draw Your Own Signature",
  "econsentViewer.enterUnlockCode":
    "Enter the unlock code that your study site gave you.",
  "econsentViewer.enterUnlockCodeLabel": "Enter Unlock Code",
  "econsentViewer.expandSection": "Expand section",
  "econsentViewer.expandSectionLabel": "Expand section titled {name}",
  "econsentViewer.exportMenuLabel": "Export Methods",
  "econsentViewer.generateShareCodeError":
    "Sorry, we couldn't create the code. Please try to share the document again, and contact your Vault administrator if you continue to see this error.",
  "econsentViewer.hotdogMenuTitle": "More Actions",
  "econsentViewer.import": "Import",
  "econsentViewer.importWithCode": "Import With Code",
  "econsentViewer.nextSection": "Next Section",
  "econsentViewer.nextSectionLabel": "Go to next section titled {name}",
  "econsentViewer.orHandDrawSignature":
    "or you can <button>draw your own signature</button>",
  "econsentViewer.pageExpiredContent":
    "Please check your email for the password reset link or select OK to return to the login page.",
  "econsentViewer.pendingDocuments":
    "You have another document pending. You can't sign this document until you complete all pending documents.",
  "econsentViewer.pleaseLoginAgain":
    "Please log in again to submit this consent form.",
  "econsentViewer.pleaseReloadBrowser":
    "Please refresh the page. Your progress will be restored from the last checkpoint.",
  "econsentViewer.pleaseReviewAndSignThisDocument":
    "Please review and sign this document. {link}",
  "econsentViewer.previousSection": "Previous Section",
  "econsentViewer.previousSectionLabel": "Go to previous section titled {name}",
  "econsentViewer.printedName": "Printed Name",
  "econsentViewer.receiveACall": "Receive A Call",
  "econsentViewer.receiveAnotherCall": "Receive Another Call",
  "econsentViewer.responseRequired": "Your response is required",
  "econsentViewer.reviewerDeclinedOn":
    "Another reviewer declined this document on {date}.",
  "econsentViewer.reviewerDeclinedToday":
    "Another reviewer declined this document today.",
  "econsentViewer.reviewerDeclinedYesterday":
    "Another reviewer declined this document yesterday.",
  "econsentViewer.sectionLabel": "Select section titled {name}",
  "econsentViewer.sectionNumber": "Section {number}",
  "econsentViewer.selectOption": "Select an option",
  "econsentViewer.sendAText": "Send A Text",
  "econsentViewer.sendAnotherText": "Send Another Text",
  "econsentViewer.shareButtonText": "Share With Code",
  "econsentViewer.shareButtonTitle": "Share",
  "econsentViewer.shareModalHelpLink": "Editing eConsent Forms",
  "econsentViewer.shareModalHelpMessage":
    "See {helpLink} in SiteVault Help for more information.",
  "econsentViewer.shareModalLoginLink": "log in to Vault",
  "econsentViewer.shareModalTitle": "Share With Import Code",
  "econsentViewer.shareStepFour":
    "Save the document to Vault. We recommend saving a version to Vault before you begin editing, so that you always have a version that represents the original imported content.",
  "econsentViewer.shareStepOne":
    "Copy the import code above. You can send this code to someone else or use it to import this document into your own Vault.",
  "econsentViewer.shareStepThree":
    "Click the {import} button, select {importWithCode}, and paste the code to import the content.",
  "econsentViewer.shareStepTwo":
    "If importing into your own Vault, {loginLink}, create a new blank ICF or a new draft of an existing blank ICF, and edit the eConsent form.",
  "econsentViewer.signEconsentDocument": "Sign Document",
  "econsentViewer.signHere": "Sign Here",
  "econsentViewer.signature": "Signature",
  "econsentViewer.signatureImageAlt": "{name}'s signature",
  "econsentViewer.signatureRequiresCode":
    "This document is ready to sign, but you need a code from the study site. Click here when they give you the code.",
  "econsentViewer.submissionErrored":
    "Sorry, we're not able to submit your document right now. Please try again later and contact your site if the problem continues.",
  "econsentViewer.submit": "Submit",
  "econsentViewer.submitDescription":
    "I have reviewed the document and am ready to submit the signed copy.",
  "econsentViewer.tableOfContents": "Table of Contents",
  "econsentViewer.thereWasAProblem": "There was a problem",
  "econsentViewer.tryAgainDescriptionCall":
    "Make sure your device has a signal and request another code.",
  "econsentViewer.tryAgainDescriptionText":
    "Make sure your device has a network connection and request another code.",
  "econsentViewer.unlockCodeErrorMessage":
    "Sorry, we couldn't create the unlock code. Please refresh the page and try again.",
  "econsentViewer.unlockCodeErrorTitle": "Your code couldn't be created",
  "econsentViewer.unlockCodeSubtext": "Unlock Code: {code}",
  "econsentViewer.unlockCodeTitle": "Unlock Code",
  "econsentViewer.unlockSuccessful": "Unlock Successful",
  "econsentViewer.useTapToSign": "Tap to Sign Instead",
  "econsentViewer.verifyIdentity": "Confirm Your Identity – Text",
  "econsentViewer.verifyIdentityVoiceCall":
    "Confirm Your Identity – Voice Call",
  "econsentViewer.viewEverythingToSign":
    "Please read or complete all sections on the table of contents before signing this document.",
  "econsentViewer.viewUncompletedSection": "View uncompleted section.",
  "econsentViewer.youDeclinedOn": "You declined this document on {date}.",
  "econsentViewer.youDeclinedToday": "You declined this document today.",
  "econsentViewer.youDeclinedYesterday":
    "You declined this document yesterday.",
  "econsentViewer.yourSessionHasTimedOut": "Your session has timed out",
  "enterMfa.calling": "Calling ({time}s)",
  "enterMfa.callingPhoneNumberLinkedToEmail":
    "We're calling the phone number linked to <b><span>{email}</span></b> to give you a six-digit verification code.",
  "enterMfa.didntGetTheCallCode":
    "Didn't get the code? Make sure your phone has a signal and then try another call.",
  "enterMfa.didntGetTheCode": "Didn't get the code?",
  "enterMfa.enterMFACode": "Verification code",
  "enterMfa.enterMfaStep": "Step 2 of 2: ",
  "enterMfa.getAnotherCall": "Get Another Call",
  "enterMfa.getVoiceCall": "Get a Voice Call",
  "enterMfa.invalidCode":
    "The code you entered is invalid. Please reenter the code and try again.",
  "enterMfa.noMoreAttempts":
    "Still didn't get the code? Sorry, we're having trouble reaching you. Please contact your site administrator to verify your phone number.",
  "enterMfa.phoneNumberLinkedToEmail":
    "We've sent a six-digit verification code to the number linked to <b><span>{email}</span></b>.",
  "enterMfa.phoneNumberMessage":
    "We've sent a six-digit verification code to your number ending with <span><b>{digits}</b></span>.",
  "enterMfa.pleaseLoginAgain":
    "We logged you out while you were inactive. Please select OK to log in and verify your identity again.",
  "enterMfa.resend": "Send Another Code",
  "enterMfa.resendFailed":
    "We're unable to send you a verification code. Please log back in and try again.",
  "enterMfa.sent": "Sent ({time}s)",
  "enterMfa.title": "Confirm Your Identity – Text",
  "enterMfa.titleVoiceCall": "Confirm Your Identity – Voice Call",
  "enterMfa.tryVoiceCall":
    "Sorry, we're having trouble texting you. <br></br>Please try a voice call.",
  "enterMfa.voiceCallMessage":
    "We're calling your number ending with <span><b>{digits}</b></span> to give you a six-digit verification code.",
  "enterMfa.yourSessionHasTimedOut": "Your session has timed out",
  "entry.account": "Account",
  "entry.addedOnDate": "Added {date}",
  "entry.addedToday": "Added today",
  "entry.addedYesterday": "Added yesterday",
  "entry.continue": "Continue",
  "entry.declinedOnDate": "Declined {date}",
  "entry.declinedToday": "Declined today",
  "entry.declinedYesterday": "Declined yesterday",
  "entry.help": "Help",
  "entry.loggedOutWhileInactive":
    "We logged you out while you were inactive. Please select OK to log in again.",
  "entry.logout": "Log Out",
  "entry.myVeevaAccount": "MyVeeva Account",
  "entry.new": "New",
  "entry.password": "Password",
  "entry.privacyPolicy": "Privacy Policy",
  "entry.signedOnDate": "Signed {date}",
  "entry.signedToday": "Signed today",
  "entry.signedYesterday": "Signed yesterday",
  "entry.todo": "To Do",
  "entry.study": "Study",
  "entry.termsOfService": "Terms of Service",
  "entry.viewedOnDate": "Viewed {date}",
  "entry.viewedToday": "Viewed today",
  "entry.viewedYesterday": "Viewed yesterday",
  "entry.back": "Back",
  "entry.navigateBack": "navigate back",
  "account.helpGuide": "Help Guide",
  "account.helpGuideSecondaryText": "Search App FAQs and Help Topics",
  "account.settings": "Settings",
  "account.formattedName": "{firstName} {lastName}",
  "account.privacyPolicy": "Privacy Policy",
  "account.logOut": "Log Out",
  "activateNewStudy.cantActivateStudy": "Can't Activate Study",
  "activateNewStudy.differentRegionError":
    "This activation code is for a study in a different geographic region from your current account. Please log out and use this activation code to register a new account.",
  "activateNewStudy.studyAddedSuccessfully":
    "Study {studyName} added successfully.",
  "activateNewStudy.createNewAccountError":
    "You have to create a new account to use this code. Please log out and use this activation code to register a new account in the MyVeeva mobile app.",
  "settings.name": "Name",
  "settings.formattedName": "{firstName} {lastName}",
  "settings.username": "Username",
  "settings.email": "Email",
  "settings.phone": "Phone",
  "settings.language": "Language",
  "settings.timeZone": "Time Zone",
  "settings.languageNoticeMobile":
    "Our app and the messages we send you will be in the language you select.",
  "settings.timeZoneNoticeMobile":
    "We use your time zone to remind you to complete your tasks on time.",
  "settings.apply": "Apply",
  "settings.confirm": "Confirm",
  "settings.cancel": "Cancel",
  "settings.invalidEmail":
    "We are unable to accept that email address. Please enter a different email address.",
  "settings.emailUpdateError":
    "Sorry, we couldn't update your email. Please try again.",
  "settings.editUsername": "Edit Username",
  "settings.editUsernameConfirmPassword":
    "To edit your username, please enter your password.",
  "settings.usernameUpdated": "Username Updated",
  "settings.addPhoneNumber": "Add Phone Number",
  "settings.phoneUpdated": "Phone Updated",
  "settings.phonePasswordMessage":
    "To update your phone number, please enter your password.",
  "settings.editPhone": "Edit Phone",
  "settings.usernameAlreadyTaken":
    "Username is already in use. Try a different username.",
  "settings.emailUpdated": "Email Updated",
  "settings.addEmail": "Add Email",
  "settings.editEmail": "Edit Email",
  "settings.editEmailConfirmPassword":
    "To update your email, please enter your password.",
  "settings.emailSent":
    "We sent an email to <span><b>{email}</b></span>. Click the link in the email to complete adding your email.",
  "settings.emailPendingBanner":
    "We sent an email to <span><b>{email}</b></span>. You'll need to verify your new email before you can make changes to other settings. {resendLink}",
  "settings.resendEmail": "Resend Email",
  "settings.emailTooltip":
    "You'll need to verify your identity to change your email address. We'll continue using your current email address for logging in until you do.",
  "settings.leavePageMessage":
    "Do you want to leave this page? Changes you made may not be saved.",
  "settings.usernameUpdateError":
    "Sorry, we couldn't update your username. Please try again.",
  "settings.phoneTooltip":
    "We use your phone number only to verify your identity.",
  "settings.languageTooltip":
    "Our app and the messages we send you will be in the language you select. Study materials that your study team sends you may not be available in the language you select.",
  "settings.timeZoneTooltip":
    "Your time zone is controlled by your browser settings.",
  "settings.phoneNumber": "Phone Number",
  "settings.passwordModalUserError": "Incorrect password",
  "settings.passwordModalServerError":
    "Sorry, we're having server issues. Please try again.",
  "settings.passwordModalTitle": "Confirm Your Identity",
  "settings.passwordModalContent":
    "Please enter your password to confirm your identity.",
  "settings.password": "Password",
  "settings.editPassword": "Edit Password",
  "settings.editPasswordMessage":
    "To edit your password, please enter your current password.",
  "settings.passwordUpdated": "Password Updated",
  "settings.currentPassword": "Current Password",
  "settings.save": "Save",
  "settings.continue": "Continue",
  "settings.phoneMfaTitleCall": "Confirm Phone Number – Voice Call",
  "settings.phoneMfaTitleText": "Confirm Phone Number – Text",
  "settings.closeDialog": "Close Dialog",
  "settings.changeLanguageTitle": "Change language?",
  "settings.confirmLanguageChange":
    "Our app and the messages we send you will be in {language}.",
  "settings.change": "Change",
  "settings.loggedOut": "Logged out",
  "settings.loggedOutWhileInactive":
    "We logged you out while you were inactive. Please select OK to log in again.",
  "settings.undo": "Undo",
  "settings.languageUpdated": "Language Updated",
  "settings.languageChangeErrorDesktop":
    "Sorry, we couldn't update your preferred language. Please try again.",
  "settings.languageChangeErrorMobile":
    "Sorry, we couldn't update your preferred language. <br></br> Please try again.",
  "settings.timeZoneChangeErrorMobile":
    "Sorry, we couldn't update your time zone. <br></br> Please try again.",
  "settings.linkExpired": "Link Expired",
  "settings.linkExpiredMessage":
    "Please return to the Settings page and request a new link.",
  "settings.returnToSettings": "Return to Settings",
  "settings.viewSteps": "View Steps",
  "settings.requestAccountDeletion": "Request Account Deletion",
  "account.myStudies": "My Studies",
  "account.myStudies.switchStudies": "Switch Studies",
  "account.myStudies.close": "Close",
  "account.myStudies.participant": "Participant",
  "account.myStudies.proxy": "Proxy for {firstName} {lastName}",
  "account.myStudies.translator": "Translator for {firstName} {lastName}",
  "account.myStudies.witness": "Witness for {firstName} {lastName}",
  "account.myStudies.guardian":
    "Guardian/Representative of {firstName} {lastName}",
  "account.myStudies.caregiver": "Caregiver for {firstName} {lastName}",
  "account.myStudies.cancel": "Cancel",
  "account.myStudies.switch": "Switch",
  "account.myStudies.notificationCountSingle": "{count} notification",
  "account.myStudies.notificationCountMultiple": "{count} notifications",
  "account.myStudies.activateNewStudy": "Activate New Study",
  "account.myStudies.switchedToStudy": "Switched to {studyName}.",
  "todo.today": "Today",
  "todo.tomorrow": "Tomorrow",
  "todo.noItemsToday": "No items due today.",
  "todo.noItemsTomorrow": "No items due tomorrow.",
  "todo.completeAsNeeded": "COMPLETE AS NEEDED",
  "todo.viewCompleted": "View Completed",
  "todo.upcomingVisits": "Upcoming Visits",
  "todo.addedToday": "Added today at {time}",
  "todo.addedYesterday": "Added yesterday at {time}",
  "todo.addedOnDate": "Added {date}",
  "todo.surveyDueToday": "Due today by {date}",
  "todo.surveyDueTomorrow": "Due tomorrow by {date}",
  "todo.surveyDue": "Due by {date}",
  "todo.availableToday": "Available today at {date}",
  "todo.availableTomorrow": "Available tomorrow at {date}",
  "todo.availableTime": "Available at {time}",
  "todo.availableUntilDate": "Available until {date}",
  "todo.availableUntilToday": "Available until {time}",
  "todo.availableUntilTomorrow": "Available until tomorrow at {time}",
  "todo.visitsDateAndTime": "{startTime} - {endTime}",
  "todo.resume": "Resume",
  "todo.completed": "Completed",
  "todo.completedToday": "Completed today at {time}",
  "todo.somethingWentWrong": "Something went wrong",
  "todo.visitNotFoundMessage": "Select OK to view your current to-do's.",
  "todo.visitNotFoundTitle": "Visit Not Available",
  "todo.ok": "OK",
  "todo.newVersion": "New Version",
  "todo.close": "Close",
  "todo.completedVersion": "Version",
  "todo.ariaLabelBlocked": "{heading} {date} blocked",
  "study.participantId": "Participant ID: {participantId}",
  "study.studyImageAlt": "Study Image",
  "study.primaryContact": "Primary Contact",
  "study.phone": "Phone",
  "study.email": "Email",
  "resources.popupsBlockedMessage": "Enable popups to see this resource.",
  "study.resources": "Resources",
  "study.sharedResourceCount": "{resourceCount} Shared Resources",
  "study.oneSharedResource": "{resourceCount} Shared Resource",
  "study.sharedDocuments": "Shared Documents",
  "study.websiteLinks": "Website Links",
  "study.mockResourceTitle": "Site Shared Resource",
  "study.mockResourceDate": "Added [Month, Day, Year]",
  "study.consentForms": "Consent Forms",
  "study.consentCount":
    "{signedConsentCount} Signed, {declinedConsentCount} Declined",
  "study.details": "Details",
  "study.studyDescription": "Study Description",
  "study.needAssistance": "Need Assistance?",
  "study.askForStudyHelp": "Study Help",
  "study.askForHelpTooltip":
    "You can request non-emergency help from your study team with this form.",
  "askForHelp.close": "Close",
  "askForHelp.formDescription":
    "Use this form to request non-emergency help from your study team. The response will not be immediate, but a team member will contact you as soon as they can.",
  "askForHelp.formTitle": "Study Help Request",
  "askForHelp.topicLabel": "What topic do you have a question about?",
  "askForHelp.contactLabel": "What is your preferred method of contact?",
  "askForHelp.selectAnOption": "Select an Option",
  "askForHelp.submit": "Submit",
  "askForHelp.consentForm": "A Consent Form",
  "askForHelp.survey": "A Survey",
  "askForHelp.visit": "A Visit",
  "askForHelp.studyParticipation": "My Study Participation",
  "askForHelp.somethingElse": "Something Else",
  "askForHelp.phoneCall": "Phone Call",
  "askForHelp.textMessage": "Text Message",
  "askForHelp.email": "Email",
  "askForHelp.submittedHeading": "Your Help Request has been submitted.",
  "askForHelp.submittedDescription":
    "A study representative will respond when available. Response time will vary by location.",
  "askForHelp.helpRequestedAt": "Sent {date}",
  "askForHelp.requestDisabledTooltip":
    "The study team received your help request and will contact you through your preferred method of communication as soon as possible.",
  "askForHelp.submitRequestError":
    "Sorry, we couldn't submit your request. Please try again.",
  "eproDemo.disclaimer":
    "These surveys are for demo purposes only. No data is being tracked or collected.",
  "eproDemo.sendFeedback": "Send Feedback",
  "error.400-message":
    "Your browser sent a request that the server didn't understand. Please go back and try again.",
  "error.400-title": "Bad Request",
  "error.404-message":
    "The page you're looking for no longer exists. Please try again later and contact support if the problem continues.",
  "error.404-title": "Page Not Found",
  "error.410-title": "Page Gone",
  "error.internalServerError": "Internal Server Error",
  "error.internalServerErrorDescription":
    "An error occurred. Please try again later.",
  "error.internalServerErrorRefresh":
    "Sorry, we're having an issue with our servers. Please refresh the page and try again.",
  "error.sessionExpired": "Your session is expired",
  "error.sessionExpiredMessage":
    "Please select OK to return to the login page.",
  "forgotPassword.username": "Username",
  "forgotPassword.usernameRequired": "Username is required",
  "forgotPassword.instructions":
    "Please enter your username to recover your password. You will have to verify your identity with your account's phone and email after this step.",
  "forgotPassword.somethingWentWrong": "Something went wrong",
  "forgotPassword.title": "Forgot Password",
  "forgotPassword.emailSentMessage":
    "We've sent an email to the address associated with your username. The link in the email will allow you to reset your password.",
  "forgotPassword.contactSiteMessage":
    "If you don't receive the email or are unable to complete the password reset process, contact your site for an activation code.",
  "inPerson.fullName": "{firstName} {lastName}",
  "inPerson.selectWhoIsCompletingSurveys": "Select who is completing surveys",
  "inPerson.LAR_GUARDIAN": "Guardian / Representative",
  "inPerson.PARTICIPANT": "Participant",
  "inPerson.PROXY": "Proxy",
  "inPerson.SITE": "Site",
  "inPerson.TRANSLATOR": "Translator",
  "inPerson.WITNESS": "Witness",
  "inPerson.caregiver": "Caregiver",
  "inPerson.allDocumentsHaveBeenSigned": "All documents have been signed",
  "inPerson.begin": "Begin",
  "inPerson.calendarInputTitle": "Open Calendar",
  "inPerson.cancel": "Cancel",
  "inPerson.close": "Close",
  "inPerson.confirmCancel": "Confirm Cancelation",
  "inPerson.confirmCancelMessage": "Select OK to cancel the session.",
  "inPerson.confirmCheckbox": "I confirm that I am the individual named above.",
  "inPerson.confirmEndSession": "Confirm End Session",
  "inPerson.confirmEndSessionMessage": "Select OK to end the session.",
  "inPerson.confirmMessage":
    "Please enter the following information to confirm your identity.",
  "inPerson.dateOfBirth": "Date of Birth",
  "inPerson.endSession": "End Session",
  "inPerson.help": "Help",
  "inPerson.larGuardian": "Guardian / Representative",
  "inPerson.logBackIn":
    "If you registered an account, you can log in to view your documents.",
  "inPerson.loggedOut2": "Logged Out",
  "inPerson.loggedOutError":
    "We logged you out while you were inactive. Please select OK to verify your identity again.",
  "inPerson.loggedOutOfSiteVault": "We logged you out of SiteVault.",
  "inPerson.login": "Log In",
  "inPerson.noSurveys": "No Surveys",
  "inPerson.notYetSigned": "Not yet signed",
  "inPerson.ok": "OK",
  "inPerson.participant": "Participant",
  "inPerson.proxy": "Proxy",
  "inPerson.readyToBegin": "Ready to begin?",
  "inPerson.rescanQRCode":
    "Please ask your site staff to restart your session by scanning the QR code for in-person consent.",
  "inPerson.sameDeviceCanceledMessage":
    "Please return this device and discuss your decision with your site coordinator if you're unsure.",
  "inPerson.sameDeviceSessionEndedMessage":
    "Thanks for completing your surveys. Please return this device.",
  "inPerson.sessionEnded": "Session Ended",
  "inPerson.sessionEndedMessage": "Thanks for completing your surveys.",
  "inPerson.signed": "Signed",
  "inPerson.site": "Site",
  "inPerson.submit": "Submit",
  "inPerson.surveyCanceled": "In-Person Survey Canceled",
  "inPerson.surveyCanceledMessage":
    "Please discuss your decision with your site coordinator if you're unsure.",
  "inPerson.surveys": "Surveys",
  "inPerson.switchRoleConfirmation":
    "When {nextFirstName} {nextLastName} is ready to review, select Begin to get started.",
  "inPerson.translator": "Translator",
  "inPerson.verifyIdentityError":
    "The information you entered doesn't match what we have on file. Please try again.",
  "inPerson.websiteIssues": "Website issues",
  "inPerson.websiteIssuesMessage":
    "Sorry, we're having an issue with our website or servers. Please refresh the page and try again.",
  "inPerson.qrCodeExpired": "QR Code Expired",
  "inPerson.qrCodeExpiredMessage":
    "Please ask your site coordinator to restart your session.",
  "inPerson.witness": "Witness",
  "inPerson.reviewTerms": "Review Terms",
  "inPerson.reviewTermsDescription":
    "Please review our Terms of Service and Privacy Notice before continuing.",
  "inPerson.acceptTerms":
    "I accept the MyVeeva for Patients <termsLink>Terms of Service</termsLink> and <privacyLink>Privacy Notice</privacyLink>.",
  "inPerson.acceptTermsTitle":
    "I accept the MyVeeva for Patients Terms of Service and Privacy Notice.",
  "inPerson.iAccept": "I Accept",
  "landingScreen.imageAlt": "Person in a clinical trial",
  "login.notEnoughInfo": "Not Enough Login Information",
  "login.notEnoughInfoMessage":
    "You need to add another verification method to access your account. Get an activation code from your study team to add one.",
  "login.accountLocked":
    "Your account is locked from too many attempts. To access your account, select Need Help Logging In or contact your study team.",
  "login.username": "Username",
  "login.usernameTooltip":
    "Try your email address if you don't remember your username.",
  "login.usernameRequired": "Username is required",
  "login.forgotPassword": "Forgot Password?",
  "login.newUser": "New User?",
  "login.registerWithActivationCode": "Register With Activation Code",
  "login.invalidEmail": "Your email must include the at sign (@) and domain.",
  "login.login": "Log In",
  "login.password": "Password",
  "login.passwordRequired": "Password is required",
  "login.passwordEyeTitle": "Show / Hide Password",
  "login.recoverAccount": "Recover Account",
  "login.internalServerError":
    "Sorry, we're having an issue with our servers.<br></br>Please try again.",
  "login.recoverYourAccount": "recover your account",
  "login.incorrectUsernameOrPassword": "Incorrect username or password",
  "login.unableToLogIn": "Unable to Log In",
  "login.fallbackError":
    "We're sorry, logging in is unavailable right now. Please try again.",
  "login.helpLoggingIn": "Need Help Logging In?",
  "login.resetWithCode": "Reset with Activation Code",
  "login.resetWithCodeMessage":
    "If you have forgotten your username, or if you're having other login problems, contact your study team for an activation code.",
  "login.resetEnterCode": "Enter your activation code to reset your login.",
  "myVeeva.image.alt": "MyVeeva for Patients",
  "newPassword.confirmNewPassword": "Reenter New Password",
  "newPassword.setNewPassword": "Set New Password",
  "newPassword.invalidCode":
    "The link to reset your password expired. You can {requestAnotherEmail} with a new link.",
  "newPassword.linkExpired": "Link Expired",
  "newPassword.linkExpiredMessage":
    "Do you want us to send you a new link to reset your password?",
  "newPassword.returnToLogin": "Return to Login",
  "newPassword.openLinkError": "Unable to Open Link",
  "newPassword.openLinkErrorMessage":
    "Sorry, we were unable to open that link. Please reselect the link we sent you, or contact your study site for help.",
  "newPassword.login": "log in",
  "newPassword.newPassword": "New Password",
  "newPassword.passwordUpdated": "Password Updated",
  "newPassword.requestAnotherEmail": "request another email",
  "newPassword.somethingWentWrong": "Something went wrong",
  "newPassword.submit": "Change Password and Continue",
  "newPassword.success":
    "You successfully updated your password and you're ready to {login}.",
  "newPassword.title": "Change Password",
  "newPassword.notEnoughInfo": "Not Enough Information",
  "newPassword.notEnoughInfoMessage":
    "You need a phone number on your account to reset your password by yourself. Get an activation code from your study team to reset your password and update your account.",
  "notifications.clearAll": "Clear All",
  "notifications.clearNotification": "Clear Notification",
  "notifications.confirmClearAll": "Confirm Clear All",
  "notifications.couldNotClear": "Notifications Couldn't Be Cleared",
  "notifications.couldNotClearRefresh":
    "Sorry, we weren't able to clear the notifications. Please refresh the page to try again.",
  "notifications.new": "New",
  "notifications.noNotifications": "No Notifications",
  "notifications.notifications": "Notifications",
  "notifications.pleaseLoginToContinue": "Please log in to continue.",
  "notifications.readLess": "Read Less",
  "notifications.readMore": "Read More",
  "notifications.today": "Today",
  "notifications.upToDate": "You're all up to date",
  "notifications.yesterday": "Yesterday",
  "register.activationCode": "Activation Code",
  "register.addEmail": "Add Email",
  "register.addEmailDescription":
    "We'll send you an email to verify this address.",
  "register.addPhone": "Add Phone",
  "register.addPhoneDescription":
    "We'll send you a code to verify this number. You can get the code by text message or phone call.",
  "register.addVerificationMethod": "Add Verification Method",
  "register.addVerificationMethodDescription":
    "Add an email or a phone number to your account. This information is required to verify your identity the next time you log in.",
  "register.codeOnlyMobileApp": "Code Only for Mobile App",
  "register.codeOnlyMobileAppError":
    "You can only use this code in the MyVeeva for Patients mobile application. Download the mobile app and enter the activation code on the login page to register.",
  "register.confirmIdentityHeadingCall": "Confirm Identity – Voice Call",
  "register.confirmIdentityHeadingEmail": "Confirm Identity – Email",
  "register.confirmIdentityHeadingText": "Confirm Identity – Text",
  "register.confirmIdentityHeadingDescriptionCall":
    "We're calling <span>{phone}</span> to give you a six-digit verification code. Enter it below.",
  "register.confirmIdentityHeadingDescriptionEmail":
    "We sent an email to <span>{email}</span> with a link that will allow you to log in.",
  "register.confirmIdentityHeadingDescriptionText":
    "We sent a text with a six-digit verification code to <span>{phone}</span>. Enter it below.",
  "register.didntReceiveCode": "Didn't receive the code?",
  "register.didntReceiveEmail": "Didn't receive the email?",
  "register.email": "Email",
  "register.emailInvalid": "Email must include the at sign (@) and domain.",
  "register.emailRequired": "Email is required",
  "register.emailSubmitError":
    "Invalid email. Check the email address and try again.",
  "register.enterActivationCodeTitle": "Enter Activation Code",
  "register.enterActivationCodeDescription":
    "Enter the activation code you received from your study team. Contact your study team if you need an activation code.",
  "register.getAText": "Get a Text",
  "register.getAnotherCall": "Get Another Call",
  "register.invalidActivationCode": "Invalid activation code",
  "register.invalidActivationCodeTitle": "Invalid Activation Code",
  "register.invalidActivationCodeDescription":
    "If you're having trouble with the code, contact your study team for help.",
  "register.invalidVerificationCode": "Invalid Verification Code",
  "register.next": "Next",
  "register.password": "Password",
  "register.passwordMustContainLowerCase": "Must have a lowercase letter",
  "register.passwordMustContainNumber": "Must have a number",
  "register.passwordMustContainOneSpecialCharacter":
    "Must have a special character",
  "register.passwordMustContainUpperCase": "Must have an uppercase letter",
  "register.passwordLengthInvalid": "Must be 8-25 characters long",
  "register.passwordsDoNotMatch": "Passwords must match",
  "register.phoneNumber": "Phone Number",
  "register.receiveACall": "Receive a Call",
  "register.reenterPassword": "Reenter Password",
  "register.sendAnotherCode": "Send Another Code",
  "register.sendAnotherEmail": "Send Another Email",
  "register.sendEmail": "Send Email",
  "register.sent": "Sent ({time}s)",
  "register.setPassword": "Set Password",
  "register.setUsername": "Set Username",
  "register.acceptTermsTitle":
    "I accept the MyVeeva for Patients Terms of Service and Privacy Notice.",
  "register.acceptTerms":
    "I accept the MyVeeva for Patients <termsLink>Terms of Service</termsLink> and <privacyLink>Privacy Notice</privacyLink>.",
  "register.setUsernameDescription":
    "Usernames can contain letters, numbers, and the following special characters: . @ + - _",
  "register.somethingWentWrong": "Something went wrong",
  "register.somethingWentWrongTitle": "Something Went Wrong",
  "register.somethingWentWrongDescription": "Please try again.",
  "register.tooManyRequests": "Too Many Requests",
  "register.tooManyRequestsMessage":
    "We can't send another code to this number right now. Check the number and try again in 10 minutes.",
  "register.tryAgainDescriptionCall":
    "Make sure your device has a signal and request another code.",
  "register.tryAgainDescriptionText":
    "Make sure your device has a network connection. If you still don't receive the code, request another one.",
  "register.tryAgainDescriptionEmail":
    "Check your spam folder for an email from MyVeeva. If you didn't receive it, send another email.",
  "register.unableToSendCode": "Unable to Send Code",
  "register.unableToSendCodeMessage":
    "Sorry, we couldn't send your verification code. Check the number and try again.",
  "register.unableToSendText": "Unable to Send Text",
  "register.unableToSendTextMessage":
    "This number can't receive texts. Request a call to receive the code at this number.",
  "register.username": "Username",
  "register.usernameAlreadyTaken":
    "Username is already in use. Try a different username, or log in if you already have an account.",
  "register.usernameCantBeginWithSpecialCharacter":
    "Username can't begin with a special character.",
  "register.usernameHasWhitespace":
    "Username can't contain spaces. Remove the spaces and try again.",
  "register.usernameInvalid": "Invalid username",
  "register.usernameRequired": "Username is required",
  "register.enterDob": "Enter Date of Birth",
  "register.dobMessage": "To confirm your identity, enter your date of birth.",
  "register.dobDay": "Day",
  "register.dobMonth": "Month",
  "register.dobYear": "Year",
  "register.dobError":
    "Date doesn't match what's on file for this study. Make sure the date you entered is correct and try again.",
  "register.dobInvalid": "Invalid date",
  "register.dobMaxAttemptsTitle": "Maximum Attempts",
  "register.dobMaxAttemptsMessage":
    "Your study team may have entered your date of birth incorrectly. Contact them to get a new activation code and try again.",
  "register.dobDayPlaceholder": "DD",
  "register.dobMonthPlaceholder": "MM",
  "register.dobYearPlaceholder": "YYYY",
  "registerEmail.email": "Email",
  "smartBanner.continueInBrowser": "Continue in Browser",
  "smartBanner.downloadTheMyVeevaApp": "Download the MyVeeva app",
  "smartBanner.getTheApp": "Get the App",
  "tasks.today": "today",
  "tasks.tomorrow": "tomorrow",
  "tasks.availableUntilDate": "Available until {date}",
  "tasks.availableUntilTodayAtTime": "Available until {today} at {time}",
  "tasks.availableUntilTomorrowAtTime": "Available until {tomorrow} at {time}",
  "tasks.due": "Due by {date}",
  "tasks.dueToday": "Due today by {date}",
  "tasks.dueTomorrow": "Due tomorrow by {date}",
  "tasks.new": "New",
  "tasks.newVersion": "New Version",
  "tasks.noTasks": "No Tasks",
  "tasks.preview": "Preview",
  "tasks.resume": "Resume",
  "tasks.review": "Review",
  "tasks.start": "Start",
  "textField.editLabel": "Edit {label}",
  "title.documentTitle": "MyVeeva for Patients",
  "title.documentTitleVeevaEconsent": "Veeva eConsent",
  "viewerBackButton.backButtonTooltip": "Back to list",
  "visits.back": "Back",
  "visits.backToVisits": "Back to visits",
  "visits.dateAndTime": "{date} · {time}",
  "visits.fallbackTitle": "(No title)",
  "visits.joinMeeting": "Join Meeting",
  "visits.otherHeader": "Visit",
  "visits.virtualHeader": "Virtual Visit",
  "visits.endTimePassed": "This visit's end time has passed.",
  "patientViewerError.loggedOut": "Logged out",
  "patientViewerError.loggedOutWhileInactive":
    "We logged you out while you were inactive. Please select OK to log in again.",
  "patientViewerError.surveyStartErrorTitle": "Survey Couldn't Be Started",
  "patientViewerError.surveyStartErrorMessage":
    "Another survey must be completed before starting this survey. To start the other survey, select <b>Start.</b>",
  "patientViewerError.cancel": "Cancel",
  "patientViewerError.start": "Start",
  "patientViewerError.websiteIssues": "Website issues",
  "patientViewerError.websiteIssuesMessage":
    "Sorry, we're having an issue with our website or servers. Please refresh the page and try again.",
}
