const tryParseLocale = (locale: string) => {
  try {
    return new Intl.Locale(locale)
  } catch {
    return null
  }
}

/**
 * These bcp47 regions should default to the traditional chinese font face.
 *
 * HK = Hong Kong
 * TW = Taiwan
 */
const traditionalChineseCharRegions = ["HK", "TW"]

export const getFontFace = (localeTag: string) => {
  const locale = tryParseLocale(localeTag)
  switch (locale?.language) {
    case "zh":
      switch (locale.script) {
        case "Hant":
          // traditional han script
          return "tc"
        case "Hans":
          // simplified han script
          return "sc"
      }
      if (
        locale.region &&
        traditionalChineseCharRegions.includes(locale.region)
      ) {
        return "tc"
      }
      return "sc"
    case "ja":
      return "jp"
    case "ko":
      return "kr"
    case "th":
      return "th"
    case "ar":
      return "ar"
    case "he":
      return "he"
    default:
      return undefined
  }
}
