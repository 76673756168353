import { GraphQLClient } from "graphql-request"
import {
  graphqlProcessRequestError,
  graphqlAddRequestHeaders,
  graphqlProcessResponseHeaders,
} from "@myvp/shared/src/functions/graphql-client-middleware"

/**
 * NOTE: when updating this client's configuration, consider updating http-request.ts in sites+sponsors+patients+epro-viewer
 */
export const graphqlClient = new GraphQLClient("/api", {
  requestMiddleware: async (request) => {
    let req = request
    req = graphqlProcessRequestError(req)
    req = graphqlAddRequestHeaders(req)
    return req
  },
  responseMiddleware: (response) => {
    graphqlProcessResponseHeaders(response)
  },
})
