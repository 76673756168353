import React from "react"
import RtlSvg from "@myvp/shared/src/icons/rtl-svg"
import {
  IconProps,
  iconColorCss,
  iconSizeCss,
} from "@myvp/shared/src/styles/icon-utils"

const Popout = (props: Omit<IconProps, "size">) => (
  <RtlSvg
    color={props.color}
    css={[iconColorCss(props.color), iconSizeCss("medium")]}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11 5H7C5.89543 5 5 5.89543 5 7V17C5 18.1046 5.89543 19 7 19H17C18.1046 19 19 18.1046 19 17V13"
      stroke="var(--color)"
      strokeWidth="2"
    />
    <path d="M13 5H19V11" stroke="var(--color)" strokeWidth="2" />
    <path d="M13 11L18 6" stroke="var(--color)" strokeWidth="2" />
  </RtlSvg>
)

export default Popout
