// https://docs.google.com/spreadsheets/d/1EMrktWBEphmAjaTxuauVbmGNwJep9CALuEUnfxUFsT4/edit#gid=1592713295
export const theme = {
  isRTL: false as boolean,
  palette: {
    surface: {
      transparent: "#FFFFFF00",
      default: "#FFFFFF",
      alt: "#F7F9FC",
      hovered: "#F7F9FC",
      pressed: "#D9DCE1",
      disabled: "#F1F4F9",
      tooltip: "#272A30",
      selected: {
        default: "#F1F4F9",
        hovered: "#D9DCE1",
        pressed: "#C1C4C9",
      },
      brand: {
        alt: {
          default: "#689AFC",
          hovered: "#8EB5FF",
          pressed: "#427EF2",
          disabled: "#CCDDFF",
          selected: {
            default: "#E7EEFF",
            hovered: "#CCDDFF",
            pressed: "#A9C7FF",
          },
        },
        default: "#F7981D",
        hovered: "#F9AD4C",
        pressed: "#ED8600",
        disabled: "#FDD7A9",
        selected: {
          default: "#FFECD8",
          hovered: "#FDD7A9",
          pressed: "#FBC27B",
        },
      },
      success: {
        default: "#CDF8D1",
        alt: "#AEEBC0",
        hovered: "#82D7A4",
        pressed: "#57C487",
      },
      warning: {
        default: "#FFF9DC",
        alt: "#FFF4B9",
        hovered: "#FFE387",
        pressed: "#F5D158",
      },
      critical: {
        default: "#FFE1E2",
        alt: "#FFCBCB",
        hovered: "#FFB3B3",
        pressed: "#FF9393",
      },
    },
    foreground: {
      inverse: "#FFFFFF",
      default: "#272A30",
      alt: "#6B6E73",
      link: "#3455DB",
      disabled: "#D9DCE1",
      disabledAlt: "#A9ACB1",
      success: "#04994E",
      warning: "#C38D00",
      critical: "#CF000F",
      highlight: "#F7981D",
      dark: {
        inverse: "#000",
        default: "#fff",
        alt: "#C1C4C9",
        link: "#8EB5FF",
        disabled: "#575A60",
        disabledAlt: "#A9ACB1",
        success: "#57C487",
        warning: "#EBC429",
        critical: "#FF7171",
        highlight: "#F7981D",
      },
    },
    background: {
      default: "#F1F4F9",
      alt: "#FFFFFF",
    },
    border: {
      default: "#D9DCE1",
      rule: "#F1F4F9",
      ruleAlt: "#EAECF2",
      focus: "#3455DB",
      dark: {
        default: "#575A60",
        rule: "#3B3E43",
        focus: "#689AFC",
      },
      highlight: {
        default: "#F7981D",
        alt: "#F9AD4C",
      },
      success: {
        default: "#2BB06B",
        alt: "#57C487",
      },
      warning: {
        default: "#E4AF0A",
        alt: "#EBC429",
      },
      critical: {
        default: "#CF000F",
        alt: "#F83E3E",
      },
    },
    decorative: {
      foreground: {
        default: {
          blue: "#17459B",
          green: "#00461D",
          red: "#940000",
          yellow: "#674A00",
          orange: "#7E3C00",
        },
        alt: {
          blue: "#3455DB",
          red: "#CF000F",
          orange: "#AB5200",
          yellow: "#85620B",
          green: "#096A30",
          teal: "#007476",
          purple: "#8A2BE2",
        },
      },
      surface: {
        blue: "#EFF4FF",
        green: "#DFFAE1",
        orange: "#FFF2E6",
        purple: "#FAF1FF",
        red: "#FFECEC",
        teal: "#DBF9F7",
        yellow: "#FFF9DC",
        alt: {
          blue: "#E7EEFF",
          yellow: "#FFF4B9",
          red: "#FFE1E2",
        },
      },
    },
    core: {
      purple: {
        500: "#CB94FB",
        700: "#A455EC",
      },
      blue: {
        500: "#8EB5FF",
        700: "#427EF2",
      },
      red: {
        700: "#F83E3E",
      },
    },
    oneOff: {
      studio: "#223354",
    },

    /** @deprecated */
    primary: {
      one: "#FF7A00",
      oneGradient: `linear-gradient(315deg, #FF7A00 0%, #FFA800 100%)`,
      oneGradientHover: `linear-gradient(315deg, #DF6B00 0%, #E79800 100%)`,
      oneOpacity: (a: number) => `rgba(255, 122, 0, ${a})`,
      two: "#FFA800",
      twoOpacity: (a: number) => `rgba(255, 168, 0, ${a})`,
      three: "#FFF2E6",
      backgroundLight: "#FFBD80",
      disabled: "#FFE8D2",
      hover: "#FFA24D",
      border: "#EF8523",
    },
    /** @deprecated */
    secondary: {
      one: "#2D9CDB",
      oneGradient: `linear-gradient(135deg, #2D9CDB 0%, #2F80ED 100%)`,
      oneOpacity: (a: number) => `rgba(45, 156, 219, ${a})`,
      two: "#2F80ED",
    },
    /** @deprecated */
    warning: {
      one: "#FFF2CF",
      oneGradient: "linear-gradient(0deg, #FFFAEC, #FFFAEC)",
      two: "#D9BE9E",
    },
    /** @deprecated */
    red: {
      light: {
        zero: "#e23233",
        one: "#b7212e",
        two: "#FF5354",
      },
      dark: {
        zero: "#BD0001",
        one: "#DB2F30",
      },
    },
    /** @deprecated */
    success: {
      one: "#6FCF97",
      oneOpacity: (a: number) => `rgba(111, 207, 151, ${a})`,
      two: "#27AE60",
      twoOpacity: (a: number) => `rgba(39, 174, 96, ${a})`,
    },
    /** @deprecated */
    steel: {
      zero: "#ffffff",
      zeroOpacity: (a: number) => `rgba(255, 255, 255, ${a})`,
      one: "#f1f4f9",
      oneOpacity: (a: number) => `rgba(241, 244, 249, ${a})`,
      two: "#d9dce1",
      three: "#c1c4c9",
      four: "#a9acb1",
      fourOpacity: (a: number) => `rgba(169, 172, 177, ${a})`,
      five: "#6b6e73",
      six: "#575a60",
      seven: "#3b3e43",
      eight: "#272a30",
      nine: "#16181d",
      ten: "#000000",
    },
    /** @deprecated */
    yellowGradient: "linear-gradient(315deg, #F0BB00 0%, #FFEA00 100%);",
    /** @deprecated */
    yellow: "#FFEA00",
    /** @deprecated */
    purple: "#7A2DDB",
    /** @deprecated */
    purpleGradient: "linear-gradient(315deg, #481EF5 0%, #7B61FF 100%);",
    /** @deprecated */
    teal: "#00B6B9",
    /** @deprecated */
    tealGradient: "linear-gradient(315deg, #009288 0%, #29CDA5 100%);",
    /** @deprecated */
    navy: "#223354",
    /** @deprecated */
    snow: "#F9F9F9",
    /** @deprecated */
    hover: "#F7F9FC",
  },
  addFocus: `:focus {
    box-shadow: 0 0 4pt 2pt rgba(242, 153, 74, 0.6);
    border-radius: 8px;
    z-index: 1;
  }`,
  focusShadow: "0 0 4pt 2pt rgba(242, 153, 74, 0.6)",
  boxShadow: "0px 8px 8px rgba(0, 0, 0, 0.4)",
  /** @deprecated */
  border: `1px solid #bdbdbd`,
  /** @deprecated */
  borderRadius: "4px",
  font: {
    family: "Inter, sans-serif",
    primary: {
      size: 18,
    },
    secondary: {
      size: 14,
    },
  },
} as const

export type Theme = typeof theme
