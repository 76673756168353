const root = "/study"

const studyRouteNames = {
  root,
  resources: `${root}/resources`,
  consentForms: `${root}/consent-forms`,
  details: `${root}/details`,
}

export default studyRouteNames
