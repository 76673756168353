import { graphqlClient } from "src/graphql-client"
import { graphql } from "src/gql"
import { MfaType } from "src/types"
import {
  CreateOrGetSelfRegistrationUserMutation,
  SendMfaMutation,
  VerifyMfaMutation,
} from "src/gql/graphql"
import {
  consumeMockRestResponse,
  getMockData,
  handleGraphQLErrors,
  isMock,
} from "@myvp/shared/src/functions/mock"
import { HttpCode } from "@myvp/shared/src/types"
import { SUPPORTED_LOCALES } from "src/i18n/locales"
import * as authServices from "src/services/auth.service"
import { isStringEnumValue } from "@myvp/shared/src/functions/type-guards"

export const sendMfa = async (args: {
  mfaType: MfaType
  relatedUserId: string
  notificationId: string
}): Promise<SendMfaMutation["sendMfa"]> => {
  if (!isMock()) {
    const mutation = graphql(/* GraphQL */ `
      mutation sendMfa($input: SendMfaInput!) {
        sendMfa(input: $input) {
          status
        }
      }
    `)
    const result = await graphqlClient.request(mutation, {
      input: {
        mfaType: args.mfaType,
        relatedUserId: args.relatedUserId,
        notificationId: args.notificationId,
      },
    })
    return result.sendMfa
  } else {
    const { sendMfa = { status: HttpCode.Ok } } = await getMockData()
    handleGraphQLErrors(sendMfa)
    return sendMfa
  }
}

export const verifyMfa = async (args: {
  code: string
  mfaType: MfaType
  relatedUserId: string
  notificationId: string
}): Promise<VerifyMfaMutation["verifyMfa"]> => {
  if (!isMock()) {
    const mutation = graphql(/* GraphQL */ `
      mutation verifyMfa($input: VerifyMfaInput!) {
        verifyMfa(input: $input) {
          relatedUserRoleId
        }
      }
    `)
    const result = await graphqlClient.request(mutation, {
      input: {
        code: args.code,
        mfaType: args.mfaType,
        relatedUserId: args.relatedUserId,
        notificationId: args.notificationId,
      },
    })
    return result.verifyMfa
  } else {
    const inputCode = args.code.slice(0, 3)
    let errorCode = null
    if (
      inputCode === args.code.slice(3) &&
      isStringEnumValue(HttpCode)(inputCode)
    ) {
      errorCode = inputCode
    }
    const errorResponse = errorCode
      ? {
          response: {
            errors: [{ extensions: { errorCode } }],
          },
        }
      : null
    if (errorResponse) {
      handleGraphQLErrors(errorResponse)
      return { relatedUserRoleId: args.relatedUserId }
    } else {
      const { verifyMfa = { relatedUserRoleId: "test-related-user-role-id" } } =
        await getMockData()
      handleGraphQLErrors(verifyMfa)
      return verifyMfa
    }
  }
}

export const createOrGetSelfRegistrationUser = async (args: {
  client: string
  relatedUserId: string
  relatedUserRoleId: string
}): Promise<
  CreateOrGetSelfRegistrationUserMutation["createOrGetSelfRegistrationUser"]
> => {
  if (!isMock()) {
    const mutation = graphql(/* GraphQL */ `
      mutation createOrGetSelfRegistrationUser(
        $input: CreateOrGetSelfRegistrationUserInput!
      ) {
        createOrGetSelfRegistrationUser(input: $input) {
          username
          locale
          authId
        }
      }
    `)
    const result = await graphqlClient.request(mutation, {
      input: {
        client: args.client,
        relatedUserId: args.relatedUserId,
        relatedUserRoleId: args.relatedUserRoleId,
      },
    })
    return result.createOrGetSelfRegistrationUser
  } else {
    const {
      verifyMfa = {
        createOrGetSelfRegistrationUser: {
          username: "testuser123",
          locale: SUPPORTED_LOCALES.enUS,
          authId: "test-auth-id",
        },
      },
    } = await getMockData()
    handleGraphQLErrors(verifyMfa)
    return verifyMfa
  }
}

export const authorizeSelfRegistration = async (args: {
  authId: string
  type: string
  client: string
  relatedUserId: string
}) => {
  if (!isMock()) {
    return authServices.authRequest({
      url: "/authorize-self-registration",
      data: args,
      headerContentType: "application/json",
    })
  } else {
    const {
      authorizeSelfRegistration = {
        status: HttpCode.Ok,
      },
    } = await getMockData()
    consumeMockRestResponse(authorizeSelfRegistration)
    return authorizeSelfRegistration
  }
}

export const updateSelfRegisteredUser = async (authId: string) => {
  if (!isMock()) {
    return authServices.authRequest({
      url: "/update-self-registered-user",
      data: { authId },
      headerContentType: "application/json",
    })
  } else {
    const {
      updateSelfRegisteredUser = {
        status: HttpCode.Ok,
      },
    } = await getMockData()
    consumeMockRestResponse(updateSelfRegisteredUser)
    return updateSelfRegisteredUser
  }
}
