import React from "react"
import AlertModal from "@myvp/shared/src/components/alert-modal"
import { HttpCode } from "@myvp/shared/src/types"
import { useIntl } from "react-intl"
import { useLocation, useNavigate } from "react-router-dom"
import { routeNames } from "src/router/route-names"
import { useErrorBoundary } from "@myvp/shared/src/hooks/use-error-boundary"

const ForgotPasswordErrorBoundary = () => {
  const { formatMessage } = useIntl()
  const navigate = useNavigate()
  const location = useLocation()

  const errorConfigs = {
    [HttpCode.Unauthorized]: {
      title: formatMessage({ id: "error.sessionExpired" }),
      message: formatMessage({ id: "error.sessionExpiredMessage" }),
      onCloseText: formatMessage({ id: "alertModal.ok" }),
      onClose: () =>
        navigate({
          pathname: routeNames.login,
          search: location.search,
        }),
    },
    fallback: {
      title: formatMessage({ id: "register.somethingWentWrongTitle" }),
      message: formatMessage({ id: "register.somethingWentWrongDescription" }),
      onCloseText: formatMessage({ id: "alertModal.ok" }),
      onClose: () => window.location.reload(),
    },
  }

  const errorConfig = useErrorBoundary(errorConfigs)
  return <AlertModal {...errorConfig} />
}

export default ForgotPasswordErrorBoundary
