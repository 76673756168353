import React from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/react"

const Svg = styled.svg`
  ${(props) =>
    props.theme.isRTL &&
    css`
      scale: -1 1;
    `}
`

const RtlSvg = (props: React.ComponentProps<"svg">) => {
  return <Svg {...props} />
}

export default styled(RtlSvg)``
