/// <reference types="@myvp/shared/boomr.d.ts" />
import { getDeviceProperties } from "@myvp/shared/src/functions/get-device-properties"

if (process.env.REACT_APP_BOOMERANG === "true") {
  // NOTE: use a relative import instead of "@myvp/shared/..." to avoid tsc error from patients or other apps.
  import("./boomerang-client").then(() => {
    BOOMR.init({
      // Instead of using the native navigator.sendBeacon functionality,
      // always send posts so that we can add proper headers
      beacon_disable_sendbeacon: true,
      beacon_type: "POST",
      beacon_url: "/r/beacon",
      log: null,
    })
    BOOMR.subscribe("before_beacon", function () {
      BOOMR.addVar("scr.w", String(window.innerWidth))
      BOOMR.addVar("scr.h", String(window.innerHeight))
      const properties = {
        ...getDeviceProperties(),
        ...(performance && performance.memory
          ? {
              used_memory: performance.memory.usedJSHeapSize,
              available_memory:
                performance.memory.totalJSHeapSize -
                performance.memory.usedJSHeapSize,
              total_memory: performance.memory.totalJSHeapSize,
            }
          : {}),
      }
      BOOMR.addVar("device-properties", properties)
    })
  })
}

export {}
