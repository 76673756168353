import React from "react"
import styled from "@emotion/styled"
import { css, useTheme } from "@emotion/react"

const Svg = styled.svg<{ size: RadioSize }>`
  min-width: 32px;
  width: 32px;
  min-height: 32px;
  height: 32px;
  ${(props) =>
    props.size === "small" &&
    css`
      min-width: 24px;
      width: 24px;
      min-height: 24px;
      height: 24px;
    `}
`

export type RadioSize = "small" | "default"

const Radio = (props: {
  className?: string
  selected: boolean
  disabled?: boolean
  size?: RadioSize
}) => {
  const size = props.size ?? "default"
  const theme = useTheme()
  const configs = {
    small: {
      cy: "12",
      cx: "12",
      outerR: "8.5",
      innerR: "4",
      viewBox: "0 0 24 24",
    },
    default: {
      cy: "16",
      cx: "16",
      outerR: "10",
      innerR: "5",
      viewBox: "0 0 32 32",
    },
  }
  return (
    <Svg
      size={size}
      focusable="false"
      className={props.className}
      viewBox={configs[size].viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx={configs[size].cx}
        cy={configs[size].cy}
        r={configs[size].outerR}
        fill={theme.palette.surface.default}
        stroke={
          props.disabled
            ? theme.palette.border.default
            : theme.palette.foreground.disabledAlt
        }
        strokeWidth="2"
      />
      {!!props.selected && (
        <circle
          cx={configs[size].cx}
          cy={configs[size].cy}
          r={configs[size].innerR}
          fill={
            props.disabled
              ? theme.palette.surface.brand.disabled
              : theme.palette.surface.brand.default
          }
        />
      )}
    </Svg>
  )
}

export default styled(Radio)``
