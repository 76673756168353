import React from "react"
import { GraphQLError } from "graphql"
import { useRouteError } from "react-router-dom"
import {
  getErrorCode,
  getErrorKey,
} from "@myvp/shared/src/functions/get-error-code"

export class GlobalAppError extends Error {
  errorCause
  constructor(error: unknown) {
    super("GlobalAppError")
    this.name = "GlobalAppError"
    this.errorCause = error
  }
}

export interface ErrorObject {
  title?: React.ReactNode
  message?: React.ReactNode
  displayIcon?: boolean
  onClose?: (...args: unknown[]) => unknown
  onCloseText?: string
  onConfirm?: (...args: unknown[]) => unknown
  onConfirmText?: string
  visible?: boolean
  disabled?: boolean
  redirect?: string | { pathname: string; search: string }
  variant?: "error" | "warning"
  render?: () => React.ReactNode
}
type Error<Metadata = object> =
  | ((args: { metadata?: Metadata }) => ErrorObject)
  | ErrorObject
type Errors<Metadata = object> = {
  [errorCode: string]: Error<Metadata>
} & { graphQLErrors?: GraphQLError[] }

export const useErrorBoundary = (
  errorConfigs: Errors,
  args: { readKey?: boolean } = {}
) => {
  let error = useRouteError()
  error = error instanceof GlobalAppError ? error.errorCause : error
  // eslint-disable-next-line no-console
  console.log(error)
  const errorCode =
    // Backend may supply a custom error in form of an error key, so try the error key first
    (args?.readKey ? (getErrorKey(error as any) as string) : null) ??
    (getErrorCode(error as any) as string) ??
    "fallback"
  let errorConfig = errorConfigs[errorCode] ?? errorConfigs["fallback"]
  if (typeof errorConfig === "function") {
    // @ts-expect-error
    let metadata = error?.graphQLErrors?.[0]?.extensions
    if (!metadata) {
      try {
        metadata = JSON.parse(JSON.stringify(error))?.response?.errors?.[0]
          ?.extensions
      } catch {}
    }
    errorConfig = errorConfig({ metadata })
  }
  return errorConfig
}
