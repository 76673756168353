import { VAULT_CLINICAL_URL } from "@myvp/shared/src/constants"
import { createRoutePath } from "@myvp/shared/src/functions/create-route-path"

export const externalRouteNames = {
  econsentEditorHelp: `${VAULT_CLINICAL_URL}/12953/`,
  myVeevaHelp: "https://patients.myveeva.com/help/",
} as const

/** @deprecated */
const DEPRECATED_econsentStudies = (taskId = `:taskId`) => {
  const route = `/econsent/view/studies`
  return taskId ? `${route}/${taskId}` : route
}
/** @deprecated */
const DEPRECATED_econsentLibrary = "/econsent/view/library"

export const routeNames = {
  version: "/version",
  error: "/error",
  login: "/login",
  confirmIdentity: "/login/confirm",
  confirmIdentityEmail: "/login/confirm/email",
  confirmIdentityPhone: "/login/confirm/phone",
  identityConfirmed: "/confirm-email-mfa",
  registerActivate: "/register/activate",
  resetAccount: "/register/reset",
  registerActivateRedirect: "/register/activate/redirect",
  registerConfirmDob: "/register/confirm-date-of-birth",
  registerSetUsername: "/register/set-username",
  registerSetPassword: "/register/set-password",
  registerSetVerificationMethod: "/register/set-verification-method",
  registerSetVerificationMethodEmail: "/register/set-verification-method/email",
  registerSetVerificationMethodPhone: "/register/set-verification-method/phone",
  registerConfirmEmail: "/register/confirm/email",
  registerConfirmPhone: "/register/confirm/phone",
  registerLegacyConfirmIdentity: "/register-email/confirm",
  registerLegacyConfirmPhone: "/register-email/confirm/phone",
  registerLegacySetPassword: "/register-email/set-password",
  registerLegacySetUsername: "/register-email/set-username",
  enterMfa: "/welcome",
  resetSvUserPassword: "/user/reset",
  forgotPassword: "/forgot-password",
  forgotPasswordEmailSent: "/forgot-password/email",
  newPassword: "/new-password",
  newPasswordMfa: "/new-password/mfa",
  setNewPassword: "/new-password/set",
  econsentEditor: "/econsent/editor",
  determineUser: "/determine-user",
  confirmNewNumber: "/confirm-new-number",
  confirmNewNumberMfa: "/confirm-new-number/mfa",
  confirmNewEmail: "/confirm-new-email",
  confirmNewEmailExpired: "/confirm-new-email/expired",
  econsentPreviewDocument: (documentId = `:documentId`) =>
    `/econsent/preview/${documentId}`,
  econsentPreviewDocumentFromEditor: (documentId = `:documentId`) =>
    `/econsent/editor/preview/${documentId}`,
  /** @deprecated */
  econsentViewDocumentFromTasks: (documentId = `:documentId`) =>
    `/tasks/econsent/view/${documentId}`,
  econsentViewDocumentFromStudy: (documentId = `:documentId`) =>
    `/study/consent-forms/view/${documentId}`,
  econsentViewDocumentFromTodo: (documentId = `:documentId`) =>
    `/todo/econsent/view/${documentId}`,
  /** @deprecated */
  econsentViewDocumentFromDocuments: (documentId = `:documentId`) =>
    `/documents/econsent/view/${documentId}`,
  econsentPreviewGenerator: `/econsent/preview/create/:documentId`,
  econsentGenerate: "/econsent/generate",
  termsOfService: "/terms",
  privacyNotice: "/privacy",
  help: "/help",
  inPerson: "/inperson",
  inPersonConfirm: "/inperson/confirm",
  inPersonEconsentViewer: (documentId = `:documentId`) =>
    `/inperson/econsent/view/${documentId}`,
  inPersonRoles: "/inperson/roles",
  inPersonSuccess: "/inperson/success",
  entry: "/",
  /** @deprecated */
  tasks: "/tasks",
  /** @deprecated */
  documents: "/documents",
  /** @deprecated */
  visits: "/visits",
  /** @deprecated */
  studies: "/studies",
  account: "/account",
  activateNewStudy: "/account/activate",
  activateNewStudyConfirmDob: "/account/activate/confirm-date-of-birth",
  settings: "/settings",
  todo: "/todo",
  patientsRedirect: "/patients-redirect",
  /** @deprecated */
  viewVisitFromVisits: (visitId = `:visitId`) => `/visits/view/${visitId}`,
  viewVisitFromTodos: (visitId = `:visitId`) => `/todo/visit/view/${visitId}`,
  eproScreenshot: (collectionId = `:collectionId`, surveyId = `:surveyId`) =>
    `/epro/${collectionId}/screenshot/${surveyId}`,
  /** @deprecated */
  eproViewSurveyFromTasks: (surveyId = `:surveyId`) =>
    `/tasks/epro/view/${surveyId}`,
  eproViewSurveyFromTodo: (surveyId = `:surveyId`) =>
    `/todo/epro/view/${surveyId}`,
  studyDetailsScreenshot: (collectionId = `:collectionId`) =>
    `/epro/${collectionId}/screenshot/study-details`,
  studyTabScreenshot: (collectionId = `:collectionId`) =>
    `/epro/${collectionId}/screenshot/study`,
  studyDescriptionScreenshot: (collectionId = `:collectionId`) =>
    `/epro/${collectionId}/screenshot/study-description`,
  resourcesScreenshot: createRoutePath(
    `/epro/:sponsorStudyId/screenshot/resources`
  ),
  redirectToAppstore: "/redirect-to-appstore",
  /** @deprecated */
  DEPRECATED_econsentViewDocument: (
    documentId = `:documentId`,
    taskId: string | undefined = undefined
  ) => `${DEPRECATED_econsentStudies(taskId)}/documents/${documentId}`,
  DEPRECATED_econsentStudies,
  DEPRECATED_econsentLibrary,
  /** @deprecated */
  DEPRECATED_econsentViewDocumentFromLibrary: (documentId = `:documentId`) =>
    `${DEPRECATED_econsentLibrary}/documents/${documentId}`,
} as const

export const globalRouteNames = {
  root: "/",
  privacyNotice: routeNames.privacyNotice,
  termsOfService: routeNames.termsOfService,
  help: routeNames.help,
} as const
