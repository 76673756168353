import React from "react"
import AlertModal from "@myvp/shared/src/components/alert-modal"
import { useIntl } from "react-intl"

const SomethingWentWrongModal = (props: {
  onClose: () => void
  visible: boolean
}) => {
  const { formatMessage } = useIntl()

  if (!props.visible) {
    return null
  }

  return (
    <AlertModal
      title={formatMessage({
        id: "register.somethingWentWrongTitle",
      })}
      message={formatMessage({
        id: "register.somethingWentWrongDescription",
      })}
      onCloseText={formatMessage({ id: "alertModal.ok" })}
      onClose={props.onClose}
    />
  )
}

export default SomethingWentWrongModal
