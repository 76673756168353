import * as React from "react"
/**
 * Pulled from
 *    - https://github.com/floating-ui/floating-ui/blob/master/packages/react/src/hooks/useMergeRefs.ts
 */
const useMergeRefs = <T>(
  refs: (React.Ref<T> | null | undefined)[]
): React.RefCallback<T> | null => {
  return React.useMemo(() => {
    if (refs.every((ref) => ref == null)) {
      return null
    }
    return (value) => {
      refs.forEach((ref) => {
        if (typeof ref === "function") {
          ref(value)
        } else if (ref != null) {
          // eslint-disable-next-line no-param-reassign
          ;(ref as React.MutableRefObject<T | null>).current = value
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, refs)
}

export default useMergeRefs
