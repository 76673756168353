import { Reducer, Action, AnyAction } from "@myvp/shared/src/types"

// Copied from https://github.com/reduxjs/redux/blob/master/src/types/reducers.ts#L39
/* eslint-disable @typescript-eslint/no-explicit-any */
type ReducersMapObject<S = any, A extends Action = AnyAction> = {
  [K in keyof S]: Reducer<S[K], A>
}

export const createReducer =
  <State = any, A extends Action = AnyAction>(actions: ReducersMapObject) =>
  (state: State, action: A): State => {
    return actions[action.type] ? actions[action.type](state, action) : state
  }

/*eslint-enable @typescript-eslint/no-explicit-any */
