import * as authServices from "src/services/auth.service"
import { isEmptyObject } from "@myvp/shared/src/functions/is-empty-object"
import {
  endTimerWithPromise,
  startTimer,
  logger,
} from "@myvp/shared/src/metrics"
import { getLocale } from "src/i18n/get-locale"
import { isMock } from "@myvp/shared/src/functions/mock"
import { MfaType, SupportedLocale } from "src/types"
import { GetUserMetadataQuery, ValidateDobInput } from "src/gql/graphql"
import { parseJwt } from "@myvp/shared/src/functions/parse-jwt"
import { refreshOpenTabs } from "src/functions/refresh-open-tabs"
import { isSessionValid } from "@myvp/shared/src/functions/is-session-valid"
import { cleanupMfaStorage } from "src/pages/confirm-identity/confirm-identity"

export interface UnregisteredAuthResponse {
  acctVerified: boolean
  firstName: string
  language: string
  lastName: string
  locale?: SupportedLocale
  mfaSent: boolean
  phoneNumber?: string
}

export const resendMfaCode = async (
  args: { username?: string; email?: string | null; mfaType?: MfaType } = {}
) => authServices.resendMfaCode(args)

export const enterMfaCode = async ({
  code,
  mfaType,
}: {
  code: string
  mfaType: MfaType
}) => {
  // Trigger event listener to remove user's metadata stored in sessionStorage
  localStorage.setItem("myvp-login", Date.now().toString())
  startTimer("ACTIVITY_PATIENT_LOGIN_MFA_SERVER")
  return endTimerWithPromise(
    authServices.enterMfaCode({
      code,
      mfaType,
    }),
    "ACTIVITY_PATIENT_LOGIN_MFA_SERVER"
  )
}

export const removeUserMetadata = () => {
  return authServices.removeUserMetadata()
}

export const login = async (input: {
  username: string
  password: string
  notificationRequestId: string
  ref: string
  baseUrl: string
}) => {
  if (isSessionValid()) {
    await logout()
    refreshOpenTabs()
  }
  let result = await authServices.login(input)
  result.bearerToken = result._httpHeaders?.get("x-atkn")
  result.frsc_t = result._httpHeaders?.get("frsc_t")
  result.frsc_k = result._httpHeaders?.get("frsc_k")
  return result
}

export const inPersonLogin = async (authToken: string) => {
  return authServices.inPersonLogin(authToken)
}

export const reauth = async (input: { password: string }) =>
  authServices.reauth(input)

export const persistUserMetadata = (metadata: GetUserMetadataQuery) => {
  return authServices.persistUserMetadata(metadata)
}

export const getUserMetadata = async () => {
  const metadata = await authServices.getUserMetadata()
  // In the event we deploy an app update and the user is on the tab and refreshes,
  // we want to make sure that we fetch in the event that timeZoneId are not there.
  if (
    isMock() ||
    !metadata.userInfo?.timeZoneId ||
    isEmptyObject(metadata.userInfo)
  ) {
    const result = await authServices.getUserInfo()
    persistUserMetadata(result)
    return result
  }
  return metadata
}
export const logout = async () => {
  // If the logout fails then let the user proceed
  await authServices.logout()
  try {
    const preferredMfaType = localStorage.getItem("mfa-type")
    localStorage.clear()
    if (preferredMfaType) {
      localStorage.setItem("mfa-type", preferredMfaType)
    }
    sessionStorage.clear()
  } catch {
    cleanupMfaStorage()
  }
  authServices.removeUserMetadata()
}

export const validateEmailCode = (input: {
  code: string
  username?: string
  newEmail?: string
  mfaCode?: string
}) => authServices.validateEmailCode(input)

export const validateNewEmailCode = async (input: {
  code?: string
  newEmail?: string
  mfaCode?: string
  mfaType?: MfaType
}) => {
  const response = await authServices.validateNewEmailCode(input)
  logger("ACTIVITY_EMAIL_UPDATE_COMPLETED")
  return response
}

export const requestForgotPasswordCode = (args: {
  username: string
  baseUrl?: string
}) => authServices.requestForgotPasswordCode(args)

export const verifyMfaCode = (input: { code: string; mfaType: MfaType }) =>
  authServices.verifyMfaCode(input)

export const userDiscovery = (input: { username: string; baseUrl?: string }) =>
  authServices.userDiscovery(input)

export const updatePhoneNumber = async (args: {
  shouldClearMetadata?: boolean
  updatedPhoneNumber: string
  mfaType?: MfaType
}) => {
  const locale = getLocale()
  logger("ACTIVITY_PHONE_NUMBER_UPDATE_INITIATED")
  const response = await authServices.updatePhoneNumber({
    ...args,
    locale,
  })
  if (args.shouldClearMetadata) {
    // There is no user provider yet - cached metadata is invalid if user went back in browser
    sessionStorage.removeItem("metadata")
  }
  return response
}

export const verifyUpdatedPhoneNumber = async (args: {
  code: string
  updatedPhoneNumber: string
  mfaType: MfaType
}) => {
  const response = await authServices.verifyUpdatedPhoneNumber(args)
  logger("ACTIVITY_PHONE_NUMBER_UPDATE_COMPLETED")
  return response
}

export const updateEmail = (input: {
  newEmail: string
  forceEmailSend?: boolean
}) => {
  logger("ACTIVITY_EMAIL_UPDATE_INITIATED")
  return authServices.updateEmail(input)
}

export const updateUserLanguage = ({ locale }: { locale: SupportedLocale }) =>
  authServices.updateUserLanguage({ locale })

export const verifyEmailMfa = async (token: string) => {
  const result = await authServices.verifyEmailMfa(token)
  const bearerToken = result._httpHeaders?.get("x-atkn")
  const isVerified = bearerToken && !!parseJwt(bearerToken)?.ver
  return { ...result, isVerified }
}

export const verifyEmail = (token: string) => {
  return authServices.verifyEmail(token)
}

export const isValidVaultSession = () => authServices.isValidVaultSession()

export const setUsername = async (args: {
  shouldClearMetadata?: boolean
  username: string
}) => {
  const response = await authServices.setUsername(args.username)
  if (args.shouldClearMetadata) {
    sessionStorage.removeItem("metadata")
  }
  return response
}

export const setPassword = async (password: string) => {
  const { status, _httpHeaders, ...response } =
    await authServices.setPassword(password)
  return response
}

export const setEmail = async (email: string) => {
  const response = await authServices.setEmail(email)
  sessionStorage.removeItem("metadata")
  return response
}

export const validateDob = (input: ValidateDobInput) =>
  authServices.validateDob(input)

export const authorizeMfaCodes = (args: {
  username: string
  emailCode: string
  mfaCode: string
  mfaType?: string
}) => authServices.authorizeMfaCodes(args)

export const validateNewActivationCode = (activationCode: string) =>
  authServices.validateNewActivationCode(activationCode.toUpperCase())

export const confirmTerms = (versionNumber: string) => {
  return authServices.confirmTerms(versionNumber)
}
