import React from "react"
import { createRoot } from "react-dom/client"
import Providers from "src/router/providers"
import styled from "@emotion/styled"
import { RouterProvider } from "react-router-dom"
import createAppRouter from "src/router/create-app-router"
import { routeNames } from "src/router/route-names"
import { emailBeacon } from "src/functions/email-beacon"

if (process.env.NODE_ENV === "production") {
  const sha = process.env.REACT_APP_SHA
  const gitlabLink =
    process.env.REACT_APP_release !== "true"
      ? `, https://gitlab.veevadev.com/myveeva-clinicaltrials/web/-/commits/${sha}`
      : ""
  // eslint-disable-next-line no-console
  console.log(
    `App Version ${process.env.REACT_APP_VERSION}, ${sha}${gitlabLink}`
  )
}

const Main = styled.main`
  height: 100%;
`

const router = createAppRouter()

const StrictMode = (props: { children: React.ReactNode }) => {
  // Even though strict mode doesn't work on production environments, let React turn it off for us for now
  return window.location.pathname === routeNames.econsentEditor &&
    process.env.NODE_ENV === "development" ? (
    <React.Fragment>{props.children}</React.Fragment>
  ) : (
    <React.StrictMode>{props.children}</React.StrictMode>
  )
}
const root = createRoot(document.getElementById("root") as HTMLElement)
root.render(
  <StrictMode>
    <Providers>
      <Main>
        <React.Suspense fallback={null}>
          <RouterProvider router={router} />
        </React.Suspense>
      </Main>
    </Providers>
  </StrictMode>
)

document.addEventListener("onBoomerangLoaded", () => {
  emailBeacon()
})
