import React from "react"
import styled from "@emotion/styled"
import breakpoints from "@myvp/shared/src/styles/breakpoints"
import { Medium16Body } from "@myvp/shared/src/typography"
import { useIntl } from "react-intl"
import MyVeevaForPatients from "@myvp/shared/src/logos/myveeva-for-patients"
import _IconLink from "@myvp/shared/src/icons/icon-link"
import Arrow from "@myvp/shared/src/icons/arrow"
import { useLocation, useMatches } from "react-router-dom"
import { getDefaultBackPath } from "src/router/routes"
import { routeNames } from "src/router/route-names"
import { css } from "@emotion/react"

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100dvh;
  margin: auto;
  ${breakpoints("xs")} {
    align-items: flex-start;
  }
`
const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 640px;
  height: 90dvh;
  width: 544px;
  border-radius: 8px;
  padding-bottom: 40px;
  border: 1px solid ${(props) => props.theme.palette.border.default};
  ${breakpoints("sm")} {
    width: 476px;
  }
  ${breakpoints("xs")} {
    width: 100%;
    height: 100dvh;
    max-height: unset;
    border: none;
  }
`
const HeaderBlock = styled.div`
  display: flex;
  align-items: flex-start;
  min-height: 56px;
  ${breakpoints("xs")} {
    border-bottom: 1px solid ${(props) => props.theme.palette.border.default};
  }
`
const IconLink = styled(_IconLink)`
  height: 56px;
  text-decoration: none;
  display: flex;
  gap: 8px;
  padding-block: 0;
  padding-inline: 8px 16px;
  border-radius: 0;
  border-start-start-radius: 8px;
`
const BackText = styled(Medium16Body)`
  color: ${(props) => props.theme.palette.foreground.default};
`
const MainBlock = styled.div<{ hasBackButton: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  height: 100%;
  padding: 0 56px;
  overflow: auto;
  ${(props) =>
    !props.hasBackButton &&
    css`
      margin-top: 32px;
    `}
  ${breakpoints("xs")} {
    padding: 16px;
  }
  ${MyVeevaForPatients} {
    width: 120px;
    height: 33px;
    min-width: 120px;
    min-height: 33px;
  }
`
const MainBlockContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 100%;
  width: 100%;

  h1 {
    color: ${(props) => props.theme.palette.foreground.default};
  }
`

const ConfirmIdentityWrapper = (props: {
  className?: string
  children: React.ReactNode
  backPath?: string
  hasBackButton?: boolean
}) => {
  const hasBackButton = props.hasBackButton ?? true
  const { formatMessage } = useIntl()
  const matches = useMatches()
  const { search, state } = useLocation()
  const defaultBackPath = getDefaultBackPath(matches) ?? routeNames.login
  const backPath = props.backPath ?? defaultBackPath

  return (
    <Container className={props.className}>
      <MainContent>
        {hasBackButton && (
          <HeaderBlock>
            <IconLink
              to={{ pathname: backPath, search }}
              state={state}
              aria-label={formatMessage({ id: "entry.navigateBack" })}
              data-automation-id="login-flows-back-button"
            >
              <Arrow variant="left" size="large" />
              <BackText>{formatMessage({ id: "entry.back" })}</BackText>
            </IconLink>
          </HeaderBlock>
        )}
        <MainBlock hasBackButton={hasBackButton}>
          <MyVeevaForPatients
            altText={formatMessage({ id: "title.documentTitle" })}
          />
          <MainBlockContent>{props.children}</MainBlockContent>
        </MainBlock>
      </MainContent>
    </Container>
  )
}

export default ConfirmIdentityWrapper
