import { type QueryClientConfig } from "@tanstack/react-query"

export const defaultReactQueryClientOptions = {
  queries: {
    refetchOnReconnect: false,
    retry: false,
    refetchOnWindowFocus: false,
    throwOnError: true,
    // https://tanstack.com/query/latest/docs/react/guides/testing#set-gctime-to-infinity-with-jest
    gcTime: process.env.NODE_ENV === "test" ? Infinity : 0,
    staleTime: process.env.NODE_ENV === "test" ? Infinity : 0,
  },
  mutations: {
    retry: false,
    throwOnError: true,
    gcTime: process.env.NODE_ENV === "test" ? Infinity : 0,
  },
} satisfies QueryClientConfig["defaultOptions"]

export const TWO_SECONDS = 2 * 1000

export const FIVE_MINUTES = 5 * 1000 * 60

export const FIFTEEN_MINUTES = 15 * 1000 * 60
