import React from "react"
import LandingScreen from "src/components/landing-screen"
import styled from "@emotion/styled"
import { useIntl } from "react-intl"
import {
  // eslint-disable-next-line no-restricted-imports
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom"
import { routeNames } from "src/router/route-names"
import * as authModel from "src/models/auth.model"
import { logger } from "@myvp/shared/src/metrics"
import {
  Medium24Header1,
  Regular14Paragraph,
} from "@myvp/shared/src/typography"
import Button from "@myvp/shared/src/components/buttons/button"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 560px;
  padding: 8px 0;

  button {
    width: 325px;
    height: 44px;
  }

  ${Medium24Header1} {
    color: ${(props) => props.theme.palette.foreground.default};
  }

  ${Regular14Paragraph} {
    color: ${(props) => props.theme.palette.foreground.alt};
  }
`
const Text = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${(props) => props.theme.palette.foreground.default};
  gap: 8px;
`

const ConfirmNewEmailExpired = () => {
  const { formatMessage } = useIntl()
  const [searchParams] = useSearchParams()
  const token = searchParams.get("token")
  const navigate = useNavigate()
  const location = useLocation()
  const locationState = location.state as { from?: string } | undefined
  const [isExpired, setIsExpired] = React.useState(false)

  // When the user is logged out, ConfirmNewEmail will redirect here as the fallback
  // regardless of whether the email is expired, so we still need to check it
  React.useEffect(() => {
    if (locationState?.from !== routeNames.confirmNewEmail && token) {
      authModel
        .validateEmailCode({ code: token })
        .then(() => {
          navigate(
            {
              pathname: routeNames.login,
              search: location.search,
            },
            { state: { from: routeNames.confirmNewEmail } }
          )
        })
        .catch(() => {
          setIsExpired(true)
          logger("ACTIVITY_EXPIRED_EMAIL_CODE_ENTERED")
        })
    } else {
      setIsExpired(true)
      logger("ACTIVITY_EXPIRED_EMAIL_CODE_ENTERED")
    }
  }, [navigate, location.search, locationState, token])

  return (
    <LandingScreen>
      {({ Logo }) =>
        isExpired && (
          <Container>
            <Logo />
            <Text>
              <Medium24Header1>
                {formatMessage({
                  id: "settings.linkExpired",
                })}
              </Medium24Header1>
              <Regular14Paragraph>
                {formatMessage({ id: "settings.linkExpiredMessage" })}
              </Regular14Paragraph>
            </Text>
            <Link
              to={{
                pathname: routeNames.settings,
                search: location.search,
              }}
            >
              <Button variant="primary" height="medium">
                {formatMessage({ id: "settings.returnToSettings" })}
              </Button>
            </Link>
          </Container>
        )
      }
    </LandingScreen>
  )
}

export default ConfirmNewEmailExpired
