import React from "react"
import { css } from "@emotion/react"
import Modal from "@myvp/shared/src/components/modal"
import { useIntl } from "react-intl"
import Button from "@myvp/shared/src/components/buttons/button"
import breakpoints from "@myvp/shared/src/styles/breakpoints"
import useStorageState from "@myvp/shared/src/hooks/use-storage-state"
import { isAndroid } from "@myvp/shared/src/functions/is-android"
import { isIos } from "@myvp/shared/src/functions/is-ios"
import { useLocation } from "react-router-dom"
import { logger } from "@myvp/shared/src/metrics"
import logoImg from "src/images/logo.png"
import Link from "@myvp/shared/src/components/buttons/link"

const SmartBanner = () => {
  const location = useLocation()
  const { pathname } = location
  const state = location.state as { userId?: string } | undefined
  const android = isAndroid()
  const ios = isIos()
  const { formatMessage } = useIntl()
  const [visible, setVisible] = useStorageState(true, "smart-banner")
  if (!android && !ios) {
    return null
  }
  return (
    <Modal
      close={() => {
        logger("ACTIVITY_SMART_BANNER_DISMISS", {
          page: pathname,
          userid: state?.userId,
          platform: ios ? "ios" : "android",
        })
        setVisible(false)
      }}
      clearOnClickOutside={true}
      aria-labelledby="smart-banner-title"
      visible={visible}
      css={css`
        #modal-popup {
          width: 100%;
          max-width: 392px;
          padding: 36px;
        }
        ${breakpoints("sm")} {
          align-items: flex-end;
          margin: 0;
          #modal-popup {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
          }
        }
        img {
          width: 66px;
          height: 66px;
        }
      `}
    >
      <div
        css={(theme) => css`
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 100%;
          a {
            width: 100%;
          }
          button {
            width: 100%;
            max-width: 318px !important;
          }
          > p {
            max-width: 321px;
            width: 100%;
            color: ${theme.palette.steel.five};
            text-align: center;
            font-size: 32px;
            margin: 22px;
            font-weight: bold;
          }
        `}
      >
        <img alt={formatMessage({ id: "title.documentTitle" })} src={logoImg} />
        <p id="smart-banner-title">
          {formatMessage({ id: "smartBanner.downloadTheMyVeevaApp" })}
        </p>
        <Button
          internalType="link"
          variant="primary"
          height="large"
          tabIndex={-1}
          type="button"
          to={
            ios
              ? (process.env.REACT_APP_ios_app_url as string)
              : (process.env.REACT_APP_android_app_url as string)
          }
          onClick={() => {
            logger("ACTIVITY_SMART_BANNER_VIEW_APPSTORE", {
              page: pathname,
              userid: state?.userId,
              platform: ios ? "ios" : "android",
            })
          }}
          css={css`
            // Mobile autofocuses this on load, but we don't want the outline to show.
            // Since this only appears on mobile devices it's fine to turn off all outlines.
            outline: none;
          `}
        >
          {formatMessage({ id: "smartBanner.getTheApp" })}
        </Button>
        <Link
          internalType="button"
          onClick={() => {
            logger("ACTIVITY_SMART_BANNER_DISMISS", {
              page: pathname,
              userid: state?.userId,
              platform: ios ? "ios" : "android",
            })
            setVisible(false)
          }}
          css={css`
            margin-block-start: 16px;
          `}
        >
          {formatMessage({ id: "smartBanner.continueInBrowser" })}
        </Link>
      </div>
    </Modal>
  )
}

export default SmartBanner
