import React from "react"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { defaultReactQueryClientOptions } from "@myvp/shared/src/default-react-query-client-options"
import { RootUIProviders } from "@myvp/shared/src/ui-providers"
import { useI18nState } from "@myvp/shared/src/i18n/i18n-state-provider"
import { getLocale } from "src/i18n/get-locale"
import { getTextDirection } from "@myvp/shared/src/functions/get-text-direction"
import _enUS from "src/i18n/languages/enUS"
import eproViewerEnUs from "@myvp/epro-viewer/src/i18n/languages/enUS"

const enUS = {
  ..._enUS,
  ...eproViewerEnUs,
}
const queryClient = new QueryClient({
  defaultOptions: defaultReactQueryClientOptions,
})

const Providers = (props: {
  children?: React.ReactNode
  queryClient?: QueryClient
  /**
   * This should only be provided in test cases.
   */
  staticTestMessages?: Record<string, string>
}) => {
  return (
    <QueryClientProvider client={props.queryClient ?? queryClient}>
      <RootUIProviders
        resolveLocale={getLocale}
        loadMessages={loadMessages}
        staticTestMessages={props.staticTestMessages}
        textDirection={getTextDirection}
      >
        {props.children}
      </RootUIProviders>
    </QueryClientProvider>
  )
}

export default Providers

const loadMessages = async (locale: string) => {
  const messagesKey = locale.replaceAll("-", "")
  let patientsMessages = {}
  try {
    patientsMessages = await import(`src/i18n/languages/${messagesKey}`)
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(`Failed to load src/i18n/languages/${messagesKey}`, e)
  }

  return {
    ...enUS,
    ...patientsMessages,
  } as Record<string, string>
}

// TODO MYVC-17483 remove and update imports to import from shared instead
export const useIntlProvider = useI18nState
