export const sleep = (time: number): Promise<void> => {
  return new Promise((resolve) => {
    return setTimeout(resolve, time)
  })
}

/**
 * Given a time and start which should be Date.now(), this will sleep the remaining time if needed.
 *
 * Example:
 *
 * let start = Date.now()
 * let result = await externalApi()
 * // If you wanted result to take at least 500 milliseconds for animation or whatever
 * await sleepIfNeeded(500, start)
 *
 * Then this function will compare see how much time has ellapsed, anything less than
 * 500 milliseconds will sleep for 500 milliseconds - time ellapsed, anything greater, will
 * not sleep at all
 */
export const sleepIfNeeded = async (
  time: number,
  start: number | Date
): Promise<void> => {
  const timeElapsed = Date.now() - Number(start)
  /* istanbul ignore else */
  if (time > timeElapsed) {
    await sleep(time - timeElapsed)
  }
}
