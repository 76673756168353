import React from "react"
import { Navigate, useLocation, useSearchParams } from "react-router-dom"
import { routeNames } from "src/router/route-names"
import { UserProvider } from "src/hooks/use-user"
import { isSessionValid } from "@myvp/shared/src/functions/is-session-valid"
import * as authModel from "src/models/auth.model"
import { useIntlProvider } from "src/router/providers"
import { getLocale } from "src/i18n/get-locale"
import AppPolicyModal from "src/components/app-policy-modal"

function RedirectToLogin({ from }: { from: string }) {
  const { updateIntl } = useIntlProvider()
  const { search } = useLocation()

  React.useEffect(() => {
    authModel.removeUserMetadata()
    updateIntl(getLocale())
  }, [updateIntl])

  return (
    <Navigate
      replace
      to={{
        pathname: routeNames.login,
        search,
      }}
      state={{ from }}
    />
  )
}

const AuthenticatedRoute = (props: {
  Fallback?: React.ComponentType
  children?: React.ReactNode
}) => {
  const Fallback = props.Fallback
  const { pathname, search } = useLocation()
  const [searchParams] = useSearchParams()

  const id = searchParams.get("id")
  if (id) {
    return (
      <Navigate
        replace
        to={{
          pathname: routeNames.determineUser,
          search,
        }}
        state={{ from: pathname }}
      />
    )
  } else if (!isSessionValid()) {
    if (Fallback) {
      return <Fallback />
    }
    // eslint-disable-next-line no-console
    console.log("Invalid myvp-sesttl, redirecting to login")
    return <RedirectToLogin from={pathname} />
  }

  return (
    <UserProvider>
      {({
        isUserApiFetching,
        isUserLoggedIn,
        isUnregisteredUserAuthorized,
      }) => {
        if (isUserApiFetching) {
          return null
        }
        if (!isUserLoggedIn && !isUnregisteredUserAuthorized) {
          if (Fallback) {
            return <Fallback />
          }
          return (
            <Navigate replace to={{ pathname: routeNames.login, search }} />
          )
        }

        return (
          <>
            <AppPolicyModal />
            {props.children}
          </>
        )
      }}
    </UserProvider>
  )
}

export default AuthenticatedRoute
