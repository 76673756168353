import React from "react"
import styled from "@emotion/styled"
// eslint-disable-next-line no-restricted-imports
import { Link, LinkProps } from "react-router-dom"
import { css } from "@emotion/react"

export type IconLinkProps = (
  | {
      variant?: "Link"
      to: LinkProps["to"]
      state?: object
      className?: string
    }
  | { variant: "a"; href: string }
) & {
  title: string
  color?: "default" | "black" | "inherit"
  target?: string
  children: React.ReactNode
} & React.HTMLAttributes<HTMLAnchorElement>

const IconLink = (props: IconLinkProps) => {
  if (props.variant === "a") {
    const { variant, color, ...rest } = props
    return <a {...rest}>{props.children}</a>
  } else {
    const { variant, color, ...rest } = props
    return <Link {...rest}>{props.children}</Link>
  }
}

const defaultProps: Partial<IconLinkProps> = {
  variant: "Link",
  color: "default",
}
IconLink.defaultProps = defaultProps

/** @deprecated Use IconButton with internalType="link" */
export default styled(IconLink)`
  width: auto;
  height: 31px;
  padding: 0 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: none;
  border-radius: ${(props) => props.theme.borderRadius};
  cursor: pointer;
  :hover {
    background-color: ${(props) => props.theme.palette.steel.one};
    ${(props) =>
      props.color === "black" &&
      css`
        background-color: ${props.theme.palette.steel.seven};
      `};

    ${(props) =>
      props.color === "inherit" &&
      css`
        background-color: inherit;
      `};
  }

  :active {
    opacity: 0.7;
  }
`
