import React from "react"
import styled from "@emotion/styled"
import { Medium14, Medium16Body } from "@myvp/shared/src/typography"
import { Link } from "react-router-dom"
import {
  CoreButtonProps,
  IconElement,
  InternalButton,
  InternalLink,
  activeCss,
  getButtonIconColor,
  heightStyles,
  variantStyles,
} from "@myvp/shared/src/components/buttons/button-utils"
import { css } from "@emotion/react"

interface TextButtonProps extends CoreButtonProps {
  children: React.ReactNode
  startIcon?: IconElement
  endIcon?: IconElement
}

interface ExternalButtonProps
  extends Omit<React.ComponentPropsWithoutRef<"button">, "children">,
    TextButtonProps {
  internalType?: "button"
}
interface ExternalButtonLinkProps
  extends Omit<React.ComponentPropsWithoutRef<typeof Link>, "children">,
    TextButtonProps {
  internalType: "link"
}

const baseStyles = () => css`
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  border-radius: 4px;
  padding: 4px;
  gap: 2px;
  cursor: pointer;
`

const Button = React.forwardRef<
  HTMLAnchorElement | HTMLButtonElement,
  ExternalButtonProps | ExternalButtonLinkProps
>(function Button(props, ref) {
  const Typography = props.height === "large" ? Medium16Body : Medium14
  const styles = [
    baseStyles(),
    heightStyles[props.height],
    variantStyles[props.variant],
    activeCss(!!props.isAnchorOpen),
  ]
  const disabled =
    "disabled" in props
      ? props.disabled
      : props["aria-disabled"] === true || props["aria-disabled"] === "true"

  const internalChildren = (
    <>
      {!!props.startIcon &&
        props.startIcon({
          size: "medium",
          color: getButtonIconColor({
            variant: props.variant,
            disabled,
          }),
        })}
      <Typography>{props.children}</Typography>
      {!!props.endIcon &&
        props.endIcon({
          size: "medium",
          color: getButtonIconColor({
            variant: props.variant,
            disabled,
          }),
        })}
    </>
  )

  if (props.internalType === "link") {
    const {
      internalType,
      variant,
      height,
      startIcon,
      endIcon,
      isAnchorOpen,
      ...linkProps
    } = props
    const linkRef = ref as React.ForwardedRef<HTMLAnchorElement>
    return (
      <InternalLink ref={linkRef} {...linkProps} css={styles}>
        {internalChildren}
      </InternalLink>
    )
  } else {
    const {
      internalType,
      variant,
      height,
      startIcon,
      endIcon,
      isAnchorOpen,
      ...buttonProps
    } = props
    const buttonRef = ref as React.ForwardedRef<HTMLButtonElement>
    return (
      <InternalButton ref={buttonRef} {...buttonProps} css={styles}>
        {internalChildren}
      </InternalButton>
    )
  }
})

export default styled(Button)``
