export const getBrowserTimezone = () =>
  Intl.DateTimeFormat().resolvedOptions().timeZone

export const getBrowserTimezoneGMT = (timezoneOffset?: number) => {
  const offsetNumber = timezoneOffset ?? new Date().getTimezoneOffset()
  const absoluteOffset = Math.abs(offsetNumber)
  const timezoneGMT =
    "GMT" +
    (offsetNumber < 0 ? "+" : "-") +
    Math.floor(absoluteOffset / 60)
      .toString()
      .padStart(2, "00") +
    ":" +
    (absoluteOffset % 60).toString().padStart(2, "00")
  return timezoneGMT
}
