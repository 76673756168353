import { isMock } from "@myvp/shared/src/functions/mock"

const SECONDS_TO_MS = 1000

/**
 * Checks the sesttl claim in localStorage to determine whether the
 * session has already timed out or is currently valid
 *
 * @returns true if session is valid, else false
 */
export const isSessionValid = (p = false) => {
  if (isMock()) {
    return true
  }
  const key = p ? "myvp-psesttl" : "myvp-sesttl"
  const sesttl = localStorage.getItem(key)
  const valid = !!sesttl && Date.now() < Number(sesttl) * SECONDS_TO_MS
  if (!valid) {
    localStorage.removeItem(key)
  }
  return valid
}
