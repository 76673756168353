import { HttpCode } from "@myvp/shared/src/types"
import { Scalars } from "src/gql/graphql"
import { SUPPORTED_LOCALES } from "src/i18n/locales"

export type SupportedLocale =
  (typeof SUPPORTED_LOCALES)[keyof typeof SUPPORTED_LOCALES]

export type EconsentHeaderFooterAlignment = "left" | "center" | "right"

export enum MfaType {
  Sms = "sms",
  Call = "call",
  Email = "email",
}

export interface Login {
  status: HttpCode
  id?: Scalars["ID"]["output"]
  email?: string
  phoneNumber?: string
  firstName?: string
  middleName?: string
  lastName?: string
  locale?: string
  language?: string
  isUserPresent?: boolean
  acctVerified?: boolean
}

export enum SettingsStatus {
  Default = "DEFAULT",
  EmailPending = "EMAIL_PENDING",
  LanguageEditing = "LANGUAGE_EDITING",
  TimeZoneEditing = "TIME_ZONE_EDITING",
}

export enum ConfirmFieldType {
  Email = "EMAIL",
  Phone = "PHONE",
}

export enum PageVariant {
  Vconsent = "vconsent",
  Patient = "patient",
  Epro = "epro",
}

export enum EproErrorType {
  SurveyOutOfSequence = "SURVEY_OUT_OF_SEQUENCE",
}

export enum VaultType {
  Site = "SITE",
  ClinOps = "CLINOPS",
}

export type EntryHeaderTextId =
  | "entry.account"
  | "entry.study"
  | "study.resources"
  | "study.consentForms"
  | "study.details"
  | "entry.todo"
  | "account.settings"

export enum EconsentPreviewErrorKey {
  InvalidSignatoryRole = "INVALID_SIGNATORY_ROLE",
}
