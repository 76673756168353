import * as registerLegacyServices from "src/pages/register/services/register-legacy.service"
import { MfaType } from "src/types"

export const sendMfa = async (args: {
  mfaType: MfaType
  relatedUserId: string
  notificationId: string
}) => registerLegacyServices.sendMfa(args)

export const verifyMfa = (args: {
  code: string
  mfaType: MfaType
  relatedUserId: string
  notificationId: string
}) => registerLegacyServices.verifyMfa(args)

export const createOrGetSelfRegistrationUser = (args: {
  client: string
  relatedUserId: string
  relatedUserRoleId: string
}) => registerLegacyServices.createOrGetSelfRegistrationUser(args)

export const authorizeSelfRegistration = (args: {
  authId: string
  type: string
  client: string
  relatedUserId: string
}) => registerLegacyServices.authorizeSelfRegistration(args)

export const updateSelfRegisteredUser = (authId: string) =>
  registerLegacyServices.updateSelfRegisteredUser(authId)
