import React from "react"
import { type RouteObject } from "react-router-dom"
import studyRouteNames from "src/pages/entry/study/study-route-names"
import type { EntryRouteHandle } from "src/pages/entry/entry"
import type { NestedRouteHandle } from "src/router/routes"

export const createStudyRoutes = (): RouteObject => ({
  id: "study",
  path: studyRouteNames.root,
  lazy: async () => {
    const { StudyErrorBoundary } = await import("src/pages/entry/study/study")
    return {
      errorElement: <StudyErrorBoundary />,
    }
  },
  handle: {
    headerTextId: "entry.study",
    isMainTab: true,
  } satisfies EntryRouteHandle,
  children: [
    {
      path: studyRouteNames.resources,
      lazy: async () => import("src/pages/entry/study/resources"),
      handle: {
        defaultBackPath: studyRouteNames.root,
        headerTextId: "study.resources",
        isMainTab: false,
      } satisfies NestedRouteHandle & EntryRouteHandle,
    },
    {
      path: studyRouteNames.consentForms,
      lazy: async () => import("src/pages/entry/study/consent-forms"),
      handle: {
        defaultBackPath: studyRouteNames.root,
        headerTextId: "study.consentForms",
        isMainTab: false,
      } satisfies NestedRouteHandle & EntryRouteHandle,
    },
    {
      path: studyRouteNames.details,
      lazy: async () => import("src/pages/entry/study/details"),
      handle: {
        defaultBackPath: studyRouteNames.root,
        headerTextId: "study.details",
        isMainTab: false,
      } satisfies NestedRouteHandle & EntryRouteHandle,
    },
    {
      index: true,
      path: studyRouteNames.root,
      lazy: async () => import("src/pages/entry/study/study"),
    },
  ],
})
