import { generatePath } from "react-router-dom"
import { type Prettify } from "@myvp/shared/src/types"

type _FindUrlParams<T extends string> =
  // recurse on the left and right sides of the "/"
  T extends `${infer L}/${infer R}`
    ? _FindUrlParams<L> | _FindUrlParams<R>
    : T extends `:${infer U}`
      ? U
      : never
/**
 * this type resolves to:
 * - if the path is just "*" or "/*" -> "*"
 * - if the path ends with "/*" -> param1 | ... | paramN | "*"
 * - if the path has params -> param1 | ... | paramN
 * - else -> never
 */
type FindUrlParams<Path extends string> = Path extends "*" | "/*"
  ? "*"
  : Path extends `${infer Rest}/*`
    ? "*" | _FindUrlParams<Rest>
    : _FindUrlParams<Path>

type UrlParamsArg<T extends string> = Prettify<
  Intersect<
    T extends `${infer O}?`
      ? // optional url path param
        { [key in O]?: string }
      : // required url path param
        { [key in T]: string }
  >
>

/**
 * Object whose properties are the url parameters for a url string.
 *
 * These are defined in the `react-router-dom` style (example: /:param1/:param2).
 */
export type RouteArgs<Path extends string> = UrlParamsArg<FindUrlParams<Path>>

/**
 * Distributing a conditional type in a contra-variant position produces an intersection.
 *
 * @see https://www.typescriptlang.org/docs/handbook/release-notes/typescript-2-8.html#distributive-conditional-types
 */
type Intersect<U> = (U extends any ? (k: U) => void : never) extends (
  k: infer I
) => void
  ? I
  : never

type IfRouteParams<T extends string, U, V> = [FindUrlParams<T>] extends [never]
  ? V
  : U

/**
 * Returns a function that returns a formatted url route.
 * It may require arguments if the `route` argument has url parameters.
 */
export function createRoutePath<const T extends string>(
  route: IfRouteParams<T, T, never>
): {
  (params: RouteArgs<T>): string
  route: T
}
export function createRoutePath<const T extends string>(
  route: T
): {
  (): T
  route: T
}
export function createRoutePath<const T extends string>(route: T) {
  const func = route.includes("/:")
    ? (params: RouteArgs<T>) => generatePath(route, params as any)
    : () => route
  ;(func as typeof func & { route: string }).route = route
  return func
}
