import React from "react"
// eslint-disable-next-line no-restricted-imports
import _FocusLock from "react-focus-lock"

/**
 * This is a disabled implementation for FocusLock from react-focus-lock. This is needed
 * to speed up jest tests because react-focus-lock rerenders itself a lot and starves
 * out other component renders during jest tests (this is a jsdom + react-focus-lock interaction).
 *
 * This has happened in the past before (these issues shouldn't apply to our current version though):
 *
 * - https://github.com/chakra-ui/chakra-ui/pull/4995
 * - https://github.com/theKashey/react-focus-lock/issues/125#issuecomment-755229155
 */
const FakeFocusLock: typeof _FocusLock = (props) => {
  return (
    <_FocusLock {...props} disabled>
      {props.children}
    </_FocusLock>
  )
}

const FocusLock =
  process.env.REACT_APP_run_axe === "true" || process.env.NODE_ENV !== "test"
    ? _FocusLock
    : FakeFocusLock

export default FocusLock
