import { css, type Theme } from "@emotion/react"

export const KEYS = {
  tab: "Tab",
  arrowDown: "ArrowDown",
  arrowLeft: "ArrowLeft",
  arrowRight: "ArrowRight",
  arrowUp: "ArrowUp",
  backspace: "Backspace",
  enter: "Enter",
  escape: "Escape",
  space: " ",
}
// https://testing-library.com/docs/ecosystem-user-event/#special-characters
export const TEST_KEYS = {
  arrowDown: "{arrowdown}",
  arrowLeft: "{arrowleft}",
  arrowRight: "{arrowright}",
  arrowUp: "{arrowup}",
  backspace: "{backspace}",
  enter: "{Enter}",
  escape: "{Escape}",
  space: "[Space]",
}

export const headerHeight = "52px"
export const headerCss = (theme: Theme) => css`
  background-color: ${theme.palette.oneOff.studio};
  height: ${headerHeight};
  padding-inline: 24px 20px;
`

export const VAULT_CLINICAL_URL = "https://clinical.veevavault.help/en/gr"

/**
 * https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
 */
export const IMAGE_TYPE_MAPPING = {
  "image/gif": "GIF",
  "image/jpeg": "JPEG",
  "image/png": "PNG",
}
export const VIDEO_TYPE_MAPPING = {
  "video/mp4": "MP4",
}

/**
 * Example domains may be https://patients-dev-ft.myveevatrials.com, https://patients.myveeva.com, https://sponsors-us.local.com:3005
 *
 * These would return myveevatrials.com, myveeva.com, local.com:3005 respectively
 */
export const GLOBAL_DOMAIN = window.location.hostname.substring(
  window.location.hostname.indexOf(".") + 1
)

export const ARIA_DATA_ATTRIBUTES = {
  /**
   * Flag to force using aria-disabled on interactive elements.
   *
   * This fixes tooltips being shown on safari 16.4 (isn't required after that version).
   */
  DISABLED: "data-use-aria-disabled",
} as const
