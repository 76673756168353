import React from "react"
import styled from "@emotion/styled"
import breakpoints from "@myvp/shared/src/styles/breakpoints"
import { useIntl } from "react-intl"
import _MyVeevaForPatients from "@myvp/shared/src/logos/myveeva-for-patients"

const Page = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  height: 100%;
  ${breakpoints("md")} {
    height: unset;
    min-height: 100%;
  }
  ${breakpoints("sm", "height")} {
    height: unset;
    min-height: 100%;
  }
  ${_MyVeevaForPatients} {
    min-width: 134px;
    min-height: 37px;
  }
`

const Content = styled.div`
  padding: 32px;
  border: 1px solid ${(props) => props.theme.palette.border.default};
  border-radius: 8px;
  display: flex;
  width: 100%;
  justify-content: center;
  width: 540px;
  min-height: 640px;
  height: 640px;
  ${breakpoints("sm")} {
    border: none;
  }

  ${breakpoints("md", "height")} {
    border: none;
  }
`
const Children = styled.div`
  height: 100%;
  max-width: 432px;
  width: 100%;
  display: flex;
  justify-content: center;
  ${breakpoints("xs")} {
    width: 300px;
  }

  h1 {
    color: ${(props) => props.theme.palette.foreground.default};
  }
`
function LandingScreen(props: {
  children?:
    | React.ReactNode
    | (({ Logo }: { Logo: React.ElementType }) => React.ReactNode)
  className?: string
}) {
  const { formatMessage } = useIntl()
  const MyVeevaForPatients = () => (
    <_MyVeevaForPatients altText={formatMessage({ id: "myVeeva.image.alt" })} />
  )
  return (
    <Page>
      <Content className={props.className}>
        <Children>
          {typeof props.children === "function"
            ? props.children({
                Logo: MyVeevaForPatients,
              })
            : props.children}
        </Children>
      </Content>
    </Page>
  )
}

export default LandingScreen
