import React from "react"
import styled from "@emotion/styled"
import breakpoints from "@myvp/shared/src/styles/breakpoints"
import MyVeevaForPatients from "@myvp/shared/src/logos/myveeva-for-patients"
import { useIntl } from "react-intl"

const Header = styled.header`
  height: 56px;
  background: ${(props) => props.theme.palette.steel.zero};
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid ${(props) => props.theme.palette.steel.three};
  padding: 0 72px;
  ${breakpoints("md")} {
    padding: 0 32px;
  }
  ${breakpoints("xs")} {
    padding: 0 8px;
  }
  ${MyVeevaForPatients} {
    width: 120px;
    height: 33px;
  }
`
const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 56px;
  text-align: center;
  h1 {
    color: ${(props) => props.theme.palette.steel.eight};
    font-size: 24px;
    line-height: 24px;
  }
  p {
    font-size: 18px;
    line-height: 22px;
    color: ${(props) => props.theme.palette.steel.seven};
  }
`
const Fallback = (props: { children?: React.ReactNode }) => {
  const { formatMessage } = useIntl()
  return (
    <>
      <Header>
        <MyVeevaForPatients
          altText={formatMessage({ id: "title.documentTitle" })}
        />
      </Header>
      <Container>{props.children}</Container>
    </>
  )
}

export default Fallback
