import React from "react"
import useForm, { FormState, StopArgs } from "@myvp/shared/src/hooks/use-form"
import { CODE_INPUT_ANIMATION_TIME } from "src/constants"
import Lottie from "react-lottie-player"
import enterCodeSuccess from "src/animations/enter-code-success.json"
import { useSend } from "src/components/enter-mfa-form"
import { useIntl } from "react-intl"
import CodeInput, {
  CodeInputStatus,
} from "@myvp/shared/src/components/code-input"
import { MfaType } from "src/types"
import { css } from "@emotion/react"
import Alert from "@myvp/shared/src/icons/deprecated-alert"
import {
  Medium16Body,
  Medium24Header4,
  Regular14Paragraph,
} from "@myvp/shared/src/typography"
import Button from "@myvp/shared/src/components/buttons/button"
import useDidUpdate from "@myvp/shared/src/hooks/use-did-update"
import { sleep } from "@myvp/shared/src/functions/sleep"
import styled from "@emotion/styled"
import { HttpCode } from "@myvp/shared/src/types"
import { getErrorCode } from "@myvp/shared/src/functions/get-error-code"
import AlertModal from "@myvp/shared/src/components/alert-modal"
import SomethingWentWrongModal from "src/pages/register/components/something-went-wrong-modal"
import { Navigate, useLocation } from "react-router-dom"
import { routeNames } from "src/router/route-names"
import useInitialMount from "@myvp/shared/src/hooks/use-initial-mount"

interface MfaValues {
  code: string
}

const Container = styled.div<{ hasTitle: boolean }>`
  width: 100%;
  height: 100%;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${(props) => props.theme.palette.foreground.default};

  ${Regular14Paragraph} {
    padding-block: 16px;
  }

  ${(props) =>
    props.hasTitle &&
    css`
      padding-top: 16px;
    `}
`

export const PhoneErrorModal = (args: {
  errorCode: HttpCode | null
  onClose: () => void
}) => {
  const { search } = useLocation()
  const { formatMessage } = useIntl()

  if (!args.errorCode || !Object.values(HttpCode).includes(args.errorCode)) {
    return null
  }

  switch (args.errorCode) {
    case HttpCode.BadRequest:
      return (
        <AlertModal
          title={formatMessage({ id: "register.unableToSendCode" })}
          message={formatMessage({
            id: "register.unableToSendCodeMessage",
          })}
          onClose={args.onClose}
        />
      )
    case HttpCode.Unauthorized:
      return <Navigate to={{ pathname: routeNames.login, search }} />
    case HttpCode.PreconditionFailed:
      return (
        <AlertModal
          title={formatMessage({ id: "register.unableToSendText" })}
          message={formatMessage({
            id: "register.unableToSendTextMessage",
          })}
          onClose={args.onClose}
        />
      )
    case HttpCode.TooManyRequests:
      return (
        <AlertModal
          title={formatMessage({ id: "register.tooManyRequests" })}
          message={formatMessage({
            id: "register.tooManyRequestsMessage",
          })}
          onClose={args.onClose}
        />
      )
    default:
      return <SomethingWentWrongModal visible={true} onClose={args.onClose} />
  }
}

const PhoneMfaForm = (props: {
  children?: (args: {
    Form: React.ReactNode
    errors: FormState<MfaValues>["errors"]
    setErrors: (errors: FormState<MfaValues>["errors"]) => void
  }) => React.ReactElement
  messageConfig: {
    heading?: React.ReactNode
    description: React.ReactNode
    tryAgainDescription: React.ReactNode
    tryAgainButton: React.ReactNode
  }
  mfaType: MfaType
  onSubmit: (
    values: MfaValues,
    funcs: {
      setErrors: (errors: FormState<MfaValues>["errors"]) => void
      stop: (args: StopArgs<MfaValues>) => void
      setSubmitting: (submitting: boolean) => void
      animate: () => Promise<void>
    }
  ) => void
  resendApi: () => Promise<void>
  invokeOnMount?: boolean
  additionalMfaOptionButton?: {
    text: string
    onClick: () => void
  }
}) => {
  const { formatMessage } = useIntl()
  const [resetCodeInput, setResetCodeInput] = React.useState(false)
  const [showingSuccessStatus, setShowingSuccessStatus] = React.useState(true)
  const [errorModal, setErrorModal] = React.useState<React.ReactNode>()
  const CODE_LENGTH = 6

  const { handleChange, isSubmitting, errors, animating, setErrors } = useForm({
    validateOnChange: true,
    animationTime: CODE_INPUT_ANIMATION_TIME,
    submitListener: (values) => {
      return values.code.length === CODE_LENGTH
    },
    initialValues: { code: "" },
    validate: () => ({}),
    onSubmit: props.onSubmit,
  })

  const sendApi = useSend({
    type: props.mfaType,
    api: props.resendApi,
    tickOnMount: true,
    onError: (e) => {
      const errorCode = getErrorCode(e) as HttpCode
      setErrorModal(
        <PhoneErrorModal
          errorCode={errorCode}
          onClose={() => setErrorModal(null)}
        />
      )
    },
  })

  useDidUpdate(animating, (prevAnimating) => {
    if (!prevAnimating && animating) {
      sleep(500).then(() => {
        setShowingSuccessStatus(false)
      })
    }
  })

  let status = CodeInputStatus.Default
  if (showingSuccessStatus && animating) {
    status = CodeInputStatus.Success
  } else if (errors.submitError) {
    status = CodeInputStatus.Error
  }

  const isInitialMount = useInitialMount()
  if (isInitialMount && props.invokeOnMount && !sendApi.disabled) {
    sendApi.invoke()
  }

  useDidUpdate(props.mfaType, function mfaTypeChangedInModal(prevMfaType) {
    if (prevMfaType && prevMfaType !== props.mfaType) {
      sendApi.invoke()
    }
  })

  const Form = (
    <Container hasTitle={!!props.messageConfig.heading}>
      {errorModal}
      <div>
        {!!props.messageConfig.heading && (
          <Medium24Header4>{props.messageConfig.heading}</Medium24Header4>
        )}
        <Regular14Paragraph>
          {props.messageConfig.description}
        </Regular14Paragraph>
      </div>
      {!showingSuccessStatus && animating ? (
        <div
          css={css`
            height: 100%;
          `}
        >
          <Lottie animationData={enterCodeSuccess} play />
        </div>
      ) : (
        <>
          <div
            css={(theme) => css`
              display: flex;
              flex-direction: column;
              height: 100%;
              padding: 32px;

              [role="alert"] {
                display: block;
                color: ${theme.palette.foreground.critical};
                margin: unset;
              }
            `}
          >
            <CodeInput
              css={(theme) => css`
                display: flex;
                justify-content: space-between;
                input {
                  background-color: ${theme.palette.foreground.inverse};
                  border-color: ${theme.palette.border.default};
                  color: ${errors.submitError
                    ? theme.palette.foreground.critical
                    : theme.palette.foreground.default};
                  border-radius: 4px;
                  width: 15%;
                  height: 64px;
                  margin-inline-end: 4px;
                }
              `}
              codeLength={CODE_LENGTH}
              name="code"
              errorMessage={
                <span
                  css={css`
                    display: flex;
                    padding-top: 16px;
                    justify-content: center;
                    align-items: center;
                  `}
                >
                  <Alert
                    variant="xsmall-outlined"
                    css={css`
                      margin-inline-end: 4px;
                    `}
                  />
                  {formatMessage({
                    id: "register.invalidVerificationCode",
                  })}
                </span>
              }
              reset={resetCodeInput}
              setReset={setResetCodeInput}
              status={status}
              inputLabel={formatMessage({ id: "enterMfa.enterMFACode" })}
              onChange={handleChange}
              focusOnMount
              disabled={isSubmitting}
            />
          </div>
          <div
            css={css`
              display: flex;
              flex-direction: column;
            `}
          >
            <Medium16Body>
              {formatMessage({ id: "register.didntReceiveCode" })}
            </Medium16Body>
            <Regular14Paragraph>
              {props.messageConfig.tryAgainDescription}
            </Regular14Paragraph>
            <Button
              variant="secondary"
              height="medium"
              disabled={sendApi.disabled || isSubmitting}
              css={css`
                align-self: center;
                margin-block: 8px;
                width: 325px;
              `}
              onClick={() => {
                sendApi.invoke()
              }}
              data-automationid={
                !sendApi.disabled ? "try-again-button" : undefined
              }
            >
              {sendApi.disabled
                ? formatMessage(
                    { id: "register.sent" },
                    { time: sendApi.timer }
                  )
                : props.messageConfig.tryAgainButton}
            </Button>
            {props.additionalMfaOptionButton && (
              <Button
                variant="secondary"
                height="medium"
                disabled={sendApi.disabled || isSubmitting}
                css={css`
                  align-self: center;
                  margin-block: 8px;
                  width: 325px;
                `}
                onClick={props.additionalMfaOptionButton.onClick}
              >
                {props.additionalMfaOptionButton.text}
              </Button>
            )}
          </div>
        </>
      )}
    </Container>
  )

  return props.children
    ? props.children({
        Form,
        errors,
        setErrors,
      })
    : Form
}

export default PhoneMfaForm
