import React from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/react"
import { Medium14 } from "@myvp/shared/src/typography"

/** @deprecated Use button.tsx instead */
const DeprecatedButton = styled.button<{
  children: React.ReactNode
  variant?: "filled" | "outlined" | "link" | "text"
  color?: "primary" | "secondary" | "default" | "red"
  disabled?: boolean
}>`
  border-style: solid;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
  background-color: ${(props) => {
    if (props.disabled) {
      return props.theme.palette.steel.one
    }
    if (props.variant === "outlined") {
      return props.theme.palette.steel.zero
    }
    if (props.color === "default") {
      return props.theme.palette.steel.four
    }
    if (props.color === "red") {
      return props.theme.palette.red.dark.zero
    }
    if (props.color) {
      return props.theme.palette[props.color].one
    }
  }};
  ${(props) =>
    (props.color === "primary" || props.color === "secondary") &&
    props.variant !== "outlined" &&
    !props.disabled &&
    props.variant !== "link" &&
    props.variant !== "text" &&
    css`
      background: ${props.theme.palette[props.color].oneGradient};
      border: none;
      border-style: none;

      :hover {
        -webkit-filter: brightness(1.1);
        filter: brightness(1.1);
      }
      transition:
        transform 0.35s ease-out,
        filter 0.35s ease-out;
    `}

  ${(props) =>
    props.color === "default" &&
    props.variant === "outlined" &&
    !props.disabled &&
    css`
      transition: border 0.35s ease-out;
    `}
  
  border-color: ${(props) => {
    if (props.disabled) {
      return props.theme.palette.steel.one
    }
    if (props.color === "default") {
      return props.theme.palette.steel.four
    }
    if (props.color === "red") {
      return props.theme.palette.red.dark.zero
    }
    if (props.color) {
      return props.theme.palette[props.color].one
    }
  }};
  cursor: pointer;
  ${(props) =>
    props.disabled &&
    css`
      cursor: not-allowed;
      box-shadow: none;
      border-style: none;
    `};
  border-width: 2.16102px;
  border-radius: ${(props) => props.theme.borderRadius};
  width: 100%;
  max-width: 140px;
  height: 32px;
  stroke-opacity: 0.5;
  color: ${(props) => {
    if (props.disabled) {
      return props.theme.palette.steel.four
    }
    if (props.color === "default" && props.variant === "filled") {
      return props.theme.palette.steel.zero
    }
    if (
      props.variant === "outlined" &&
      props.color !== "default" &&
      props.color !== "red" &&
      props.color
    ) {
      return props.theme.palette[props.color].one
    }
    if (
      (props.color === "default" || props.color === "red") &&
      props.variant === "outlined"
    ) {
      return props.theme.palette.steel.five
    }
    return props.theme.palette.steel.zero
  }};
  ${Medium14.__emotion_styles};
  font-style: normal;
  :hover {
    background-color: ${(props) => {
      if (props.disabled) {
        return props.theme.palette.steel.one
      }
      if (props.variant === "outlined") {
        return props.theme.palette.steel.zero
      }
      if (props.color === "default") {
        return props.theme.palette.steel.five
      }
      if (props.color === "red") {
        return props.theme.palette.red.dark.one
      }
      if (props.color) {
        return props.theme.palette[props.color].two
      }
    }};
    border-color: ${(props) => {
      if (props.disabled) {
        return props.theme.palette.steel.one
      }
      if (props.color === "default") {
        return props.theme.palette.steel.five
      }
      if (props.color === "red") {
        return props.theme.palette.red.dark.one
      }
      if (props.color) {
        return props.theme.palette[props.color].two
      }
    }};
    color: ${(props) => {
      if (props.disabled) {
        return props.theme.palette.steel.four
      }
      if (props.color === "default" && props.variant === "filled") {
        return props.theme.palette.steel.zero
      }
      if (
        props.variant === "outlined" &&
        props.color !== "default" &&
        props.color !== "red" &&
        props.color
      ) {
        return props.theme.palette[props.color].two
      }
      if (
        (props.color === "default" || props.color === "red") &&
        props.variant === "outlined"
      ) {
        return props.theme.palette.steel.seven
      }
      return props.theme.palette.steel.zero
    }};
  }
  ${(props) =>
    props.variant === "link" &&
    css`
      width: auto;
      max-width: none;
      height: auto;
      background: none;
      box-shadow: none;
      padding: 0;
      border: none;
      color: ${props.theme.palette.foreground.link};
      :focus {
        border-radius: 0;
      }
      :hover {
        background: none;
        color: ${props.theme.palette.foreground.link};
        text-decoration: underline;
      }
      :active {
        color: ${props.theme.palette.decorative.foreground.default.blue};
      }
      ${props.disabled &&
      css`
        opacity: 0.7;
      `};
    `};

  ${(props) =>
    props.variant === "text" &&
    css`
      width: auto;
      max-width: none;
      height: auto;
      background: none;
      box-shadow: none;
      padding: 0;
      border: none;
      line-height: 24px;
      color: ${props.theme.palette.steel.eight};
      :hover {
        background: none;
        color: ${props.theme.palette.steel.eight};
      }
    `};
`

DeprecatedButton.defaultProps = {
  variant: "filled",
  color: "primary",
  disabled: false,
  children: null,
}
export default DeprecatedButton
