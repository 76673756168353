import { css } from "@emotion/react"
import styled from "@emotion/styled"
import React from "react"
import { Link } from "react-router-dom"
import {
  type CoreButtonProps,
  type IconElement,
  InternalButton,
  InternalLink,
  getButtonIconColor,
  heightStyles,
  variantStyles,
  activeCss,
} from "@myvp/shared/src/components/buttons/button-utils"
import { IconColor } from "@myvp/shared/src/styles/icon-utils"

interface IconButtonProps extends CoreButtonProps {
  icon: IconElement
  variant: "primary" | "secondary" | "tertiary"
  iconColor?: IconColor // Only used for tertiary variant
}
interface ExternalIconButtonProps
  extends Omit<React.ComponentPropsWithoutRef<"button">, "children">,
    IconButtonProps {
  internalType?: "button"
}
interface ExternalIconLinkButtonProps
  extends Omit<React.ComponentPropsWithoutRef<typeof Link>, "children">,
    IconButtonProps {
  internalType: "link"
}

export const baseStyles = () => css`
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  border-radius: 4px;
  gap: 8px;
  cursor: pointer;
`
const widthStyles = {
  small: css`
    width: 32px;
    min-width: 32px;
  `,
  medium: css`
    width: 40px;
    min-width: 40px;
  `,
  large: css`
    width: 44px;
    min-width: 44px;
  `,
}

const IconButton = React.forwardRef<
  HTMLAnchorElement | HTMLButtonElement,
  ExternalIconButtonProps | ExternalIconLinkButtonProps
>(function IconButton(props, ref) {
  const styles = [
    baseStyles(),
    widthStyles[props.height],
    heightStyles[props.height],
    variantStyles[props.variant],
    activeCss(!!props.isAnchorOpen),
  ]
  const disabled =
    "disabled" in props
      ? props.disabled
      : props["aria-disabled"] === true || props["aria-disabled"] === "true"
  const internalChildren = props.icon({
    size: "medium",
    color: getButtonIconColor({
      variant: props.variant,
      disabled,
      iconColor: props.iconColor,
    }),
  })

  if (props.internalType === "link") {
    const {
      internalType,
      variant,
      height,
      icon,
      iconColor,
      isAnchorOpen,
      ...linkProps
    } = props
    const linkRef = ref as React.ForwardedRef<HTMLAnchorElement>
    return (
      <InternalLink ref={linkRef} {...linkProps} css={styles}>
        {internalChildren}
      </InternalLink>
    )
  } else {
    const {
      internalType,
      variant,
      height,
      icon,
      iconColor,
      isAnchorOpen,
      ...buttonProps
    } = props
    const buttonRef = ref as React.ForwardedRef<HTMLButtonElement>
    return (
      <InternalButton ref={buttonRef} {...buttonProps} css={styles}>
        {internalChildren}
      </InternalButton>
    )
  }
})

export default styled(IconButton)``
