// Adapted from https://github.com/CharlesStover/use-force-update/blob/f509462bc61a53bbda210018f32399a29269bd98/src/use-force-update.ts
import React from "react"

// Returning a new object reference guarantees that a before-and-after
// equivalence check will always be false, resulting in a re-render, even
// when multiple calls to forceUpdate are batched.

const useForceUpdate = () => {
  const [, dispatch] = React.useState(Object.create(null))

  // Turn dispatch(required_parameter) into dispatch().
  const memoizedDispatch = React.useCallback(() => {
    dispatch(Object.create(null))
  }, [dispatch])
  return memoizedDispatch
}

export default useForceUpdate
