import React from "react"
import usePrevious from "@myvp/shared/src/hooks/use-previous"
import useInitialMount from "@myvp/shared/src/hooks/use-initial-mount"
import useRefCallback from "@myvp/shared/src/hooks/use-ref-callback"
import { dequal } from "dequal/lite"

const useDidUpdate = <T>(
  current: T,
  callback: (prev: T, current: T) => void,
  {
    runOnInitialMount = false,
    active = true,
  }: {
    runOnInitialMount?: boolean
    active?: boolean
  } = {}
) => {
  const refCallback = useRefCallback(callback)
  const prev = usePrevious(current)
  const isInitialMount = useInitialMount()
  React.useEffect(() => {
    if (
      active &&
      (runOnInitialMount || !isInitialMount) &&
      !dequal(prev, current)
    ) {
      refCallback.current?.(prev, current)
    }
  }, [isInitialMount, current, prev, runOnInitialMount, active])
}

export default useDidUpdate
