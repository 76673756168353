import React from "react"
import { Link as RRLink } from "react-router-dom"
import {
  InternalButton,
  InternalLink,
} from "@myvp/shared/src/components/buttons/button-utils"
import { css } from "@emotion/react"
import { Theme } from "@myvp/shared/src/styles/theme"

interface ExternalLinkProps extends React.ComponentPropsWithoutRef<"button"> {
  internalType: "button"
}
interface ExternalLinkButtonProps
  extends React.ComponentPropsWithoutRef<typeof RRLink> {
  internalType?: "link"
}

const baseStyles = (theme: Theme) => css`
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  gap: 8px;
  cursor: pointer;
  color: ${theme.palette.foreground.link};
  background: none;
  border: none;
  padding: 0;

  :hover {
    text-decoration: underline;
  }

  :active {
    color: ${theme.palette.decorative.foreground.default.blue};
    text-decoration: underline;
  }

  :disabled,
  &[aria-disabled="true"] {
    cursor: not-allowed;
    color: ${theme.palette.foreground.disabled};
    text-decoration: none;
  }
`

const Link = React.forwardRef<
  HTMLAnchorElement | HTMLButtonElement,
  ExternalLinkProps | ExternalLinkButtonProps
>(function Button(props, ref) {
  if (props.internalType === "button") {
    const { internalType, ...buttonProps } = props
    const buttonRef = ref as React.ForwardedRef<HTMLButtonElement>
    return (
      <InternalButton
        ref={buttonRef}
        {...buttonProps}
        css={(theme) => baseStyles(theme)}
      >
        {props.children}
      </InternalButton>
    )
  } else {
    const { internalType, ...linkProps } = props
    const linkRef = ref as React.ForwardedRef<HTMLAnchorElement>
    return (
      <InternalLink
        ref={linkRef}
        {...linkProps}
        css={(theme) => baseStyles(theme)}
      >
        {props.children}
      </InternalLink>
    )
  }
})

export default Link
