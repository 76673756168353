import React from "react"
import { createPortal, flushSync } from "react-dom"
import { css, type Theme } from "@emotion/react"
import { Medium14 } from "@myvp/shared/src/typography"
import AlertIcon from "@myvp/shared/src/icons/deprecated-alert"
import Warning from "@myvp/shared/src/icons/warning"
import CheckCircle from "@myvp/shared/src/icons/check-circle"
import useRefCallback from "@myvp/shared/src/hooks/use-ref-callback"
import { sleep } from "@myvp/shared/src/functions/sleep"

const maximumAlertCount = 6
const getAlertKey = (() => {
  let alertKey = 1
  return () => String(alertKey++)
})()

const rowGap = 16
const alertListCss = css`
  position: absolute;
  left: 0px;
  right: 0px;
  top: 60px;
  padding-inline: 16px;
  display: grid;
  grid-template-columns: minmax(0px, 480px);
  justify-content: center;
  pointer-events: none;
  row-gap: ${rowGap}px;
`
export const AlertList = (props: {
  className?: string
  /**
   * The default alert time (in ms) to show an alert.
   */
  defaultAlertTimeout?: number
  children?: React.ReactNode
}) => {
  interface AlertItem {
    key: string
    element: React.ReactNode
  }
  interface AlertItemState {
    key: string
    timeout: number
    state: "entering" | "active" | "exiting"
    element: HTMLDivElement | null
  }
  const itemQueueRef = React.useRef<AlertItem[]>([])
  const [alerts, setAlerts] = React.useState<AlertItem[]>([])
  const [rootElement] = React.useState(() => {
    return document.getElementById("modal-root")
  })
  const alertRefs = React.useRef<AlertItemState[]>([])

  const setItemRef = React.useCallback(
    (element: HTMLDivElement | null, index: number) => {
      const alertItemState = alertRefs.current[index]
      if (alertItemState) {
        alertItemState.element = element
      }
    },
    []
  )

  const defaultAlertTimeout = props.defaultAlertTimeout ?? 5_000
  const requestAnimation = useBufferedCallback(async () => {
    const exitingAlerts = alertRefs.current.filter(
      (alert) => alert.state === "exiting"
    )
    const item =
      alertRefs.current.length - exitingAlerts.length >= maximumAlertCount
        ? undefined
        : itemQueueRef.current.shift()
    const keysToRemove: ReadonlySet<string> = new Set(
      exitingAlerts.map((alert) => alert.key)
    )
    // no items are being added or removed, animation not necessary
    if (!item && keysToRemove.size === 0) {
      return
    }

    // add state to track alert that will be added
    const addedAlertState: AlertItemState | null = item
      ? {
          key: item.key,
          timeout: defaultAlertTimeout,
          state: "entering",
          element: null,
        }
      : null
    if (addedAlertState) {
      alertRefs.current.unshift(addedAlertState)
    }

    const animationKeyframes = alertRefs.current.map((itemState) => {
      // eslint-disable-next-line no-undef
      const itemKeyframes: PropertyIndexedKeyframes = {}
      if (itemState.state === "entering") {
        itemKeyframes.opacity = [0, 1]
      } else if (itemState.state === "exiting") {
        itemKeyframes.opacity = [1, 0]
      }
      return itemKeyframes
    })

    if (item && addedAlertState) {
      // record positions to start the F.L.I.P. animation from
      const prevPositions = alertRefs.current.map(
        (item) => item.element?.getBoundingClientRect().y ?? 0
      )
      // flush DOM changes immediately
      flushSync(() => {
        setAlerts((items) => [item, ...items])
      })
      // set the previous position for the first element based on it's size
      if (addedAlertState.element) {
        const pos = addedAlertState.element.getBoundingClientRect()
        prevPositions[0] = pos.y - pos.height - rowGap
      }
      // setup transforms to play the F.L.I.P. animation
      for (const [index, prevPosition] of prevPositions.entries()) {
        const element = alertRefs.current[index].element
        if (element) {
          const yDelta = prevPosition - element.getBoundingClientRect().y
          animationKeyframes[index].transform = [
            `translate(0px, ${yDelta}px)`,
            "",
          ]
        }
      }
    }

    await Promise.all(
      alertRefs.current.map((alert, index) => {
        if (!alert.element || process.env.NODE_ENV === "test") {
          return Promise.resolve()
        }
        const animation = alert.element.animate(animationKeyframes[index], {
          duration: 250,
          easing: "linear",
        })
        return animation.finished
      })
    )

    if (addedAlertState) {
      const alertState = addedAlertState
      alertState.state = "active"
      setTimeout(() => {
        alertState.state = "exiting"
        requestAnimation()
      }, alertState.timeout)
    }
    if (keysToRemove.size) {
      alertRefs.current = alertRefs.current.filter(
        (item) => !keysToRemove.has(item.key)
      )
      flushSync(() => {
        setAlerts((items) =>
          items.filter((alert) => !keysToRemove.has(alert.key))
        )
      })
    }
  })

  const contextValue: React.ContextType<typeof AlertListContext> =
    React.useMemo(
      () => ({
        enqueueAlert: (content) => {
          const alertHandle = getAlertKey()
          let element: React.ReactNode
          if ("element" in content) {
            element = content.element
          } else {
            const { text, ...alertProps } = content
            element = <Alert {...alertProps}>{text}</Alert>
          }
          itemQueueRef.current.push({
            key: alertHandle,
            element,
          })
          requestAnimation()
          return { alertHandle }
        },
        dismissAlert: (alertHandle) => {
          const queueIndex = itemQueueRef.current.findIndex(
            (item) => item.key === alertHandle
          )
          if (queueIndex >= 0) {
            itemQueueRef.current.splice(queueIndex, 1)
            return
          }
          const animationState = alertRefs.current.find(
            (alert) => alert.key === alertHandle
          )
          if (animationState) {
            animationState.state = "exiting"
            requestAnimation()
          }
        },
      }),
      [requestAnimation]
    )

  if (!rootElement) {
    return null
  }

  return (
    <AlertListContext.Provider value={contextValue}>
      {createPortal(
        <div className={props.className} css={alertListCss}>
          {alerts.map((item, index) => (
            <ItemContainer key={item.key} index={index} setItemRef={setItemRef}>
              {item.element}
            </ItemContainer>
          ))}
        </div>,
        rootElement
      )}
      {props.children}
    </AlertListContext.Provider>
  )
}

const ItemContainer = (props: {
  index: number
  setItemRef: (element: HTMLDivElement | null, index: number) => void
  children?: React.ReactNode
}) => {
  const setItemRef: React.RefCallback<HTMLDivElement> = React.useCallback(
    (element) => {
      const setItemRef = props.setItemRef
      setItemRef(element, props.index)
    },
    [props.index, props.setItemRef]
  )
  return <div ref={setItemRef}>{props.children}</div>
}

const alertCss = css`
  pointer-events: all;
  display: flex;
  min-height: 40px;
  padding: 8px;
  column-gap: 8px;

  border-radius: 4px;
  box-shadow: 0 4px 12px 0px #e9ecf1;
`
const alertVariants = {
  warn: {
    icon: <Warning variant="default-medium" />,
    css: (theme: Theme) => css`
      background-color: ${theme.palette.surface.warning.default};
      color: ${theme.palette.decorative.foreground.default.yellow};
    `,
  },
  error: {
    icon: <AlertIcon variant="medium" />,
    css: (theme: Theme) => css`
      background-color: ${theme.palette.surface.critical.default};
      color: ${theme.palette.decorative.foreground.default.red};
    `,
  },
  success: {
    icon: <CheckCircle variant="medium-success" />,
    css: (theme: Theme) => css`
      background-color: ${theme.palette.surface.success.default};
      color: ${theme.palette.decorative.foreground.default.green};
    `,
  },
} as const
export const Alert = (props: {
  variant?: keyof typeof alertVariants
  children?: React.ReactNode
}) => {
  const Typography = Medium14
  const variant = alertVariants[props.variant ?? "error"]
  return (
    <div css={[alertCss, variant.css]} role="alert">
      {variant.icon}
      <div
        css={css`
          align-self: center;
          overflow-wrap: anywhere;
        `}
      >
        <Typography>{props.children}</Typography>
      </div>
    </div>
  )
}

const AlertListContext = React.createContext<{
  enqueueAlert(
    content:
      | { element: React.ReactNode }
      | (Omit<React.ComponentPropsWithoutRef<typeof Alert>, "children"> & {
          text: React.ReactNode
        })
  ): { alertHandle: string }
  dismissAlert(alertHandle: string): void
} | null>(null)

export const useAlertList = () => {
  const contextValue = React.useContext(AlertListContext)
  if (!contextValue) {
    throw new Error("Missing AlertList component")
  }
  return contextValue
}

const useBufferedCallback = (callback: () => Promise<void>) => {
  const stateRef = React.useRef({
    requested: false,
    running: false,
  })
  const callbackRef = useRefCallback(callback)

  const bufferedCallback = React.useCallback(async (): Promise<void> => {
    // don't schedule another callback call if one is already running or requested
    if (stateRef.current.running || stateRef.current.requested) {
      // if a callback is running, make sure we schedule it again after it's done
      stateRef.current.requested = true
      return
    }

    // wait for one tick to batch up calls
    await sleep(0)
    stateRef.current.running = true
    stateRef.current.requested = false
    try {
      await callbackRef.current()
    } finally {
      stateRef.current.running = false
      // don't schedule another call if requested is still false after the promise returns
      if (stateRef.current.requested) {
        stateRef.current.requested = false
        // call bufferedCallback in a microtask to:
        // - avoid creating a long stack
        // - avoid other calls scheduling a callback call before this does
        queueMicrotask(bufferedCallback)
      }
    }
  }, [])
  return bufferedCallback
}
