import React from "react"
import { sleep } from "@myvp/shared/src/functions/sleep"
import { createReducer } from "@myvp/shared/src/functions/create-reducer"
import { setState } from "@myvp/shared/src/functions/set-state"
import useRefCallback from "@myvp/shared/src/hooks/use-ref-callback"
import { CustomEvent } from "@myvp/shared/src/types"
import { isEmptyObject } from "@myvp/shared/src/functions/is-empty-object"
import { isDefined } from "@myvp/shared/src/functions/type-guards"

export interface FormState<ValueType> {
  values: ValueType
  errors: {
    validationErrors?: { [key in keyof ValueType]?: any }
    submitError?: string | React.ReactNode
  }
  isSubmitting: boolean
  animating: boolean
  hasChanges: boolean
  hasSubmitted: boolean
  blurred: { [key in keyof ValueType]?: boolean }
}

export type StopArgs<ValueType> = Partial<
  Pick<
    FormState<ValueType>,
    "errors" | "isSubmitting" | "hasChanges" | "blurred"
  >
>

type UseFormProps<ValueType> = {
  initialErrors?:
    | FormState<ValueType>["errors"]
    | ((initialValues: ValueType) => FormState<ValueType>["errors"])
  hasInitialChanges?: boolean
  submitListener?: (values: ValueType) => boolean
  isValidateValid?: (
    validationResult: FormState<ValueType>["errors"]
  ) => boolean
  onChange?: (values: ValueType) => void
  validateOnChange?: boolean
  validateOnBlur?: boolean
  animationTime?: number
  validate?: (
    values: ValueType,
    args: {
      type: "change" | "blur" | "submit"
      currentErrors: FormState<ValueType>["errors"]
      blurred: FormState<ValueType>["blurred"]
    }
  ) => FormState<ValueType>["errors"] | Promise<FormState<ValueType>["errors"]>
  onSubmit?: (
    values: ValueType,
    args: {
      setSubmitting: (submitting: boolean) => void
      setErrors: (errors: FormState<ValueType>["errors"]) => void
      stop: (args?: StopArgs<ValueType>) => void
      animate: () => Promise<void>
    }
  ) => void | Promise<void>
} & (
  | {
      storageKey?: undefined
      initialValues: ValueType | (() => ValueType)
      storage?: undefined
    }
  | {
      storageKey: string
      initialValues: ValueType | ((storedValues: ValueType | null) => ValueType)
      storage?: Storage
      clearStorageOnSubmit?: boolean
    }
)

interface Action<ValueType> {
  type:
    | "SET_VALUES"
    | "SET_ERRORS"
    | "SET_PARTIAL_ERRORS"
    | "START"
    | "SET_SUBMITTING"
    | "SET_VALIDATION_ERRORS"
    | "STOP"
    | "SET_BLUR"
    | "HAS_SUBMITTED"
    | "SET_ANIMATING"
  values?: ValueType
  errors?: FormState<ValueType>["errors"]
  blurred?: FormState<ValueType>["blurred"]
  isSubmitting?: boolean
  hasChanges?: boolean
  animating?: boolean
}

const isValidationErrorValuesFalsy = <ValueType>(
  object: FormState<ValueType>["errors"]
) =>
  isEmptyObject(object) ||
  Object.values(object?.validationErrors as object).every((value) => !value)

/**
 * Copied the API from https://formik.org/, we had gotten approval from legal and technical
 * to use formik, but then after Peter's email we decided to not opt in for it.
 * This is a lighter weight solution, and only implements the features that we need so far.
 */
const useForm = <ValueType extends object>({
  initialErrors,
  hasInitialChanges = true,
  isValidateValid = isValidationErrorValuesFalsy,
  validate,
  onSubmit,
  submitListener,
  onChange = () => {},
  validateOnChange = false,
  validateOnBlur = false,
  animationTime = 0,
  ...args
}: UseFormProps<ValueType>) => {
  const storage = args.storage ?? sessionStorage

  const validateRef = useRefCallback(validate)
  const isValidateValidRef = useRefCallback(isValidateValid)
  const onSubmitRef = useRefCallback(onSubmit)
  const submitListenerRef = useRefCallback(submitListener)
  const onChangeRef = useRefCallback(onChange)

  const getInitialValues = (): ValueType => {
    if (isStorageArgs(args)) {
      const storedText = storage.getItem(args.storageKey)
      if (typeof args.initialValues === "function") {
        try {
          return args.initialValues(
            storedText ? tryJsonParse(storedText) : null
          )
        } catch {
          // if this caught, then the _initialValues function broke, therefore just use an empty object
          return {} as ValueType
        }
      } else {
        return storedText
          ? tryJsonParse(storedText) ?? args.initialValues
          : args.initialValues
      }
    } else if (typeof args.initialValues === "function") {
      return args.initialValues()
    } else {
      return args.initialValues
    }
  }

  const reducer = createReducer<FormState<ValueType>>({
    SET_VALUES: (state: FormState<ValueType>, action: Action<ValueType>) => ({
      ...state,
      values: action.values,
      hasChanges: true,
    }),
    SET_ERRORS: (state: FormState<ValueType>, action: Action<ValueType>) => ({
      ...state,
      errors: action.errors,
    }),
    SET_PARTIAL_ERRORS: (
      state: FormState<ValueType>,
      action: Action<ValueType>
    ) => ({
      ...state,
      errors: {
        ...state.errors,
        validationErrors: {
          ...state.errors?.validationErrors,
          ...action.errors?.validationErrors,
        },
      },
    }),
    START: (state: FormState<ValueType>) => ({
      ...state,
      isSubmitting: true,
      errors: {},
      hasChanges: false,
    }),
    SET_SUBMITTING: setState,
    SET_VALIDATION_ERRORS: (
      state: FormState<ValueType>,
      action: Action<ValueType>
    ) => ({
      ...state,
      errors: action.errors,
      hasChanges: false,
    }),
    STOP: (state: FormState<ValueType>, action: Action<ValueType>) => ({
      ...state,
      blurred: action.blurred || state.blurred,
      errors: action.errors,
      isSubmitting: action.isSubmitting,
      hasChanges: action.hasChanges,
    }),
    SET_BLUR: (state: FormState<ValueType>, action: Action<ValueType>) => ({
      ...state,
      errors: action.errors ? action.errors : state.errors,
      blurred: action.blurred,
    }),
    HAS_SUBMITTED: (state: FormState<ValueType>) => ({
      ...state,
      hasSubmitted: true,
    }),
    SET_ANIMATING: setState,
  })

  const [state, dispatch] = React.useReducer(
    reducer as React.Reducer<FormState<ValueType>, Action<ValueType>>,
    undefined,
    () => {
      const initialValues = getInitialValues()
      let errors: FormState<ValueType>["errors"]
      if (!isDefined(initialErrors)) {
        errors = {}
      } else if (typeof initialErrors === "function") {
        errors = initialErrors(initialValues)
      } else {
        errors = initialErrors
      }
      return {
        values: initialValues,
        errors,
        isSubmitting: false,
        animating: false,
        hasChanges: hasInitialChanges
          ? Object.values(initialValues).reduce((accum, value) => {
              if (value) {
                return true
              }
              return accum
            }, false)
          : false,
        hasSubmitted: false,
        blurred: {},
      }
    }
  )

  const animate = React.useCallback(async () => {
    dispatch({ type: "SET_ANIMATING", animating: true })
    await sleep(animationTime)
    dispatch({ type: "SET_ANIMATING", animating: false })
  }, [animationTime])
  const setBlur = React.useCallback(
    (
      errors: FormState<ValueType>["errors"] | undefined,
      blurred: FormState<ValueType>["blurred"]
    ) => {
      dispatch({ type: "SET_BLUR", errors, blurred })
    },
    []
  )
  const setErrors = React.useCallback(
    (errors: FormState<ValueType>["errors"]) => {
      dispatch({ type: "SET_ERRORS", errors })
    },
    []
  )

  const setPartialErrors = React.useCallback(
    (errors: FormState<ValueType>["errors"]) => {
      dispatch({ type: "SET_PARTIAL_ERRORS", errors })
    },
    []
  )
  const setSubmitting = React.useCallback((isSubmitting: boolean) => {
    dispatch({ type: "SET_SUBMITTING", isSubmitting })
  }, [])

  const start = React.useCallback(() => {
    dispatch({ type: "START" })
  }, [])
  const setValidationErrors = React.useCallback(
    (errors: FormState<ValueType>["errors"]) => {
      dispatch({ type: "SET_VALIDATION_ERRORS", errors })
    },
    []
  )
  const stop = React.useCallback(
    ({
      errors = {},
      isSubmitting = false,
      hasChanges = false,
      blurred,
    }: StopArgs<ValueType> = {}) => {
      dispatch({ type: "STOP", errors, isSubmitting, hasChanges, blurred })
    },
    []
  )
  const clearStorageOnSubmit = isStorageArgs(args) && args.clearStorageOnSubmit
  const handleSubmit = React.useCallback(
    async (event?: CustomEvent, values = state.values) => {
      event?.preventDefault?.()
      dispatch({ type: "HAS_SUBMITTED" })
      const validationResult =
        (await validateRef.current?.(values, {
          type: "submit",
          currentErrors: state.errors,
          blurred: state.blurred,
        })) ?? {}
      if (isValidateValidRef.current(validationResult)) {
        start()
        await onSubmitRef.current?.(values, {
          setSubmitting,
          setErrors,
          stop,
          animate,
        })
      } else {
        setValidationErrors(validationResult)
      }

      if (clearStorageOnSubmit) {
        storage.removeItem(args.storageKey)
      }
    },
    [
      animate,
      setErrors,
      setSubmitting,
      setValidationErrors,
      start,
      state.blurred,
      state.errors,
      state.values,
      stop,
      storage,
      clearStorageOnSubmit,
      args.storageKey,
    ]
  )
  const setValues = React.useCallback(
    async (values: ValueType) => {
      onChangeRef.current?.(values)

      dispatch({ type: "SET_VALUES", values })
      if (validateOnChange) {
        const validate = validateRef.current
        const result = await (validate as NonNullable<typeof validate>)(
          values,
          {
            type: "change",
            currentErrors: state.errors,
            blurred: state.blurred,
          }
        )
        setErrors(result)
      }
      if (submitListenerRef.current?.(values)) {
        handleSubmit({ type: "submit" }, values)
      }
      if (args.storageKey) {
        storage.setItem(args.storageKey, JSON.stringify(values))
      }
    },
    [
      handleSubmit,
      setErrors,
      state.blurred,
      state.errors,
      storage,
      args.storageKey,
      validateOnChange,
    ]
  )
  const handleChange = React.useCallback(
    async (...events: CustomEvent[]) => {
      let values: ValueType = { ...state.values }
      events.forEach((event) => {
        const targetElement = event.target as HTMLInputElement
        values[targetElement.name as keyof ValueType] =
          targetElement.type === "checkbox"
            ? (targetElement.checked as ValueType[keyof ValueType])
            : (targetElement.value as ValueType[keyof ValueType])
      })
      await setValues(values)
    },
    [setValues, state.values]
  )

  const handleReset = async (event: CustomEvent) => {
    event.preventDefault?.()
    const initialValues = getInitialValues()
    if (validateOnChange && validateRef.current) {
      const result = await validateRef.current(initialValues, {
        type: "change",
        currentErrors: state.errors,
        blurred: state.blurred,
      })
      setErrors(result)
    }
    setValues(initialValues)
  }

  const handleBlur = async (event: CustomEvent) => {
    const blurred = {
      ...state.blurred,
      [(event.target as HTMLInputElement).name]: true,
    }
    let errors = validateOnBlur
      ? await validateRef.current?.(state.values, {
          type: "blur",
          currentErrors: state.errors,
          blurred,
        })
      : undefined

    setBlur(errors, blurred)
  }
  return {
    hasSubmitted: state.hasSubmitted,
    values: state.values,
    hasChanges: state.hasChanges,
    handleBlur,
    blurred: state.blurred,
    handleChange,
    handleSubmit,
    isSubmitting: state.isSubmitting,
    errors: state.errors,
    animating: state.animating,
    setErrors,
    setPartialErrors,
    animate,
    handleReset,
    setValues,
  }
}

export default useForm

/**
 * This typeguard is a workaround for downstream code re-typechecking this file with
 * `strictNullChecks: false` (undefined isn't part of that type system, so narrowing with it doesn't work).
 */
const isStorageArgs = (args: {
  storageKey?: string
}): args is { storageKey: string } => {
  return typeof args.storageKey === "string"
}

const tryJsonParse = (json: string) => {
  try {
    return JSON.parse(json)
  } catch {
    return null
  }
}
