import languagesJson from "@myvp/shared/src/i18n/languages.json"
import createResolveSupportedLocale from "@myvp/shared/src/i18n/resolve-supported-locale"

type Locale = Readonly<(typeof languagesJson)["languages"][number]>
export const LOCALES: readonly Locale[] = languagesJson.languages

export const SUPPORTED_LOCALES: Readonly<(typeof languagesJson)["shorthands"]> =
  languagesJson.shorthands

export const getLocaleFromDisplayName = (displayName: string) =>
  LOCALES.find((locale) => locale.displayName === displayName)?.tag

export const resolveSupportedLocale = createResolveSupportedLocale({
  languages: LOCALES,
  shorthands: SUPPORTED_LOCALES,
})
