/**
 * Taken and modified from redactor - returns "edge" for Edge browser, "ie11"
 * for IE11, and false if we are using a modern browser
 * Note - UA updated for edge to be Edg not Edge so this is slightly wrong in Redactor
 *
 * @returns "edge" for edge, "ie11" for IE11, false for modern browsers
 */
export const isIEOrEdge = (userAgent = navigator.userAgent) => {
  if (/Edg/.test(userAgent)) {
    return "edge"
  }

  let isIE = RegExp("msie" + ("\\s" + 11), "i").test(userAgent)

  if (!isIE) {
    isIE = !!userAgent.match(/Trident.*rv[ :]*11\./)
  }

  return isIE ? "ie11" : "none"
}
