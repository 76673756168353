/**
 * Type guard to determine if the value is not undefined and not null.
 *
 * Useful for narrowing an array type when using `.filter`.
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export const isDefined = <T>(value: T): value is T & {} => {
  return value !== undefined && value !== null
}

/**
 * Type guard to determine if a string value is in a typescript string enum.
 *
 * @example
 * ```ts
 * enum MyEnum {
 *   A = "abc",
 *   B = "bac",
 *   C = "cab",
 * }
 * {
 *   const value = "abc"
 *   if (isStringEnumValue(MyEnum)(value)) {
 *     value // this will have type `MyEnum.A`
 *   }
 * }
 * function myFn(value: string) {
 *   if (isStringEnumValue(MyEnum)(value)) {
 *     value // this will have type `MyEnum`
 *   }
 * }
 * ```
 */
export const isStringEnumValue =
  <T extends string>(enumType: Record<string, T>) =>
  (value: unknown): value is T => {
    return Object.values(enumType).includes(value as T)
  }
