import React from "react"
import styled from "@emotion/styled"
import breakpoints from "@myvp/shared/src/styles/breakpoints"
import FocusLock from "@myvp/shared/src/components/focus-lock"
import { css } from "@emotion/react"
import Times from "@myvp/shared/src/icons/times"
import IconButton from "@myvp/shared/src/components/buttons/icon-button"
import { KEYS } from "@myvp/shared/src/constants"
import useEventListener from "@myvp/shared/src/hooks/use-event-listener"
import { sleep } from "@myvp/shared/src/functions/sleep"
import useMediaQuery from "@myvp/shared/src/hooks/use-media-query"
import useMergeRefs from "@myvp/shared/src/hooks/use-merge-refs"
import { Medium16Heading } from "@myvp/shared/src/typography"
import { isEmptyObject } from "@myvp/shared/src/functions/is-empty-object"
import { useOnClickOutside } from "@myvp/shared/src/hooks/use-on-click-outside"

const Title = styled.div<{ hasTitle: boolean; closeTitle: boolean }>`
  display: ${(props) => (props.hasTitle ? "flex" : "none")};
  color: ${(props) => props.theme.palette.steel.eight};
  font-weight: bold;
  width: 100%;
  align-items: ${(props) => (props.closeTitle ? "center" : "flex-start")};
  justify-content: space-between;
  h2 {
    font-size: 13px;
    line-height: 16px;
    margin: 0;
    padding: 0;
  }
`
const IconContainer = styled.div`
  padding-inline: 4px 28px;
`
const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`

export const popupContentPaddingBlock = 24

const Div = styled.div`
  outline: none;
  position: absolute;
  border: ${(props) => props.theme.border};
  box-sizing: border-box;
  box-shadow: ${(props) => props.theme.boxShadow};
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  background: ${(props) => props.theme.palette.steel.zero};
  padding: ${popupContentPaddingBlock}px;
  ${breakpoints("sm")} {
    padding: 10px;
  }
`
const Popup = React.forwardRef<HTMLDivElement, PopupProps>(function InnerPopup(
  {
    visible,
    close,
    title,
    titleTypography,
    icon,
    children,
    closeTitle,
    clearOnClickOutside,
    ignoreOnClickOutside,
    titlePosition,
    focusOnMount,
    enableFocusTrap,
    hideClose,
    disableClose,
    anchorEl,
    anchorOrigin,
    focusRef,
    noAnchorOnMobile,
    buttonElement,
    topOffset,
    contentStyle,
    ...props
  },
  forwardedRef
) {
  const ref = React.useRef<HTMLInputElement>(null)
  const isXs = useMediaQuery({
    size: "xs",
    callback: () => null,
    dimension: "width",
  })
  React.useEffect(() => {
    if (visible && ref && ref.current && focusOnMount) {
      ref.current.focus()
    }
  }, [visible, ref, focusOnMount])
  const [style, setStyle] = React.useState(props.style)
  const removeAnchor = noAnchorOnMobile && isXs
  const updateStyle = React.useCallback(async () => {
    if (anchorEl && !removeAnchor && ref.current) {
      // We want getBoundingClientRect to be calculated after the popup has painted otherwise the positioning is not correct, sleep(0) does not work here
      await Promise.resolve()
      const r = anchorEl.getBoundingClientRect()
      let top = undefined
      let bottom = undefined
      let right = undefined
      let left = undefined
      if (anchorOrigin?.horizontal === "left") {
        /*
                  [  A  ]  -- { right: 240px }
            [     B     ] -- { offset width: 80px }
            [     B     ]
            [     B     ]
            [     B     ]
        
        Take A's right position, then subtract the popup's offset width
        If the offset with was not subtracted, then it'd look like this:
        
                  [  A  ]  -- { right: 240px }
                        [     B     ] -- { offset width: 80px }
                        [     B     ]
                        [     B     ]
                        [     B     ]
         */
        // Add window.scrollX to account for ipad/iphones misplacing the popup when zoomed in
        left = r.right - ref.current.offsetWidth + window.scrollX
      } else if (anchorOrigin?.horizontal === "right") {
        /*
                    [  A  ]  -- { left: 200px
                    [     B     ]
                    [     B     ]
                    [     B     ]
                    [     B     ]
        */
        // Add window.scrollX to account for ipad/iphones misplacing the popup when zoomed in
        left = r.left + window.scrollX
      }
      if (anchorOrigin?.vertical === "top") {
        /*
            [     B     ] -- { offset width: 80px }
            [     B     ]
            [     B     ]
            [     B     ]
                  [  A  ]  -- { top: 120px }
            
        Take A's top position, and subtract the offsetHeight of the popup.
        Without the offset height alignment, it'd look like this:
        
                        [   B[  A  ]]
                        [     B     ]
                        [     B     ]
                        [     B     ]
          A is now under B
         */
        top = r.top - ref.current.offsetHeight
      } else if (anchorOrigin?.vertical === "bottom") {
        /*
                  [  A  ]  -- { top: 120px }
            [     B     ] -- { offset width: 80px }
            [     B     ]
            [     B     ]
            [     B     ]
         */
        top = r.bottom
      }
      setStyle({
        position: "fixed",
        bottom,
        top: (top || 0) + (topOffset || 0),
        left,
        right,
      })
    } else {
      setStyle(props.style)
    }
  }, [
    anchorEl,
    anchorOrigin?.horizontal,
    anchorOrigin?.vertical,
    removeAnchor,
    props.style,
    topOffset,
  ])
  React.useLayoutEffect(() => {
    updateStyle()
  }, [anchorEl, updateStyle])
  useEventListener("resize", updateStyle, { active: !!anchorEl })
  useEventListener("scroll", updateStyle, { active: !!anchorEl })
  const closeWithFocus = React.useCallback(async () => {
    close?.()
    if (focusRef) {
      // Make sure that the popup is now closed.
      await sleep(0)

      if ("focus" in focusRef) {
        // Instead of passing a react ref, we can pass an HTML element.
        // This is helpful when binding the focusRef to event.currentTarget
        focusRef.focus()
      } else if (focusRef.current) {
        focusRef.current.focus()
      }
    }
  }, [close, focusRef])

  const mergedRef = useMergeRefs([ref, forwardedRef])

  const TitleTypography = (titleTypography ?? Medium16Heading) || React.Fragment

  const clickOutsideProps = useOnClickOutside(
    (e) => {
      if (
        visible &&
        e.target !== anchorEl &&
        e.target !== buttonElement &&
        !anchorEl?.contains(e.target as Node) &&
        !buttonElement?.contains(e.target as Node) &&
        !ignoreOnClickOutside?.current?.contains(e.target as Node)
      ) {
        close?.()
      }
    },
    { active: clearOnClickOutside }
  )

  if (!visible) {
    return null
  }
  return (
    <div {...clickOutsideProps}>
      <FocusLock
        disabled={!enableFocusTrap}
        noFocusGuards
        shards={[
          ...(anchorEl ? [anchorEl] : []),
          ...(buttonElement ? [buttonElement] : []),
        ]}
      >
        {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions, styled-components-a11y/no-noninteractive-element-interactions  */}
        <Div
          id={props.focusLockModalId}
          tabIndex={-1}
          aria-labelledby={props["aria-labelledby"]}
          {...props}
          style={(() => {
            if (
              !removeAnchor &&
              process.env.NODE_ENV !== "test" &&
              anchorEl &&
              isEmptyObject(style as object)
            ) {
              // Hide the popup until it is anchored
              return {
                visibility: "hidden",
              }
            }
            return style
          })()}
          ref={mergedRef}
          role="dialog"
          aria-modal="true"
          onKeyUp={(e) => {
            if (e.key === KEYS.escape) {
              closeWithFocus()
            }
          }}
        >
          {icon && <IconContainer>{icon}</IconContainer>}
          <Content style={contentStyle}>
            {title && (
              <Title
                id="title-container"
                hasTitle={!!title}
                closeTitle={Boolean(closeTitle)}
              >
                {titlePosition === "center" && <div style={{ width: 35 }} />}
                <div>
                  {(() => {
                    if (props.deprecatedHeading) {
                      return <h2 id={props["aria-labelledby"]}>{title}</h2>
                    } else if (typeof title === "string") {
                      return (
                        <TitleTypography id={props["aria-labelledby"]}>
                          {title}
                        </TitleTypography>
                      )
                    } else {
                      return title
                    }
                  })()}
                  {}
                </div>
                {closeTitle && (
                  <IconButton
                    css={[
                      css`
                        visibility: visible;
                      `,
                      hideClose
                        ? css`
                            visibility: hidden;
                          `
                        : null,
                    ]}
                    icon={Times}
                    variant="tertiary"
                    iconColor="default"
                    height="small"
                    title={closeTitle}
                    onClick={() => {
                      closeWithFocus()
                    }}
                    disabled={disableClose}
                  />
                )}
              </Title>
            )}
            {typeof children === "function"
              ? children({ closeWithFocus })
              : children}
          </Content>
        </Div>
      </FocusLock>
    </div>
  )
})

export interface PopupProps {
  className?: string
  hideClose?: boolean
  disableClose?: boolean
  enableFocusTrap?: boolean
  closeTitle?: string
  title?: string | React.ReactNode
  visible: boolean | undefined
  close?: () => void
  clearOnClickOutside?: boolean
  ignoreOnClickOutside?: React.MutableRefObject<HTMLElement | null>
  buttonElement?: HTMLButtonElement | null
  titlePosition?: "left" | "center"
  focusOnMount?: boolean
  icon?: React.ReactNode
  anchorEl?: HTMLElement | null
  anchorOrigin?: {
    vertical?: "bottom" | "top"
    horizontal?: "left" | "right"
  }
  style?: React.CSSProperties
  contentStyle?: React.CSSProperties
  focusRef?: HTMLElement | React.MutableRefObject<HTMLElement | null>
  noAnchorOnMobile?: boolean
  focusLockModalId?: string
  titleTypography?: typeof Medium16Heading | false
  "aria-labelledby"?: string
  topOffset?: number
  children?:
    | React.ReactElement
    | ((params: { closeWithFocus: () => void }) => React.ReactNode)
    | React.ReactNode
  id?: string
  /** @deprecated */
  deprecatedHeading?: boolean
}

Popup.defaultProps = {
  style: {},
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "right",
  },
  titlePosition: "left",
  children: null,
  clearOnClickOutside: true,
  focusOnMount: true,
  enableFocusTrap: true,
  icon: null,
  noAnchorOnMobile: false,
  focusLockModalId: "focus-lock-modal",
  "aria-labelledby": "modal-title",
  topOffset: 0,
} satisfies Partial<PopupProps>

export default styled(Popup)``
