import {
  prepareHttpRequestHeaders,
  processHttpResponseHeaders,
} from "@myvp/shared/src/functions/app-http-headers"

/**
 * NOTE: when updating this function, consider updating http-request.ts in the other apps
 */
export const httpRequest = async (
  url: string | URL,
  { headers, ...parameters }: RequestInit = {}
) => {
  if (process.env.NODE_ENV === "test") {
    // eslint-disable-next-line no-console
    console.error(
      `You failed to mock an http request inside of a unit test\n
       api: ${url} \n
       test: ${expect.getState().currentTestName}`
    )
  }
  const result = await fetch(url, {
    headers: {
      ...(prepareHttpRequestHeaders("http") as RequestInit["headers"]),
      ...headers,
    },
    ...parameters,
  })

  processHttpResponseHeaders(result.headers, "http")
  if (!result.ok) {
    throw result
  }
  return result
}
