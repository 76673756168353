import { GraphQLError } from "graphql/error"

export const getErrorCode = (
  error: {
    status?: string | number
    message?: string
  } & { graphQLErrors?: GraphQLError[] }
) => {
  let code = error?.graphQLErrors?.[0]?.extensions?.errorCode
  if (!code) {
    try {
      if (
        process.env.NODE_ENV === "development" &&
        error?.message?.includes?.(
          `Cannot read properties of undefined (reading 'filter')`
        )
      ) {
        // eslint-disable-next-line no-alert
        window.alert("You need to run yarn codegen")
      }
      // graphql-request returns an object that needs to be stringified then parsed again, if this fails, then it's using fetch
      const newError = JSON.parse(JSON.stringify(error))
      code = newError?.response?.errors?.[0]?.extensions?.errorCode
    } catch {}
  }
  if (!code) {
    code = error?.status
  }
  return code ? String(code) : code
}

export const getErrorKey = (
  error: {
    status?: string | number
    message?: string
  } & { graphQLErrors?: GraphQLError[] }
) => {
  let key = error?.graphQLErrors?.[0]?.extensions?.errorKey
  if (!key) {
    try {
      if (
        process.env.NODE_ENV === "development" &&
        error?.message?.includes?.(
          `Cannot read properties of undefined (reading 'filter')`
        )
      ) {
        // eslint-disable-next-line no-alert
        window.alert("You need to run yarn codegen")
      }
      // graphql-request returns an object that needs to be stringified then parsed again, if this fails, then it's using fetch
      const newError = JSON.parse(JSON.stringify(error))
      key = newError?.response?.errors?.[0]?.extensions?.errorKey
    } catch {}
  }

  return key
}
