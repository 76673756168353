import { logger } from "@myvp/shared/src/metrics"

export const emailBeacon = () => {
  // check search params before slow read from session storage
  const currentParams = new URLSearchParams(window.location.search)
  const key = `activity-open-app-beacon-${currentParams.get(
    "notificationRequestId"
  )}`
  if (
    currentParams.get("notificationChannel") === "EMAIL" &&
    sessionStorage.getItem(key) !== "true"
  ) {
    sessionStorage.setItem(key, "true")
    logger("ACTIVITY_APP_OPEN", {
      notificationRequestId: currentParams.get("notificationRequestId"),
      channel: currentParams.get("notificationChannel"),
    })
  }
}
