import React from "react"
import { css, useTheme } from "@emotion/react"
import styled from "@emotion/styled"
import RtlSvg from "@myvp/shared/src/icons/rtl-svg"
import { iconSizeCss } from "@myvp/shared/src/styles/icon-utils"

const Svg = styled(RtlSvg)<{
  size: "large" | "medium" | "small"
  variant: "left" | "right"
}>`
  ${(props) => iconSizeCss(props.size)};

  ${(props) =>
    props.size === "small" &&
    props.variant === "right" &&
    css`
      transform: scale(-1, 1);
    `};
`

const Arrow = (props: {
  size?: "large" | "medium" | "small"
  variant?: "left" | "right"
}) => {
  const size = props.size ?? "large"
  const variant = props.variant ?? "left"
  const theme = useTheme()

  if (size === "small") {
    return (
      <Svg
        variant={variant}
        focusable="false"
        size="small"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.05713 8L7.52853 12.4714L8.47134 11.5286L5.60941 8.66667L11.9999 8.66667L11.9999 7.33333L5.60941 7.33333L8.47134 4.4714L7.52853 3.52859L3.05713 8Z"
          fill={theme.palette.foreground.default}
        />
      </Svg>
    )
  }
  return (
    <Svg
      variant={variant}
      size={size}
      focusable="false"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {variant === "left" ? (
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.58582 16L15.2929 24.7071L16.7071 23.2929L10.4142 17L24 17L24 15L10.4142 15L16.7071 8.70712L15.2929 7.29291L6.58582 16Z"
          fill={theme.palette.foreground.default}
        />
      ) : (
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M25.4143 16L16.7072 24.7071L15.293 23.2929L21.5859 17L8.00009 17L8.00009 15L21.5859 15L15.293 8.70711L16.7072 7.29289L25.4143 16Z"
          fill={theme.palette.foreground.default}
        />
      )}
    </Svg>
  )
}

export default Arrow
