import React from "react"
import * as authModel from "src/models/auth.model"
import useApi from "@myvp/shared/src/hooks/use-api"
import {
  type To,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom"
import { routeNames } from "src/router/route-names"
import { logger } from "@myvp/shared/src/metrics"
import { createReducer } from "@myvp/shared/src/functions/create-reducer"
import { useIntlProvider } from "src/router/providers"
import { getLocale } from "src/i18n/get-locale"
import useDidUpdate from "@myvp/shared/src/hooks/use-did-update"
import { Role, GetUserMetadataQuery } from "src/gql/graphql"
import { useQueryClient } from "@tanstack/react-query"
import { SUPPORTED_LOCALES } from "src/i18n/locales"
import { SupportedLocale } from "src/types"

export interface Value {
  refreshUserMetadata?: () => void
  updateUserInfo: (
    userInfo: GetUserMetadataQuery["userInfo"],
    options?: {
      redirect?: boolean
      searchParams?: object
      customState?: object
    }
  ) => void
  updateUserStudies: (
    userStudies: GetUserMetadataQuery["userStudies"],
    options?: {
      redirect?: boolean
      searchParams?: object
      customState?: object
    }
  ) => Promise<void>
  isUserLoggedIn?: boolean
  isUnregisteredUserAuthorized?: boolean
  isUserApiFetching?: boolean
  logout?: (url: To) => void
  participantRelatedUserId?: string
  role?: Role
  studyId?: string
}
type State = Value &
  GetUserMetadataQuery & {
    userInfo: GetUserMetadataQuery["userInfo"] & {
      languageTag: string
      timeZoneId: string
      timeZoneLabel: string
    }
  }
const Context = React.createContext<State>({
  userInfo: {
    languageTag: "",
    timeZoneId: "",
    timeZoneLabel: "",
  },
  userStudies: {
    userStudyRoles: [],
  },
  updateUserInfo: () => {},
  updateUserStudies: async () => {},
})
const reducer = createReducer({
  UPDATE_METADATA: (state, action) => {
    let updatedState = {
      ...state,
      data: {
        ...state.data,
        ...action.metadata,
      },
    }
    authModel.persistUserMetadata(updatedState.data)
    return updatedState
  },
})

export const UserProvider = (props: {
  children:
    | React.ReactNode
    | ((value: Value & GetUserMetadataQuery) => React.ReactNode)
}) => {
  const navigate = useNavigate()
  const location = useLocation()
  const [searchParams, setSearchParams] = useSearchParams()

  const { dispatch, ...userApi } = useApi(() => authModel.getUserMetadata(), {
    lazy: false,
    onError: (e) => {
      // eslint-disable-next-line no-console
      console.error(e)
    },
    reducer,
  })
  const queryClient = useQueryClient()
  const { userInfo, userStudies } = userApi.data ?? {}
  const {
    firstName,
    lastName,
    email,
    username,
    formattedPhoneNumber,
    pendingEmail,
    phoneCountryCode,
    phoneNumber,
    timeZoneId,
    timeZoneLabel,
    languageDisplayName,
    languageTag,
    newTermsVersion,
  } = userInfo ?? {}

  // handles:
  // 1. when the page initially loads and the URL params need to be set with the default study
  // 2. the user changes their study in the switch studies modal and the URL params need to be updated
  useDidUpdate(userStudies, (_, userStudies) => {
    if (userStudies) {
      const defaultStudy = userStudies.userStudyRoles.find(
        (study) => study.isDefaultStudy
      )
      if (defaultStudy && defaultStudy.studyDetails.studyId) {
        const newSearchParams = new URLSearchParams(searchParams)
        newSearchParams.set("role", defaultStudy.role)
        newSearchParams.set("studyId", defaultStudy.studyDetails.studyId)
        newSearchParams.set(
          "relatedUserId",
          defaultStudy.participantDetails.relatedUserId
        )
        setSearchParams(newSearchParams, {
          replace: true,
          state: location.state,
        })
      }
    }
  })

  const locale = getLocale(
    languageTag ?? SUPPORTED_LOCALES.enUS
  ) as SupportedLocale
  const { locale: currentLocale, updateIntl } = useIntlProvider()
  React.useEffect(() => {
    if (locale && currentLocale && locale !== currentLocale) {
      updateIntl(locale)
    }
  }, [currentLocale, locale, updateIntl])

  const logout: Value["logout"] = React.useCallback(
    async (url: To) => {
      const to = url ?? `${routeNames.login}${location.search}`
      queryClient.clear()
      logger("ACTIVITY_USER_LOGOUT", { email })
      await authModel.logout()
      // Reset browser locale when the user is logged out
      updateIntl()
      navigate(to)
    },
    [email, navigate, queryClient, location.search, updateIntl]
  )

  React.useEffect(() => {
    window.onstorage = (event) => {
      if (
        event.storageArea === localStorage &&
        event.key === "myvp-login" &&
        event.oldValue !== event.newValue
      ) {
        // This will get triggered in the authModel.login function
        //
        // When a different tab has logged in, then we want to ensure we remove the
        // user's metadata inside of sessionStorage, if not, then it will display old tab's
        // metadata in the logout popup upon refresh, since we do not fetch user metadata
        // if there is already data saved in sessionStorage
        authModel.removeUserMetadata()
      }

      // Refresh page if necessary
      else if (
        event.storageArea === localStorage &&
        event.key === "myvp-refresh" &&
        event.oldValue !== event.newValue
      ) {
        authModel.removeUserMetadata()
        window.location.reload()
      }
    }
  }, [])

  const updateUserInfo = React.useCallback(
    (_userInfo: GetUserMetadataQuery["userInfo"]) => {
      const metadata = { userInfo: { ...userInfo, ..._userInfo }, userStudies }
      dispatch({ type: "UPDATE_METADATA", metadata })
    },
    [dispatch, userStudies, userInfo]
  )
  const updateUserStudies = React.useCallback(
    async (_userStudies: GetUserMetadataQuery["userStudies"]) => {
      const metadata = {
        userInfo,
        userStudies: { ...userStudies, ..._userStudies },
      }
      dispatch({ type: "UPDATE_METADATA", metadata })
    },
    [dispatch, userInfo, userStudies]
  )

  const refreshUserMetadata = React.useCallback(() => {
    authModel.removeUserMetadata()
    authModel.getUserMetadata().then((data) => {
      dispatch({ type: "UPDATE_METADATA", data })
    })
  }, [dispatch])

  const participantRelatedUserId = searchParams.get("relatedUserId")
  const role = searchParams.get("role") as Role
  const studyId = searchParams.get("studyId")

  const value = {
    refreshUserMetadata,
    updateUserInfo,
    updateUserStudies,
    userInfo: {
      firstName,
      lastName,
      email,
      username,
      formattedPhoneNumber,
      pendingEmail,
      phoneCountryCode,
      phoneNumber,
      timeZoneId,
      timeZoneLabel,
      languageDisplayName,
      languageTag: locale,
      newTermsVersion,
    },
    isUserLoggedIn: !!(
      userApi.called &&
      firstName &&
      lastName &&
      email &&
      phoneNumber
    ),
    isUnregisteredUserAuthorized: !!(userApi.called && firstName && lastName),
    isUserApiFetching: userApi.isFetching || !userApi.called,
    logout,
    participantRelatedUserId,
    role,
    studyId,
    userStudies,
  }

  return (
    <Context.Provider value={value as State}>
      {typeof props.children === "function"
        ? props.children(value as State)
        : props.children}
    </Context.Provider>
  )
}

export const useUserSelector = <T,>(selector: (state: State) => T) => {
  return selector(React.useContext(Context))
}
