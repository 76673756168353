import React from "react"
import { useLocation } from "react-router-dom"
import { routeNames } from "src/router/route-names"
import { useUserSelector } from "src/hooks/use-user"
import * as authModel from "src/models/auth.model"
import { useIntl } from "react-intl"
import { css } from "@emotion/react"
import styled from "@emotion/styled"
import Popout from "@myvp/shared/src/icons/popout"
import {
  Medium24Header4,
  Regular14Paragraph,
} from "@myvp/shared/src/typography"
import Link from "@myvp/shared/src/components/buttons/link"
import { useMutation } from "@tanstack/react-query"
import Modal from "@myvp/shared/src/components/modal"
import Button from "@myvp/shared/src/components/buttons/button"

// copied/modified from entry/account/account.tsx
const LinkRow = styled(Link)`
  border-radius: 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 48px;
  text-decoration: none;
  padding-inline: 5px;
  border-bottom: 0.5px solid ${(props) => props.theme.palette.border.rule};

  :hover {
    border-radius: 8px;
    background-color: ${(props) => props.theme.palette.surface.hovered};
  }

  ${Regular14Paragraph} {
    font-weight: 500;
  }
`

const AppPolicyModal = () => {
  const { search } = useLocation()
  const { formatMessage } = useIntl()

  // If the call to this API fails, the user will still be able to log in,
  // but they will see the alert again the NEXT time they log in.
  const confirmTerms = useMutation({
    mutationFn: (versionNumber: string) =>
      authModel.confirmTerms(versionNumber),
    throwOnError: false,
  })

  const { languageTag, logout, newTermsVersion, updateUserInfo } =
    useUserSelector((state) => {
      return {
        languageTag: state.userInfo.languageTag,
        logout: state.logout,
        newTermsVersion: state.userInfo.newTermsVersion,
        updateUserInfo: state.updateUserInfo,
      }
    })

  const searchWithLanguageTag = new URLSearchParams(search)
  if (languageTag) {
    searchWithLanguageTag.set("locale", languageTag)
  }

  if (!newTermsVersion) {
    return null
  }

  return (
    <Modal
      title={
        <Medium24Header4>
          {formatMessage({
            id: "authenticatedRoute.updatesToAppPolicies",
          })}
        </Medium24Header4>
      }
      visible={true}
      css={(theme) => css`
        #modal-popup {
          max-width: 496px;
          width: calc(100vw - 48px);
        }

        ${Regular14Paragraph} {
          color: ${theme.palette.foreground.default};
        }
      `}
    >
      <Regular14Paragraph
        css={css`
          text-align: start;
          padding-block: 16px;
        `}
      >
        {formatMessage({
          id: "authenticatedRoute.updatedTermsMessage",
        })}
      </Regular14Paragraph>
      <LinkRow
        to={{
          pathname: routeNames.privacyNotice,
          search: searchWithLanguageTag.toString(),
        }}
        target="_blank"
      >
        <Regular14Paragraph>
          {formatMessage({ id: "authenticatedRoute.privacyNotice" })}
        </Regular14Paragraph>
        <Popout color="link" />
      </LinkRow>
      <LinkRow
        to={{
          pathname: routeNames.termsOfService,
          search: searchWithLanguageTag.toString(),
        }}
        target="_blank"
      >
        <Regular14Paragraph>
          {formatMessage({ id: "entry.termsOfService" })}
        </Regular14Paragraph>
        <Popout color="link" />
      </LinkRow>
      <div
        css={css`
          display: flex;
          justify-content: center;
          padding-top: 16px;

          > button {
            width: 100%;
            margin-inline: 8px;
          }
        `}
      >
        <Button
          variant="secondary"
          height="medium"
          onClick={() =>
            logout?.({
              pathname: routeNames.login,
              search,
            })
          }
          disabled={confirmTerms.isPending}
        >
          {formatMessage({ id: "authenticatedRoute.returnToLogin" })}
        </Button>
        <Button
          variant="primary"
          height="medium"
          onClick={() => {
            confirmTerms.mutate(newTermsVersion ?? "")
            updateUserInfo({ newTermsVersion: null }, { redirect: false })
          }}
          disabled={confirmTerms.isPending}
        >
          {formatMessage({ id: "entry.continue" })}
        </Button>
      </div>
    </Modal>
  )
}

export default AppPolicyModal
