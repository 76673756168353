import React from "react"
import styled from "@emotion/styled"
import { css, useTheme } from "@emotion/react"

export type Variant =
  | "small"
  | "medium"
  | "medium-outlined"
  | "large"
  | "default-medium"
const Svg = styled.svg<{ variant: Variant }>`
  min-width: 16px;
  width: 16px;
  min-height: 16px;
  height: 16px;

  ${(props) =>
    (props.variant === "medium" ||
      props.variant === "medium-outlined" ||
      props.variant === "default-medium") &&
    css`
      min-width: 24px;
      width: 24px;
      min-height: 24px;
      height: 24px;
    `}

  ${(props) =>
    props.variant === "large" &&
    css`
      min-width: 26px;
      width: 26px;
      min-height: 26px;
      height: 26px;
    `}

    ${(props) =>
    props.variant === "medium-outlined"
      ? css`
          path {
            stroke: ${props.theme.palette.foreground.warning};
          }
        `
      : css`
          path {
            :first-of-type {
              fill: ${props.theme.palette.foreground.warning};
            }

            fill: ${props.theme.palette.foreground.inverse};
          }
        `}
`

const Warning = (props: { variant?: Variant }) => {
  const variant = props.variant ?? "small"
  const theme = useTheme()
  let content: React.ReactNode = null
  switch (props.variant) {
    case "medium":
      content = (
        <>
          <path
            d="M1.38932 19.493L11.1386 2.96155C11.5255 2.30553 12.4745 2.30553 12.8614 2.96155L22.6107 19.493C23.0038 20.1596 22.5232 21.001 21.7493 21.001H2.25069C1.47678 21.001 0.996187 20.1596 1.38932 19.493Z"
            fill={theme.palette.foreground.warning}
          />
          <path
            d="M12 10.001V14.801M12 16.401V18.001"
            stroke={theme.palette.foreground.inverse}
            strokeWidth="2"
          />
        </>
      )
      break
    case "medium-outlined":
      content = (
        <path
          stroke="#C38D00"
          strokeWidth="2"
          d="M2.25 20 12 3.469 21.75 20H2.25ZM12 9v4.8m0 1.6V17"
        />
      )
      break
    case "small":
    case "default-medium":
    case "large":
    default:
      content = (
        <>
          <path d="M7.01918 1.6C7.4551 0.799999 8.5449 0.800001 8.98082 1.6L15.8466 14.2C16.2825 15 15.7376 16 14.8658 16H1.13424C0.262402 16 -0.282497 15 0.153424 14.2L7.01918 1.6Z" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7 10V5H9V10H7ZM9 12V14H7V12H9Z"
          />
        </>
      )
  }
  return (
    <Svg
      focusable="false"
      viewBox={
        props.variant === "medium" || props.variant === "medium-outlined"
          ? "0 0 24 24"
          : "0 0 16 16"
      }
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      variant={variant}
    >
      {content}
    </Svg>
  )
}

export default styled(Warning)``
