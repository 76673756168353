import React from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/react"
import {
  type RouteObject,
  Outlet,
  useParams,
  useLocation,
  Navigate,
  type NavigateProps,
  type Location as RRLocation,
  type Params,
  redirect,
  generatePath,
  useMatches,
  useRouteError,
} from "react-router-dom"
import { routeNames } from "src/router/route-names"
import LandingScreen from "src/components/landing-screen"
import { useIntl } from "react-intl"
import AuthenticatedRoute from "src/components/authenticated-route"
import { CONFIRM_FIELD_TYPES } from "src/constants"
import ConfirmNewEmailExpired from "src/pages/confirm-new-email/confirm-new-email-expired"
import InPersonFallback from "src/pages/in-person/components/in-person-fallback"
import { isSessionValid } from "@myvp/shared/src/functions/is-session-valid"
import SmartBanner from "src/components/smart-banner"
import { type EntryRouteHandle } from "src/pages/entry/entry"
import { createStudyRoutes } from "src/pages/entry/study/study-routes"
import studyRouteNames from "src/pages/entry/study/study-route-names"
import VisitErrorBoundary from "src/pages/entry/todo/visit-error-boundary"
import { GLOBAL_DOMAIN } from "@myvp/shared/src/constants"
import ForgotPasswordErrorBoundary from "src/pages/forgot-password/forgot-password-error-boundary"

/**
 * This types the handle attribute on the route object for any nested pages
 * that need default back behavior
 *
 * It determines which route should be navigated to using the back arrow
 */
export interface NestedRouteHandle {
  defaultBackPath: string
}

export const getDefaultBackPath = (matches: ReturnType<typeof useMatches>) =>
  [...matches].reverse().find(
    (
      match
    ): match is (typeof matches)[number] & {
      handle: NestedRouteHandle
    } => {
      return (
        !!match.handle &&
        typeof (match.handle as NestedRouteHandle).defaultBackPath === "string"
      )
    }
  )?.handle?.defaultBackPath

const RootErrorHandler = () => {
  const error = useRouteError()
  // eslint-disable-next-line no-console
  console.error(error)
  // avoid rendering the debug overlay react-router has
  return <React.Fragment />
}
export const createRootRoute = (routes: RouteObject[]): RouteObject => ({
  path: "/",
  element: (
    <>
      <DevTools />
      <DocumentHead />
      <Outlet />
    </>
  ),
  errorElement: <RootErrorHandler />,
  children: routes,
})

const NavigateWithParams = (props: { to: string }) => {
  const { search } = useLocation()
  const params = useParams()
  const pathname = generatePath(props.to, params)

  return <Navigate replace to={{ pathname, search }} />
}

const createRedirect = (
  args: { from: string; to: string } | { index: true; to: string }
): RouteObject => ({
  path: "from" in args ? args.from : undefined,
  index: "index" in args ? args.index : undefined,
  element: <NavigateWithParams to={args.to} />,
})

const LazyDevTools = React.lazy(() => import("@myvp/shared/src/dev-tools"))
const DevTools = () => (
  <React.Suspense fallback={null}>
    {process.env.NODE_ENV === "development" ||
    (process.env.REACT_APP_mock_services === "true" &&
      window.location.host.startsWith("myvc-")) ? (
      <LazyDevTools project="patients" port="3000" />
    ) : (
      <React.Fragment />
    )}
  </React.Suspense>
)

/**
 * Mutates or adds html elements to the html document's head element.
 */
const DocumentHead = (): null => {
  const hostname = window.location?.hostname?.split?.(".")?.[0] ?? ""
  const { formatMessage } = useIntl()
  const location = useLocation()

  // set document title
  React.useEffect(() => {
    if (location.pathname === routeNames.econsentEditor) {
      document.title = formatMessage({ id: "title.documentTitleVeevaEconsent" })
    } else {
      document.title = formatMessage({ id: "title.documentTitle" })
    }
  }, [formatMessage, location.pathname])

  // add meta tag to prevent search indexing
  React.useEffect(() => {
    if (hostname !== GLOBAL_DOMAIN) {
      const meta = document.createElement("meta")
      meta.name = "robots"
      meta.content = "noindex"
      document.getElementsByTagName("head")[0].appendChild(meta)
    }
  }, [hostname])

  return null
}

const NavigateWithLocation = (props: {
  props: (loc: RRLocation, params: Readonly<Params<string>>) => NavigateProps
}) => {
  const location = useLocation()
  const params = useParams()
  const navigateProps = props.props(location, params)
  return <Navigate {...navigateProps} />
}

const Help = (): null => {
  React.useEffect(() => {
    if (window.location.pathname !== "/help/404.html") {
      window.location.assign("/help/404.html")
    }
  }, [])
  return null
}

const Tos = (): null => {
  React.useEffect(() => {
    // This only runs if a user somehow puts /terms/somethingelse then it redirects back to /terms
    const url = new URL(window.location.href)
    url.pathname = routeNames.termsOfService
    window.location.assign(url)
  }, [])
  return null
}

const Pn = (): null => {
  React.useEffect(() => {
    // This only runs if a user somehow puts /privacy/somethingelse then it redirects back to /privacy
    const url = new URL(window.location.href)
    url.pathname = routeNames.privacyNotice
    window.location.assign(url)
  }, [])
  return null
}

function Suspense(props: Partial<React.SuspenseProps>) {
  return (
    <React.Suspense fallback={props.fallback ?? <Fallback />}>
      {props.children}
    </React.Suspense>
  )
}
const Fallback = styled.div`
  height: 100%;
  ${(props) =>
    props.color === "white" &&
    css`
      background: ${props.theme.palette.steel.zero};
    `};
  ${(props) =>
    props.color === "snow" &&
    css`
      background: ${props.theme.palette.snow};
    `};
`

const LazyLogin = React.lazy(() => import("src/pages/login"))
const Login = () => (
  <Suspense fallback={<LandingScreen />}>
    <LazyLogin />
  </Suspense>
)
const LazyResetSvUserPassword = React.lazy(
  () => import("src/pages/reset-sv-user-password/reset-sv-user-password")
)
const ResetSvUserPassword = () => (
  <Suspense fallback={<LandingScreen />}>
    <LazyResetSvUserPassword />
  </Suspense>
)

const LazyEnterMfa = React.lazy(() => import("src/pages/enter-mfa"))
const EnterMfa = () => (
  <Suspense fallback={<LandingScreen />}>
    <LazyEnterMfa />
  </Suspense>
)

const LazyEconsentViewer = React.lazy(
  () => import("src/pages/econsent/econsent-viewer/econsent-viewer")
)
const EconsentViewer: React.FC<{ inPerson?: boolean }> = (props) => (
  <Suspense>
    <LazyEconsentViewer inPerson={props.inPerson} />
  </Suspense>
)

const LazyEconsentGenerate = React.lazy(
  () => import("src/pages/econsent/econsent-generate/econsent-generate")
)
const EconsentGenerate = () => (
  <Suspense fallback={null}>
    <LazyEconsentGenerate />
  </Suspense>
)

const LazyEconsentEditor = React.lazy(
  () => import("src/pages/econsent/econsent-editor/econsent-editor")
)
const EconsentEditor = () => (
  <Suspense fallback={null}>
    <LazyEconsentEditor />
  </Suspense>
)

const LazyDetermineUser = React.lazy(() => import("src/pages/determine-user"))
const DetermineUser = () => (
  <Suspense fallback={null}>
    <LazyDetermineUser />
  </Suspense>
)

const LazyEconsentPreviewGenerator = React.lazy(
  () => import("src/pages/econsent/econsent-viewer/econsent-preview-generator")
)
const EconsentPreviewGenerator = () => (
  <Suspense fallback={null}>
    <LazyEconsentPreviewGenerator />
  </Suspense>
)

const LazyError = React.lazy(() => import("src/pages/error"))
const Error = () => (
  <Suspense fallback={null}>
    <LazyError />
  </Suspense>
)

const LazyEntry = React.lazy(() => import("src/pages/entry/entry"))
const Entry = () => (
  <Suspense fallback={null}>
    <LazyEntry>
      <Outlet />
    </LazyEntry>
  </Suspense>
)

const LazyInPerson = React.lazy(() => import("src/pages/in-person/in-person"))
const InPerson = () => (
  <Suspense fallback={null}>
    <LazyInPerson />
  </Suspense>
)
const LazyInPersonConfirm = React.lazy(
  () => import("src/pages/in-person/in-person-confirm")
)
const InPersonConfirm = () => (
  <Suspense fallback={null}>
    <LazyInPersonConfirm />
  </Suspense>
)
const LazyInPersonRoles = React.lazy(
  () => import("src/pages/in-person/in-person-roles")
)
const InPersonRoles = () => (
  <Suspense fallback={null}>
    <LazyInPersonRoles />
  </Suspense>
)

const LazyInPersonSuccess = React.lazy(
  () => import("src/pages/in-person/in-person-success")
)
const InPersonSuccess = () => (
  <Suspense fallback={null}>
    <LazyInPersonSuccess />
  </Suspense>
)

const LazyConfirmNewEmail = React.lazy(
  () => import("src/pages/confirm-new-email/confirm-new-email")
)
const ConfirmNewEmail = () => (
  <Suspense fallback={null}>
    <LazyConfirmNewEmail />
  </Suspense>
)

const LazyPatientViewerErrorBoundary = React.lazy(
  () => import("src/pages/epro/viewers/patient/patient-viewer-error-boundary")
)
const PatientViewerErrorBoundary = () => (
  <Suspense>
    <LazyPatientViewerErrorBoundary />
  </Suspense>
)
const LazyPatientViewer = React.lazy(
  () => import("src/pages/epro/viewers/patient/patient-viewer")
)
const PatientViewer = () => (
  <Suspense>
    <LazyPatientViewer />
  </Suspense>
)

const LazyStudyTabScreenshot = React.lazy(
  () => import("src/pages/entry/study-screenshots/study-tab-screenshot")
)
const StudyTabScreenshot = () => (
  <Suspense>
    <LazyStudyTabScreenshot />
  </Suspense>
)
const LazyStudyDescriptionScreenshot = React.lazy(
  () => import("src/pages/entry/study-screenshots/study-description-screenshot")
)
const StudyDescriptionScreenshot = () => (
  <Suspense>
    <LazyStudyDescriptionScreenshot />
  </Suspense>
)

const LazyVisitTodoDetails = React.lazy(
  () => import("src/pages/entry/todo/visit-details")
)
const VisitTodoDetails = () => (
  <Suspense>
    <LazyVisitTodoDetails />
  </Suspense>
)

const LazyRedirectToAppstore = React.lazy(
  () => import("src/pages/redirect-to-appstore")
)
const RedirectToAppstore = () => (
  <Suspense>
    <LazyRedirectToAppstore />
  </Suspense>
)

const LazyTodo = React.lazy(() => import("src/pages/entry/todo/todo"))
const EntryTodo = () => (
  <Suspense fallback={null}>
    <SmartBanner />
    <LazyTodo />
  </Suspense>
)

const LazyAccount = React.lazy(() => import("src/pages/entry/account/account"))
const EntryAccount = () => (
  <Suspense fallback={null}>
    <LazyAccount />
  </Suspense>
)

const LazyAccountErrorBoundary = React.lazy(
  () => import("src/pages/entry/account/account-error-boundary")
)
const AccountErrorBoundary = () => (
  <Suspense fallback={null}>
    <LazyAccountErrorBoundary />
  </Suspense>
)

const LazyTodoErrorBoundary = React.lazy(
  () => import("src/pages/entry/todo/todo-error-boundary")
)
const TodoErrorBoundary = () => (
  <Suspense fallback={null}>
    <LazyTodoErrorBoundary />
  </Suspense>
)

const LazySettings = React.lazy(
  () => import("src/pages/entry/settings/settings")
)
const EntrySettings = () => (
  <Suspense fallback={null}>
    <LazySettings />
  </Suspense>
)

const LazySettingsErrorBoundary = React.lazy(
  () => import("src/pages/entry/settings/settings-error-boundary")
)
const SettingsErrorBoundary = () => (
  <Suspense fallback={null}>
    <LazySettingsErrorBoundary />
  </Suspense>
)
const LazyScreenshotErrorBoundary = React.lazy(
  () =>
    import("src/pages/epro/viewers/screenshot/epro-screenshot-error-boundary")
)
const EproScreenshotErrorBoundary = () => (
  <Suspense>
    <LazyScreenshotErrorBoundary />
  </Suspense>
)
const LazyScreenshotViewer = React.lazy(
  () => import("src/pages/epro/viewers/screenshot/epro-screenshot")
)
const EproScreenshot = () => (
  <Suspense>
    <LazyScreenshotViewer />
  </Suspense>
)

export const createEntryRoutes = (): RouteObject[] => [
  {
    path: routeNames.entry,
    element: (
      <AuthenticatedRoute>
        <Entry />
      </AuthenticatedRoute>
    ),
    children: [
      {
        path: routeNames.todo,
        Component: EntryTodo,
        errorElement: <TodoErrorBoundary />,
        handle: {
          headerTextId: "entry.todo",
          isMainTab: true,
        } satisfies EntryRouteHandle,
      },
      createStudyRoutes(),
      {
        path: routeNames.account,
        Component: EntryAccount,
        errorElement: <AccountErrorBoundary />,
        handle: {
          headerTextId: "entry.account",
          isMainTab: true,
        } satisfies EntryRouteHandle,
      },
      {
        path: routeNames.activateNewStudy,
        lazy: () => import("src/pages/entry/account/activate-new-study"),
        handle: {
          defaultBackPath: routeNames.account,
        } satisfies NestedRouteHandle,
        errorElement: <AccountErrorBoundary />,
      },
      {
        path: routeNames.activateNewStudyConfirmDob,
        lazy: () => import("src/pages/entry/account/account-confirm-dob"),
        handle: {
          defaultBackPath: routeNames.activateNewStudy,
        } satisfies NestedRouteHandle,
        errorElement: <AccountErrorBoundary />,
      },
      {
        path: routeNames.settings,
        Component: EntrySettings,
        handle: {
          defaultBackPath: routeNames.account,
          headerTextId: "account.settings",
          isMainTab: false,
        } satisfies NestedRouteHandle & EntryRouteHandle,
        errorElement: <SettingsErrorBoundary />,
      },
      {
        path: routeNames.viewVisitFromTodos(),
        Component: VisitTodoDetails,
        errorElement: <VisitErrorBoundary />,
      },
      createRedirect({
        from: routeNames.DEPRECATED_econsentStudies(),
        to: routeNames.todo,
      }),
      createRedirect({
        from: routeNames.DEPRECATED_econsentStudies(),
        to: routeNames.todo,
      }),
      createRedirect({
        from: routeNames.DEPRECATED_econsentLibrary,
        to: routeNames.todo,
      }),
      createRedirect({
        index: true,
        to: routeNames.todo,
      }),
      createRedirect({
        from: routeNames.entry + "/*",
        to: routeNames.todo,
      }),
    ],
  },
]

export const createEproViewerRoutes = (): RouteObject[] => {
  return [
    {
      path: routeNames.eproViewSurveyFromTodo() + "/*",
      element: (
        <AuthenticatedRoute>
          <PatientViewer />
        </AuthenticatedRoute>
      ),
      errorElement: <PatientViewerErrorBoundary />,
    },
    ...[
      createRedirect({
        from: routeNames.eproViewSurveyFromTasks(),
        to: routeNames.eproViewSurveyFromTodo(),
      }),
    ],
  ]
}

export const routes: RouteObject[] = [
  {
    path: routeNames.login,
    Component: Login,
  },
  {
    path: routeNames.eproScreenshot(),
    Component: EproScreenshot,
    errorElement: <EproScreenshotErrorBoundary />,
  },
  {
    path: routeNames.confirmIdentity,
    lazy: () => import("src/pages/confirm-identity/confirm-identity"),
    handle: {
      defaultBackPath: routeNames.login,
    },
  },
  {
    path: routeNames.confirmIdentityEmail,
    lazy: () => import("src/pages/confirm-identity/confirm-identity-email"),
    handle: {
      defaultBackPath: routeNames.confirmIdentity,
    },
  },
  {
    path: routeNames.confirmIdentityPhone,
    lazy: () => import("src/pages/confirm-identity/confirm-identity-phone"),
    handle: {
      defaultBackPath: routeNames.confirmIdentity,
    },
  },
  {
    path: routeNames.identityConfirmed,
    lazy: () => import("src/pages/confirm-identity/identity-confirmed"),
  },
  {
    path: routeNames.version,
    lazy: () => import("src/pages/patients-version"),
  },
  { path: routeNames.error + "/*", Component: Error },
  {
    path: routeNames.registerActivate + "/*",
    lazy: () => import("src/pages/register/register-activate"),
  },
  {
    path: routeNames.resetAccount,
    lazy: () => import("src/pages/register/reset-account"),
  },
  {
    path: routeNames.registerActivateRedirect + "/*",
    lazy: () => import("src/pages/register/register-activate-redirect"),
  },
  {
    path: routeNames.registerConfirmDob + "/*",
    lazy: () => import("src/pages/register/register-confirm-dob"),
    handle: {
      defaultBackPath: routeNames.registerActivate,
    },
  },
  {
    path: routeNames.registerSetUsername + "/*",
    lazy: () => import("src/pages/register/register-set-username"),
    handle: {
      defaultBackPath: routeNames.registerActivate,
    },
  },
  {
    path: routeNames.registerSetPassword + "/*",
    lazy: () => import("src/pages/register/register-set-password"),
    handle: {
      defaultBackPath: routeNames.registerSetUsername,
    },
  },
  {
    path: routeNames.registerSetVerificationMethod + "/*",
    lazy: () => import("src/pages/register/register-set-verification-method"),
    handle: {
      defaultBackPath: routeNames.registerSetPassword,
    },
  },
  {
    path: routeNames.registerSetVerificationMethodEmail + "/*",
    lazy: () => import("src/pages/register/register-set-email"),
    handle: {
      defaultBackPath: routeNames.registerSetVerificationMethod,
    },
  },
  {
    path: routeNames.registerSetVerificationMethodPhone + "/*",
    lazy: () => import("src/pages/register/register-set-phone"),
    handle: {
      defaultBackPath: routeNames.registerSetVerificationMethod,
    },
  },
  {
    path: routeNames.registerConfirmEmail + "/*",
    lazy: () => import("src/pages/register/register-confirm-email"),
    handle: {
      defaultBackPath: routeNames.registerSetVerificationMethodEmail,
    },
  },
  {
    path: routeNames.registerConfirmPhone + "/*",
    lazy: () => import("src/pages/register/register-confirm-phone"),
    handle: {
      defaultBackPath: routeNames.registerSetVerificationMethodPhone,
    },
  },
  {
    path: routeNames.registerLegacyConfirmIdentity + "/*",
    lazy: () => import("src/pages/confirm-identity/confirm-identity"),
  },
  {
    path: routeNames.registerLegacyConfirmPhone + "/*",
    lazy: () => import("src/pages/register/register-confirm-phone"),
    handle: {
      defaultBackPath: routeNames.registerLegacyConfirmIdentity,
    },
  },
  {
    path: routeNames.registerLegacySetUsername + "/*",
    lazy: () => import("src/pages/register/register-set-username"),
    handle: {
      defaultBackPath: routeNames.registerLegacyConfirmIdentity,
    },
  },
  {
    path: routeNames.registerLegacySetPassword + "/*",
    lazy: () => import("src/pages/register/register-set-password"),
    handle: {
      defaultBackPath: routeNames.registerLegacySetUsername,
    },
  },
  { path: routeNames.enterMfa + "/*", Component: EnterMfa },
  {
    path: routeNames.resetSvUserPassword + "/*",
    Component: ResetSvUserPassword,
  },
  {
    // Not in route names because this shouldn't be used elsewhere
    path: "/confirm-new-field/*",
    loader: ({ request }) => {
      const url = new URL(request.url)
      const field = url.searchParams.get("field")
      if (field === CONFIRM_FIELD_TYPES.phone) {
        url.pathname = routeNames.confirmNewNumber
        return redirect(url.href)
      } else if (field === CONFIRM_FIELD_TYPES.email) {
        url.pathname = routeNames.confirmNewEmail
        return redirect(url.href)
      } else {
        return null
      }
    },
    element: null,
  },
  {
    path: routeNames.studyTabScreenshot(),
    Component: StudyTabScreenshot,
    handle: {
      headerTextId: "entry.study",
      isMainTab: true,
    } satisfies EntryRouteHandle,
  },
  {
    path: routeNames.studyDescriptionScreenshot(),
    Component: StudyDescriptionScreenshot,
    handle: {
      headerTextId: "study.details",
      isMainTab: false,
    } satisfies EntryRouteHandle,
  },
  {
    path: routeNames.resourcesScreenshot.route,
    lazy: () =>
      import("src/pages/entry/study-screenshots/resources-screenshot"),
    handle: {
      headerTextId: "study.resources",
      isMainTab: false,
    } satisfies EntryRouteHandle,
  },
  {
    path: routeNames.confirmNewEmail + "/*",
    element: (
      <AuthenticatedRoute Fallback={ConfirmNewEmailExpired}>
        <ConfirmNewEmail />
      </AuthenticatedRoute>
    ),
  },
  {
    path: routeNames.patientsRedirect,
    lazy: () => import("src/pages/patients-redirect"),
  },
  {
    path: routeNames.confirmNewEmailExpired,
    Component: ConfirmNewEmailExpired,
  },
  {
    path: routeNames.forgotPassword,
    lazy: () => import("src/pages/forgot-password/forgot-password"),
    errorElement: <ForgotPasswordErrorBoundary />,
  },
  {
    path: routeNames.forgotPasswordEmailSent,
    lazy: () => import("src/pages/forgot-password/forgot-password-email-sent"),
    errorElement: <ForgotPasswordErrorBoundary />,
    handle: {
      defaultBackPath: routeNames.forgotPassword,
    } satisfies NestedRouteHandle,
  },
  {
    path: routeNames.newPassword,
    lazy: () => import("src/pages/forgot-password/new-password"),
    errorElement: <ForgotPasswordErrorBoundary />,
  },
  {
    path: routeNames.newPasswordMfa,
    lazy: () => import("src/pages/forgot-password/new-password-mfa"),
    errorElement: <ForgotPasswordErrorBoundary />,
    handle: {
      defaultBackPath: routeNames.newPassword,
    } satisfies NestedRouteHandle,
  },
  {
    path: routeNames.setNewPassword,
    lazy: () => import("src/pages/forgot-password/set-new-password"),
    errorElement: <ForgotPasswordErrorBoundary />,
    handle: {
      defaultBackPath: routeNames.newPasswordMfa,
    } satisfies NestedRouteHandle,
  },
  { path: routeNames.determineUser + "/*", Component: DetermineUser },
  { path: routeNames.help + "/*", Component: Help },
  { path: routeNames.privacyNotice + "/*", Component: Pn },
  { path: routeNames.termsOfService + "/*", Component: Tos },
  { path: routeNames.econsentEditor, Component: EconsentEditor },
  {
    path: routeNames.econsentPreviewDocument(),
    Component: EconsentViewer,
  },
  {
    path: routeNames.econsentPreviewDocument(""),
    Component: EconsentViewer,
  },
  {
    path: routeNames.econsentPreviewDocumentFromEditor(""),
    Component: EconsentViewer,
  },
  {
    path: routeNames.econsentPreviewDocumentFromEditor(),
    Component: EconsentViewer,
  },
  {
    path: routeNames.econsentPreviewGenerator,
    Component: EconsentPreviewGenerator,
  },
  { path: routeNames.econsentGenerate, Component: EconsentGenerate },
  { path: routeNames.inPersonConfirm, Component: InPersonConfirm },
  { path: routeNames.inPersonRoles, Component: InPersonRoles },
  { path: routeNames.redirectToAppstore, Component: RedirectToAppstore },
  { path: routeNames.inPersonSuccess, Component: InPersonSuccess },
  ...createEproViewerRoutes(),
  {
    path: routeNames.inPerson,
    element: (
      <AuthenticatedRoute Fallback={InPersonFallback}>
        <InPerson />
      </AuthenticatedRoute>
    ),
  },
  {
    path: routeNames.inPersonEconsentViewer(),
    element: (
      <AuthenticatedRoute Fallback={InPersonFallback}>
        <EconsentViewer inPerson />
      </AuthenticatedRoute>
    ),
  },
  {
    path: routeNames.econsentViewDocumentFromStudy() + "/*",
    element: (
      <AuthenticatedRoute>
        <EconsentViewer />
      </AuthenticatedRoute>
    ),
    handle: {
      defaultBackPath: studyRouteNames.consentForms,
    } satisfies NestedRouteHandle,
  },
  {
    path: routeNames.econsentViewDocumentFromTodo() + "/*",
    element: (
      <AuthenticatedRoute>
        <EconsentViewer />
      </AuthenticatedRoute>
    ),
  },
  {
    path: routeNames.viewVisitFromTodos() + "/*",
    element: (
      <AuthenticatedRoute>
        <VisitTodoDetails />
      </AuthenticatedRoute>
    ),
    errorElement: <VisitErrorBoundary />,
  },
  {
    path: routeNames.DEPRECATED_econsentViewDocument(),
    element: (
      <NavigateWithLocation
        props={(location, params) => ({
          replace: true,
          to: {
            pathname: routeNames.econsentViewDocumentFromTasks(
              params.documentId
            ),
            search: location.search,
          },
          state: {
            from: {
              pathname: routeNames.tasks,
              search: location.search,
            },
          },
        })}
      />
    ),
  },
  {
    path: routeNames.DEPRECATED_econsentViewDocumentFromLibrary() + "/*",
    element: (
      <NavigateWithLocation
        props={(location, params) => ({
          replace: true,
          to: {
            pathname: routeNames.econsentViewDocumentFromDocuments(
              params.documentId
            ),
            search: location.search,
          },
          state: {
            from: {
              pathname: routeNames.documents,
              search: location.search,
            },
          },
        })}
      />
    ),
  },
  ...[
    ...createEntryRoutes(),
    createRedirect({
      from: routeNames.econsentViewDocumentFromDocuments(),
      to: routeNames.econsentViewDocumentFromStudy(),
    }),
    createRedirect({
      from: routeNames.econsentViewDocumentFromTasks(),
      to: routeNames.econsentViewDocumentFromTodo(),
    }),
    createRedirect({
      from: routeNames.viewVisitFromVisits(),
      to: routeNames.viewVisitFromTodos(),
    }),
    createRedirect({
      from: routeNames.documents,
      to: studyRouteNames.resources,
    }),
  ],
  {
    index: true,
    loader: ({ request }) => {
      const url = new URL(request.url)
      url.pathname = isSessionValid() ? routeNames.entry : routeNames.login
      return redirect(url.href)
    },
  },
]
