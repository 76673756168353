export const breakpointSizes = {
  xxs: 320,
  xs: 480,
  sm: 600,
  md: 960,
  lg: 1280,
}

type Dimension = "width" | "height"
const breakpoints = (
  size: "xxs" | "xs" | "sm" | "md" | "lg",
  dimension: Dimension = "width"
) => {
  const sizes = {
    xxs: `@media (max-${dimension}: ${breakpointSizes[size]}px)`,
    xs: `@media (max-${dimension}: ${breakpointSizes[size]}px)`,
    sm: `@media (max-${dimension}: ${breakpointSizes[size]}px)`,
    md: `@media (max-${dimension}: ${breakpointSizes[size]}px)`,
    lg: `@media (max-${dimension}: ${breakpointSizes[size]}px)`,
  }
  return sizes[size] ?? sizes.md
}
export default breakpoints
