/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react"
import { EqualityChecker, SetState } from "zustand"
import { shallow } from "zustand/shallow"
import { AnyAction, Reducer } from "@myvp/shared/src/types"
import { createWithEqualityFn } from "zustand/traditional"

export type Setters<State> = (
  set: (updatedState: State) => void,
  get: () => State
) => { [key: string]: (...args: any) => void }

const useStore = <State>({
  reducer = (state: State): State => state,
  initialState,
  actions = {},
  setters = () => ({}),
}: {
  reducer?: Reducer
  initialState: State
  name?: string
  actions?: object
  setters?: Setters<State>
}) => {
  const [useZustand] = React.useState(() => {
    return createWithEqualityFn(
      (set: SetState<{ state: State }>, get: () => { state: State }) => ({
        setters: setters(
          (updatedState: State) => {
            set({ state: updatedState })
          },
          () => get().state
        ),
        set: (newState: (get: State | (() => State)) => State) => {
          if (typeof newState === "function") {
            return set({ state: newState(() => get().state) })
          } else {
            set({ state: newState })
          }
        },
        dispatch: (args: AnyAction) =>
          set((store: { state: any }) => {
            return { state: reducer(store.state, args) }
          }),
        getState: () => get().state,
        state: initialState,
      })
    )
  })
  const [_actions] = React.useState(actions)

  const useSelector = (
    selector: (state: State) => any,
    equalityFn?: EqualityChecker<State>
  ) => {
    return useZustand(
      (store) => selector(store.state),
      (state, newState) => {
        return (equalityFn ?? shallow)(state, newState)
      }
    )
  }

  const { _setters, set, dispatch, getState } = useZustand(
    (store: any) => ({
      _setters: store.setters,
      set: store.set,
      dispatch: store.dispatch,
      getState: store.getState,
    }),
    () => {
      // Since these values are stable, they will always be the same
      return true
    }
  )
  return {
    useSelector,
    dispatch,
    setters: _setters,
    set: set as (state: State | ((get: () => State) => State)) => void,
    getState,
    actions: React.useMemo(() => {
      return Object.entries(_actions).reduce(
        (
          accum: { [k: string]: (...args: any[]) => void } = {},
          [key, value]
        ) => ({
          ...accum,
          [key]: (...args: any[]) => {
            dispatch(value(...args))
          },
        }),
        {}
      )
    }, [_actions, dispatch]),
  }
}

export default useStore
