import getBrowserLocale from "@myvp/shared/src/i18n/get-browser-locale"

/**
 * Returns in order of priority:
 * 1. The query param locale
 * 2. localeOverride
 * 3. The user's session locale
 * 4. The browser's locale
 */
const getUserLocale = (localeOverride?: string): string | readonly string[] => {
  // check query param locale value first
  {
    const searchParams = new URLSearchParams(window.location.search)
    const queryParamLocale = searchParams.get("locale")
    if (queryParamLocale) {
      return queryParamLocale as string
    }
  }
  if (localeOverride) {
    return localeOverride
  }
  // check user's session locale
  {
    let sessionLocale: string | undefined
    try {
      const metadata = JSON.parse(sessionStorage.getItem("metadata") as string)
      // TODO MYVC-17836 delete metadata?.locale fallback
      sessionLocale = metadata?.userInfo?.languageTag ?? metadata?.locale
    } catch {
      sessionLocale = undefined
    }
    if (sessionLocale) {
      return sessionLocale
    }
  }
  // use the browser's locale
  return getBrowserLocale()
}

export default getUserLocale
