import React from "react"
import useRefCallback from "@myvp/shared/src/hooks/use-ref-callback"
import { isIEOrEdge } from "@myvp/shared/src/functions/is-ie-or-edge"
import { breakpointSizes } from "@myvp/shared/src/styles/breakpoints"

const useMediaQuery = ({
  size,
  callback = () => {},
  dimension = "width",
}: {
  size: keyof typeof breakpointSizes
  callback?: (matches: boolean) => void
  dimension?: "width" | "height"
}) => {
  const callbackRef = useRefCallback(callback)
  const queries = React.useMemo(
    () => ({
      xxs: `(max-${dimension}: ${breakpointSizes.xxs}px)`,
      xs: `(max-${dimension}: ${breakpointSizes.xs}px)`,
      sm: `(max-${dimension}: ${breakpointSizes.sm}px)`,
      md: `(max-${dimension}: ${breakpointSizes.md}px)`,
      lg: `(max-${dimension}: ${breakpointSizes.lg}px)`,
    }),
    [dimension]
  )

  const [matches, setMatches] = React.useState(
    () => window.matchMedia(queries[size]).matches
  )

  React.useEffect(() => {
    let mounted = true

    const mql = window.matchMedia(queries[size])
    const onChange = () => {
      if (!mounted) {
        return
      }
      callbackRef.current(!!mql.matches)
      setMatches(!!mql.matches)
    }

    const isIE = isIEOrEdge()
    // Using deprecated function only for IE
    if (isIE === "edge" || isIE === "ie11") {
      mql.addListener(onChange)
    } else {
      mql.addEventListener("change", onChange)
    }
    setMatches(mql.matches)

    return () => {
      mounted = false
      if (isIE === "edge" || isIE === "ie11") {
        mql.removeListener(onChange)
      } else {
        mql.removeEventListener("change", onChange)
      }
    }
  }, [queries, size])

  return matches
}

export default useMediaQuery
