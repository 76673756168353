import React from "react"
import { useIntl } from "react-intl"
import Fallback from "src/components/fallback"
import messages from "src/i18n/languages/enUS"

const InPersonFallback: React.FC<{
  title?: keyof typeof messages
  body?: keyof typeof messages
}> = (props) => {
  const { formatMessage } = useIntl()
  return (
    <Fallback>
      <h1>{formatMessage({ id: props.title ?? "error.sessionExpired" })}</h1>
      <p>{formatMessage({ id: props.body ?? "inPerson.rescanQRCode" })}</p>
    </Fallback>
  )
}

export default InPersonFallback
